import React from 'react';
import './MuAddNewMessage.scss';
import PopUpForm from '../popupForm/PopUpForm';

export default function AddNewMessage() {

  return (
    <div className='AddNewMessage'>
      <PopUpForm action="AddNew" />
    </div>
  )
}

