import React, { useState, useMemo, useEffect } from "react";
import { Box, Button, IconButton } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import './GridTable.scss'
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DialogConfirm from './DialogConfirm';

//     // Normalise la chaîne de caractères en tenant compte des caractères spéciaux français
//     // exp : remplace les caractères accentués par leur équivalent non accentué
// const normalizeString = (str) => {
//     return str
//         .toLowerCase()
//         .normalize('NFD')
//         .replace(/[\u0300-\u036f]/g, '');
// };

function CustomToolbar({ setFilterButtonRef, isSelectionEmpty, onClickDelete }) {

    const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

    return (
        // currentCity &&
        <div className='grid-customToolbar-container'>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton ref={setFilterButtonRef} />
                {/* <GridToolbarDensitySelector /> */}
                <GridToolbarExport
                    csvOptions={{
                        fileName: `Liste-messages-${currentCity.description}-${currentCity.cityName}`,
                        delimiter: ';',
                        utf8WithBom: true,
                        columnsToExport: ["title","fullName","dateEnd","dateStart"],
                    }}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>

            <div className="btn-supprimer">
                {!isSelectionEmpty && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClickDelete}
                    >
                        Supprimer
                    </Button>
                )}
            </div>
        </div>
    );
}

CustomToolbar.propTypes = {
    setFilterButtonRef: PropTypes.func.isRequired,
    isSelectionEmpty: PropTypes.bool.isRequired,
    onClickDelete: PropTypes.func.isRequired,
};


export default function GridTable({ data, setData, setMustReload, allowedManagement }) {

    const [isSelectionEmpty, setIsSelectionEmpty] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [open, setOpen] = useState(false);

    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(data)
    }, [data]);

   const columns = useMemo(() => {
        const baseColumns = [
            { field: 'title', headerName: 'Titre Message', width: 250, editable: false, },
            { field: 'fullName', headerName: 'Auteur', width: 200, editable: false, },
            { field: 'dateStart', headerName: 'Date début', width: 150, editable: false, },
            { field: 'dateEnd', headerName: 'Expiration', width: 150, editable: false, },
        ];
        if (allowedManagement) {
            // .unshift Ajouter la colonne d'édition uniquement pour le rôle 'admin' au debut du tableau
            baseColumns.unshift({
                field: 'edit',
                headerName: 'Éditer',
                width: 80,
                editable: false,
                sortable: false,
                filterable: false,
                align: 'center',
                renderCell: (params) => {
                    return (
                        <Link
                            to={`/dashboard/municipalites/${params.row.cityCode}/mu-messages/edit-message/${params.row.idCityMessage}`}
                            state={params.row}
                            style={{
                                textDecoration: "none",
                                cursor: "pointer",
                            }}
                        >
                            <IconButton>
                                <EditIcon className="edit-icon" />
                            </IconButton>
                        </Link>
                    )
                }
            });
        }
        return baseColumns;
    }, [allowedManagement]);


    const handleSelectionChange = (selection) => {
        // console.log(rows)
        // console.log(selection)
        setIsSelectionEmpty(selection.length === 0);

        // Utilisation de filter pour récupérer les objets correspondants à la sélection
        const newSelectionRows = rows.filter((row) => selection.includes(row.idCityMessage));
        // console.log(newSelectionRows)

        // Mettre à jour l'état avec les lignes sélectionnées
        setSelectedRows(newSelectionRows);
    };

    const onClickDelete = async () => {
        console.log('Deleting selected rows !');
        setOpen(true)
    };

    return (
        <div className="GridTable">
            {open && (
                <DialogConfirm
                    open={open}
                    setOpen={setOpen}
                    rows={rows}
                    setRows={setRows}
                    selectedRows={selectedRows}
                    setIsSelectionEmpty={setIsSelectionEmpty}
                    setMustReload={setMustReload}
                    setData={setData}
                />
            )}

            {/* currentCity && */}
            <Box className='dataGridBox' sx={{ width: '100%', }} >
                {/* <Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div" >
                    Liste des utilisateurs
                </Typography> */}

                <DataGrid
                    slots={{ toolbar: CustomToolbar, }}
                    slotProps={{
                        panel: { anchorEl: filterButtonRef, },
                        toolbar: {
                            setFilterButtonRef,
                            isSelectionEmpty,
                            onClickDelete,
                        }
                    }}

                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 50, 100]}

                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                    // getRowSpacing={(params) => ({
                    //     top: params.isFirstVisible ? 0 : 5,
                    //     bottom: params.isLastVisible ? 0 : 5,
                    // })}

                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.idCityMessage}

                    disableRowSelectionOnClick
                    // checkboxSelection
                    // onRowSelectionModelChange={handleSelectionChange}

                    checkboxSelection={allowedManagement}
                    onRowSelectionModelChange={allowedManagement ? handleSelectionChange : undefined}

                />
            </Box>
        </div>
    )
}
