import React from 'react';
import './SearchBar.scss';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearIcon from "@mui/icons-material/Clear";

export default function SearchBar(props) {
    const { query, setQuery } = props;

    const handleInputChange = (e) => {
        setQuery(e.target.value);
    };

    const handleClearInput = () => {
        setQuery('');
    };

    return (
        <div className="searchBar">
            <input
                id='searchbar'
                name='searchbar'
                type="text"
                placeholder="Recherche..."
                className="inputSearch"
                value={query}
                onChange={handleInputChange}
            />
            {query && (
                <ClearIcon
                    onClick={handleClearInput}
                    sx={{ cursor: 'pointer', fontSize: 'small' }}
                />
            )}
            <SearchOutlinedIcon />
        </div>
    );
}
