import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import './AccordionProfilesAndServices.scss'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { IconButton } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  backgroundColor: 'rgba(255, 255, 255, 0)', // Blanc avec opacité
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Ombre légère
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function AccordionProfilesAndServices(props) {

  const {
    userToEdit,
    setUserToEdit,
    displayProfile
  } = props;

  // Trier la liste par nom avant le rendu
  // [...finalProfilesList]:ici utilisation de l'opérateur de propagation (...).
  // => copie superficielle (shallow copy) de finalProfilesList.
  // Cette shallow copy garantit que nous n'altérons pas directement l'original lorsque nous trierons la liste.
  // localeCompare(): compare les chaînes de caractères cityName de a et b en utilisant les règles de tri locales
  // (c'est-à-dire, en prenant en compte les accents et les caractères spéciaux).
  // Elle renvoie un nombre négatif si a doit venir avant b, un nombre positif si a doit venir après b, et zéro si les deux sont égaux.

  // const sortedProfilesList = [...finalProfilesList].sort((a, b) => a.creationUserProfile.localeCompare(b.creationUserProfile));
  const sortedProfilesList = [...userToEdit.profiles].sort((a, b) => a.cityName.localeCompare(b.cityName));
  // const sortedProfilesList = [...finalProfilesList].sort((a, b) => a.idUserProfile - b.idUserProfile);
  // const sortedProfilesList = [...finalProfilesList].sort((a, b) => -(a.idUserProfile - b.idUserProfile));

  // retirer un profil de la liste de profiles (corbeille de l'accordion)
  const handleRemoveProfile = (index) => {
    // .filter((_, i) => la fonction de rappel (callback) ne prend pas en compte le premier paramètre (la valeur de l'élément),
    // mais seulement le deuxième paramètre (l'index de l'élément dans le tableau).
    const updatedProfilesList = sortedProfilesList.filter((_, i) => i !== index);
    // setFinalProfilesList(updatedProfilesList);
    setUserToEdit((prev) => ({ ...prev, profiles: updatedProfilesList }))
    setExpanded(null)
  };

  const [expanded, setExpanded] = React.useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };


  return (
    <div className="container">
      {sortedProfilesList.length > 0 ? (
        sortedProfilesList.map((profile, index) => (
          <div key={index}>
            <Accordion expanded={expanded === `panel-${index}`} onChange={handleChange(`panel-${index}`)}>

              <AccordionSummary aria-controls={`panel-${index}-content`} id={`panel-${index}-header`}>
                <div className='MuiAccordionSummary-content-Municipality'>
                  <img className='Muni-logo' src={profile.logo} alt="logo" />
                  <Typography>{profile.cityName}</Typography>
                </div>
                <IconButton onClick={() => handleRemoveProfile(index)} edge="end" aria-label="delete">
                  <DeleteIcon className="delete-profilAndServices-icon" />
                </IconButton>
              </AccordionSummary>

              <AccordionDetails className='AccordionDetails-container'>
                <div className='AccordionDetails-text'>
                  <Typography className='MuiTypography-nameProfile'>
                    {profile.profile}
                  </Typography>
                  <Typography className='MuiTypography-services'>
                    {profile.servicesProfile.map(service => service.aliasName).join(', ')}
                  </Typography>
                </div>
                <IconButton
                  className="edit-profilAndServices-btn"
                  onClick={() => displayProfile(profile)}
                >
                  <EditIcon className="edit-profilAndServices-icon" />
                </IconButton>
              </AccordionDetails>

            </Accordion>
          </div>
        ))
      ) : (
        <Typography variant="caption" color="error">Au moins un profil, ainsi que ses services, doit être attribué à cet utilisateur.</Typography>
      )
      }
    </div>
  );

}
