import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import './LandingCard.scss';

export default function LandingCard({ row }) {


  return (
    <Card className='LandingMuiCard-root' >
      {/* <CardActionArea component="a" href={row.url } target="_blank"> */}
      {/* j'ajoute le gde dans le href pour bypasser l'authentification en suivant le lien */}
      {/* <CardActionArea component="a" href={`${row.url}&gde=<encryptedBase64>`} target="_blank"> */}

      <CardActionArea component="a" href={`${row.url}`} target="_blank">

        <div className='LandingMuiCard-imgContainer'>
          <CardMedia
            className='LandingMuiCard-logo'
            component="img"
            image={row.logo}
            alt="logo"
            onError={(e) => {
              e.target.src = "https://www.goazimut.com/GOnet6/images/print/SaintAzimut_print.png";
            }}
          />
        </div>

        <div className='LandingMuiCard-cardContent'>

          <Typography className='LandingMuiCard-text' variant="caption" component="div">
            {row.description} - {row.cityCode}
          </Typography>

          <Typography className='LandingMuiCard-text' variant="h6" component="div">
            {row.cityName}
          </Typography>

          <Typography className='LandingMuiCard-text' variant="button" color="text.secondary">
            MRC : {row.mrcRealName}
          </Typography>

        </div>
      </CardActionArea>
    </Card>
  );
}
