import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './MuCartographie.scss'
import MuHeader from '../../../components/mu-header/MuHeader';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import { Paper } from '@mui/material';


export default function MuCartographie() {
  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));

  return (
    currentCity &&
    <div className='cartographie'>
      <MuHeader currentCity={currentCity} />

      <div className='cartographie-main-container'>

        <div className="topPage">
          <div className="topTitle">
            <PublicRoundedIcon />
            <div>Cartographie {currentCity.description}  </div>
            <span><em>{currentCity.cityName}</em></span>
          </div>
          <div className="topNav">
            <Link className='topNav-close' to={`/dashboard/municipalites/${cityCode}/mu-statistiques`} style={{ textDecoration: "none", cursor: 'pointer' }}>
              <CloseRoundedIcon />
            </Link>
          </div>
        </div>

        <Paper
          className="cartographie-paper"
          elevation={5}
        >
          <p>Cette page permettra d'envoyer des données et un MXD pour faire la création d'une nouvelle thématique.</p>
          <p>Lorsque la thématique aura été créée, l'utilisateur recevra une notification par courriel. Il pourra alors assigner l'accès à cette thématique via le portail.</p>
          <h3>Paramètres des thématiques :</h3>
          <ul>
            <li>Identifiant (unique)</li>
            <li>Nom affiché</li>
            <li>Nom du producteur</li>
            <li>Date de production</li>
            <li>Note sur la thématique</li>
            <li>Option pour la diffusion</li>
            <ul>
              <li>Diffusion grand public</li>
              <li>Ajouter cadastre, matrice, réseau routier, hydrographie</li>
            </ul>
          </ul>

        </Paper>

      </div>
    </div>
  )
}


