import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router";
import './AddNewMessage.scss';
import {
  getUserMunicipalitesList,
  getMessageTypeList,
  // getServices,
  addMultiMuMessage,
  updateMultiMuMessage,
  getMessagesListByType,
  getServices,
} from '../httpQuerys';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  Typography,
  Stack,
  FormControl,
  Paper,
  Button,
  FormControlLabel,
  Checkbox,
  Slider,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';

import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditorWysiwyg from './AddNew-Message-Forms/Editor-Wysiwyg/EditorWysiwyg';
import DialogConfirm from '../AddNew-Message/DialogConfirm';

import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from 'react-query';


export default function AddNewMessage({ idUser }) {

  const AddNewMessageFormRef = useRef(null);
  const navigate = useNavigate();
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

  const [municipalities, setMunicipalities] = useState([]);
  const [selectedMunicipalities, setSelectedMunicipalities] = useState([]);

  const [multiMuMessageToSave, setMultiMuMessageToSave] = useState();

  const [allMuSelect, setAllMuSelect] = useState(false);

  const [messageTypeList, setMessageTypeList] = useState([])
  const [messageType, setMessageType] = useState(null)


  const [idCityMessage, setIdCityMessage] = useState(null)
  const [idMessage, setIdMessage] = useState(null)

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  // Définir les dates initiales
  const [startDate, setStartDate] = useState(dayjs());
  const [startDateError, setStartDateError] = useState('');
  // endDate = Date d'aujourd'hui plus un mois par défaut
  const [endDate, setEndDate] = useState(dayjs().add(1, 'month'));
  const [endDateError, setEndDateError] = useState('');

  const [privateVersion, setPrivateVersion] = useState(false);
  const [publicVersion, setPublicVersion] = useState(false);
  const [versionError, setVersionError] = useState('');

  const [autoClose, setAutoClose] = useState(true);
  const [userClose, setUserClose] = useState(false);
  const [displayTime, setDisplayTime] = useState(30000);
  const [orderBy, setOrderBy] = useState(1);

  const [typeText, setTypeText] = useState('');
  const [frMessage, setFrMessage] = useState('');
  const [enMessage, setEnMessage] = useState('');
  const [textError, setTextError] = useState('');

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [open, setOpen] = useState(false);

  const [openAutoC, setOpenAutoC] = useState(false);
  const [loading, setLoading] = useState(true);
  // const loading = openAutoC && municipalities.length === 0;

  const [services, setServices] = useState([]);
  const [idService, setIdService] = useState(null);

  const { data, isLoading, isError } = useQuery('userMunicipalitiesList', () => getUserMunicipalitesList(idUser));

  //  valeurs a récupérer de la BD une seule fois lors du chargement
  useEffect(() => {
    setAllMuSelect(false)
    setLoading(openAutoC && municipalities.length === 0)

    if (!isLoading && !isError) {
      setMunicipalities([{ cityCode: '-', cityName: 'Tout sélectionner', mrcCode: '', mrcRealName: '' }, ...data])
    }

    getMessageTypeList().then((data) => {
      setMessageTypeList(data.filter(item => [1, 3].includes(item.idtypeMessage)));
    });
  }, [data, isError, isLoading, municipalities.length, openAutoC]);

  // useEffect(() => {
  //   setAllMuSelect(false)
  //   setLoading(openAutoC && municipalities.length === 0)

  //   getUserMunicipalitesList(idUser).then((data) => {
  //     setLoading(false)
  //     setMunicipalities([{ cityCode: '-', cityName: 'Tout sélectionner', mrcCode: '', mrcRealName: '' }, ...data])
  //   });

  //   getMessageTypeList().then((data) => {
  //     setMessageTypeList(data.filter(item => [2, 3, 4, 5, 6].includes(item.idtypeMessage)));
  //   });
  // }, [idUser, loading, municipalities.length, openAutoC]);


  // une fois le type de message choisit on va chercher les détails du message
  // si ce n'est pas le type 1 ou 3
  useEffect(() => {
    if (selectedMunicipalities && selectedMunicipalities.length > 0) {
      if (messageType && messageType.idtypeMessage) {
        // determiner la nature du message à envoyer vers la BD pour impression ou sous format HTML selon le idtypeMessage
        const typeText = (messageType.idtypeMessage === 4 || messageType.idtypeMessage === 5) ? 'print' : 'html'
        setTypeText(typeText)
      }

      setIdCityMessage(null);
      setIdMessage(null);
      setTitle('');
      setPrivateVersion(false);
      setPublicVersion(false);
      setFrMessage('');
      setEnMessage('');
      setTextError('');

      if (messageType && messageType.idtypeMessage !== 1 && messageType.idtypeMessage !== 3) {
        // console.log(selectedMunicipalities)
        const cityCode = selectedMunicipalities[0].cityCode;
        getMessagesListByType(cityCode, messageType.idtypeMessage).then((data) => {
          if (data.length > 0) {
            // console.log(data)
            setIdCityMessage(data[0].idCityMessage);
            setIdMessage(data[0].idMessage);
            setTitle(data[0].title)
            setPrivateVersion(data[0].privateVersion)
            setPublicVersion(data[0].publicVersion)
            setFrMessage(data[0].text_fr)
            setEnMessage(data[0].text_en)
          }
        });
      }
    }
  }, [messageType, selectedMunicipalities]);


  // Si on a sélectionné une seule municiplité et que le type de message choisi est = 1 ou 3
  // On attribue la valeur de service
  useEffect(() => {
    const fetchServices = async () => {
      let foundService = null;
      let filteredServices = [];

      if (selectedMunicipalities.length === 1
        && selectedMunicipalities[0].cityCode !== '-'
        && (messageType?.idtypeMessage === 1 || messageType?.idtypeMessage === 3)
      ) {
        try {
          const data = await getServices(selectedMunicipalities[0].cityCode);
          // console.log(data);

          if ((!publicVersion && !privateVersion) || (publicVersion && privateVersion)) {
            filteredServices = [];
            foundService = null;
          } else if (publicVersion && !privateVersion) {
            filteredServices = data.filter(service => service.servicePublic === true);
            foundService = filteredServices.length > 0 ? filteredServices[0].idService : null;
          } else if (!publicVersion && privateVersion) {
            filteredServices = data.filter(service => service.servicePublic === false);
          }

          setServices(filteredServices);
          setIdService(foundService);
        } catch (error) {
          console.error('Error fetching services:', error);
          setServices([]);
          setIdService(null);
        }
      }
    };

    fetchServices();
  }, [messageType, privateVersion, publicVersion, selectedMunicipalities]);

  // useEffect(() => {
  //   let foundService = null
  //   if (selectedMunicipalities.length === 1
  //     && selectedMunicipalities[0].cityCode !== '-'
  //     && (messageType?.idtypeMessage === 1 || messageType?.idtypeMessage === 3)
  //   ) {

  //     // console.log(selectedMunicipalities[0].cityCode)
  //     // console.log(messageType?.idtypeMessage)

  //     getServices(selectedMunicipalities[0].cityCode).then(data => {
  //       if (!publicVersion && !privateVersion) {
  //         setServices([]);
  //       } else if (publicVersion && !privateVersion) {
  //         setServices(data.filter(service => service.servicePublic === true));
  //         foundService = data.find(service => service.servicePublic === true).idService;
  //       } else if (!publicVersion && privateVersion) {
  //         setServices(data.filter(service => service.servicePublic === false));
  //       } else {
  //         setServices([]);
  //       }

  //       setIdService(foundService)
  //       // console.log(foundService)
  //     });

  //   }
  // }, [messageType, privateVersion, publicVersion, selectedMunicipalities])


  // données des Options de la liste déroulante des municipalités
  const municipalitiesDefaultProps = {
    // options: [ {cityCode:'-' , cityName:'Tout sélectionner'}, ...municipalities],
    options: municipalities.sort((a, b) => a.mrcCode.localeCompare(b.mrcCode)), // Trier les options par mrcCode
    groupBy: (option) => option.mrcRealName, // Regrouper les options par mrcName
    getOptionLabel: (option) => option.cityCode ? `${option.cityCode} - ${option.cityName}` : ''
    // getOptionLabel: (option) => `${option.cityName} (${option.cityCode})`,
  };

  // données des Options de la liste déroulante pour les types de messages
  const messageTypeListDefaultProps = {
    options: messageTypeList,
    getOptionLabel: (option) => `${option.idtypeMessage} - ${option.typedescription}`
  };

  // données des Options de la liste déroulante des services
  const servicesDefaultProps = {
    options: services,
    getOptionLabel: (option) => option.aliasName ? `${option.idService} - ${option.aliasName}` : ''
  };

  // ---------------------------------------------------------------
  // Configuration de la locale pour Day.js
  dayjs.locale('fr-ca');
  // const today = dayjs().isValid() ? dayjs().locale('fr-ca').format('YYYY-MM-DD HH:mm:ss') : null;
  // ---------------------------------------------------------------

  const capitalizeTheFirstLetter = (str) => {
    //seulement la premiere lettre en majuscule
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleStartDateChange = (newValue) => {
    // Vérifier si la date de debut est inférieure à la date d'aujourd'hui
    if (dayjs(newValue).isBefore(dayjs().subtract(1, 'days'))) {
      setStartDateError('La date de début doit être supérieure ou égale à la date d\'aujourd\'hui.');
    } else {
      setStartDate(newValue);
      setEndDate(dayjs(newValue).add(30, 'days'));
      setStartDateError('');
      setEndDateError('');
    }
  };


  const handleEndDateChange = (newValue) => {
    // Vérifier si la date de fin est inférieure à la date de début
    if (dayjs(newValue).isBefore(startDate)) {
      setEndDateError('La date de fin doit être supérieure à la date de début.');
      // Ajouter automatiquement 30 jours à la date de début
      setEndDate(dayjs(startDate).add(30, 'days'));
    } else {
      setEndDate(newValue);
      setEndDateError('');
    }
  };

  // Effacer le contenu detous les champs
  const handleClearAllFields = () => {
    // setSelectedMunicipalities([])
    setMessageType(null)
    setTitle('');
    setTitleError('');
    setStartDate(dayjs());
    setStartDateError('');
    setEndDate(dayjs().add(1, 'month'));
    setEndDateError('');
    setPrivateVersion(false);
    setPublicVersion(false);
    setIdService(null);
    setVersionError('');
    setAutoClose(true);
    setUserClose(false);
    setDisplayTime(30000);
    setOrderBy(1);
    setFrMessage('');
    setEnMessage('');
    setTextError('');
  };

  const handleCancel = () => {
    navigate(`/dashboard/messages/messages-list`)
  }

  const handleReset = () => {
    // Réinitialiser tous les états
    setSelectedMunicipalities([])
    setMessageType(null)
    setTitle('');
    setTitleError('');
    setStartDate(dayjs());
    setStartDateError('');
    setEndDate(dayjs().add(1, 'month'));
    setEndDateError('');
    setPrivateVersion(false);
    setPublicVersion(false);
    setVersionError('');
    setAutoClose(true);
    setUserClose(false);
    setDisplayTime(30000);
    setOrderBy(1);
    setFrMessage('');
    setEnMessage('');
    setTextError('');
  };

  // Définir le format de date souhaité exp : 2024-07-24 00:00:00.000
  const formatDate = (date) => {
    return date.hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm:ss.SSS');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title === '') setTitleError('Un titre est obligatoire.')
    else setTitleError('')
    if (!publicVersion && !privateVersion) setVersionError('Au moins l\'une des deux versions doit être sélectionnée.')
    else setVersionError('')
    if ((frMessage === '' && enMessage === '')) {
      setTextError("Aucun message n'a été saisi. Cela implique qu'il n'y aura pas de message à afficher.");
    } else {
      setTextError('');
    }

    const isFormValid =
      (title !== '' && !titleError) &&
      ((publicVersion === true || privateVersion === true) && !versionError) &&
      (startDate !== '' && !startDateError) &&
      (endDate !== '' && !endDateError) &&
      ((frMessage !== '' || enMessage !== '') && !textError);


    const municipalitiesListe = selectedMunicipalities[0].cityName === "Tout sélectionner" ? municipalities.filter(item => item.cityName !== 'Tout sélectionner') : selectedMunicipalities

    // Copier les états actuels dans de nouvelles variables
    let newFrMessage = frMessage;
    let newEnMessage = enMessage;

    // Si le type de texte est 'print' ou 'html', modifier les messages en conséquence
    if (typeText === 'print') {
      newFrMessage = frMessage
        ? frMessage.replace(/<p><br><\/p>/g, '\\n').replace(/<\/\w+>/g, '\\n').replace(/<[^>]*>/g, '').replace(/'/g, '’')
        : '';
      newEnMessage = enMessage
        ? enMessage.replace(/<p><br><\/p>/g, '\\n').replace(/<\/\w+>/g, '\\n').replace(/<[^>]*>/g, '').replace(/'/g, '’')
        : '';
    } else if (typeText === 'html') {
      newFrMessage = frMessage ? frMessage.replace(/'/g, '’') : '';
      newEnMessage = enMessage ? enMessage.replace(/'/g, '’') : '';

      if (messageType && messageType.idtypeMessage === 1) {
        newFrMessage = `
            <div class="layout-1">
              <br />
              <div class="text" style="text-align: center">
                <p>${newFrMessage}</p>
              </div>
            </div>
            <br />`;
        newEnMessage = `
            <div class="layout-1">
              <br />
              <div class="text" style="text-align: center">
                <p>${newEnMessage}</p>
              </div>
            </div>
            <br />`;
      }
    }

    const messageToSave = {
      idCityMessage: idCityMessage,
      municipalities: municipalitiesListe,
      dateStart: (messageType.idtypeMessage === 1) || (messageType.idtypeMessage === 3) ? formatDate(startDate) : null,
      dateEnd: (messageType.idtypeMessage === 1) || (messageType.idtypeMessage === 3) ? formatDate(endDate) : null,
      autoClose: autoClose || true,
      idtypeMessage: messageType.idtypeMessage,
      privateVersion: privateVersion,
      publicVersion: publicVersion,
      userClose: userClose || false,
      orderBy: orderBy,
      frequency: 0,
      blocking: 0,
      nameMessage: title,
      idService: idService ?? null,
      isAzimut: 0,
      author: currentUserInfo.idUser,
      displayTime: displayTime || null,

      idMessage: idMessage,
      title: title,
      text_fr: newFrMessage,
      text_en: newEnMessage,
    }

    if (isFormValid) {

      let result = undefined
      // console.log({ messageToSave })
      if (messageToSave
        && ((messageToSave.idtypeMessage === 1) || (messageToSave.idtypeMessage === 3))
        && messageToSave.idCityMessage
        && messageToSave.idMessage) {

        console.log('Mise à jour d\'un message');
        result = await updateMultiMuMessage(messageToSave);

      } else {
        console.log('Ajout d\'un nouveau message')
        result = await addMultiMuMessage(messageToSave);
      }

      // console.log({ result })
      if (result) {
        console.log('Soumission du Formulaire d\'ajout d\'un nouveau message à une ou plusieurs Municipalités');
      }

      setIsFormValidated(true);
      setMultiMuMessageToSave(messageToSave);
      // permet d'afficher la boite de dialogue à la soumission du formulaire
      setOpen(true);
    } else {
      console.log('Un des champs est manquant ou mal saisi');
      setIsFormValidated(false);
      setOpen(true);
    }
  }


  return (
    <div className='AddNewMessage'>

      {open && (
        <DialogConfirm
          handleClearAllFields={handleReset}
          multiMuMessageToSave={multiMuMessageToSave}
          open={open}
          setOpen={setOpen}
          isFormValidated={isFormValidated}
          titleError={titleError}
          versionError={versionError}
          textError={textError}
        />
      )}

      <Paper elevation={5} className='addNew-message-paper' sx={{ height: 600, overflowY: 'auto' }}>
        <form ref={AddNewMessageFormRef} className='addNew-message-form' onSubmit={handleSubmit}>

          <Grid container className='gridContainer' spacing={2} m='0' >

            <Grid container className='gridContainer-first-block'>

              <Grid item className='gridItem' xs={12} md={6} lg={6} >
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <Stack spacing={1} >
                    <Autocomplete
                      {...municipalitiesDefaultProps}
                      multiple
                      disableCloseOnSelect
                      id="municipalitiesMultiSelect"
                      name="municipalitiesMultiSelect"
                      // disabled={!municipality}

                      open={openAutoC}
                      onOpen={() => {
                        setOpenAutoC(true);
                      }}
                      onClose={() => {
                        setOpenAutoC(false);
                      }}
                      loading={loading}

                      isOptionEqualToValue={(option, value) => option.cityName === value.cityName}

                      value={allMuSelect ? null : selectedMunicipalities}

                      onChange={(e, newValue) => {
                        setMessageType('')
                        if (newValue && newValue.length > 0) {
                          if (newValue[newValue.length - 1].cityName === 'Tout sélectionner') {
                            // L'utilisateur a sélectionné "Tout sélectionner", nous conservons cette sélection
                            setSelectedMunicipalities([{ cityCode: '-', cityName: 'Tout sélectionner', mrcCode: '', mrcRealName: '' }]);
                          } else if (selectedMunicipalities.length === 1 && selectedMunicipalities[0].cityName === 'Tout sélectionner') {
                            // L'utilisateur a précédemment sélectionné uniquement "Tout sélectionner", mais maintenant il a sélectionné d'autres options,
                            // donc nous supprimons "Tout sélectionner" de la sélection
                            setSelectedMunicipalities(newValue.filter(item => item.cityName !== 'Tout sélectionner'));
                          } else {
                            // L'utilisateur a sélectionné d'autres options, nous les conservons
                            setSelectedMunicipalities(newValue);
                          }
                          // setMunicipalitiesError('');
                        } else {
                          // L'utilisateur a effacé la valeur sélectionnée
                          setSelectedMunicipalities([]);
                        }
                      }}

                      onInputChange={(e, newInputValue) => {
                        if (!newInputValue) {
                          // L'utilisateur a effacé le champ au clavier
                          setSelectedMunicipalities([])
                        }
                      }}

                      clearOnEscape
                      // renderInput={(params) => <TextField {...params} label="Municipalités" variant="standard" name="profilesList" />}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Municipalités"
                          variant="standard"
                          name="profilesList"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Stack>
                  {/* {profileError && <Typography variant="caption" color="error">{profileError}</Typography>} */}
                </FormControl>
              </Grid>

              <Grid item className='gridItem' xs={12} md={6} lg={6} >
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <Stack spacing={1} >
                    <Autocomplete
                      {...messageTypeListDefaultProps}
                      id="messageTypeList"
                      name="messageTypeList"

                      // getOptionDisabled={(option) => option.idtypeMessage === 3 }
                      getOptionDisabled={(option) => {
                        // Vérifier si selectedMunicipalities existe et si la longueur est supérieure à 1
                        if (selectedMunicipalities && selectedMunicipalities.length > 1) {
                          return (option.idtypeMessage !== 1) && (option.idtypeMessage !== 3);
                        } else if (selectedMunicipalities && selectedMunicipalities.length === 1) {
                          // Si la longueur de selectedMunicipalities est de 1 et que cityCode est '-', désactiver toutes les options sauf 3
                          if (selectedMunicipalities[0].cityCode === '-') {
                            return (option.idtypeMessage !== 1) && (option.idtypeMessage !== 3);
                          }
                        } else {
                          // Si aucune municipalité n'est sélectionnée, désactiver toutes les options
                          return true;
                        }
                      }}

                      isOptionEqualToValue={(option, value) => option.idtypeMessage === value.idtypeMessage}

                      value={messageType || null}
                      onChange={(e, newValue) => {
                        // console.log('Type de message newValue ===> ', newValue)
                        handleClearAllFields()
                        // setMessageType(null)
                        if (newValue) {
                          // console.log('Type de message newValue ===> ', newValue)
                          setMessageType(newValue)
                        }
                      }}

                      clearOnEscape
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type du message"
                          variant="standard"
                          name="messageTypeList"
                        />
                      )}
                    />
                  </Stack>
                </FormControl>
              </Grid>

            </Grid>

            {selectedMunicipalities.length > 0 && messageType &&
              <Grid container className='gridContainer-second-block'>

                <Grid item className='gridItem' xs={12} md={6} lg={6}>
                  <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                    <InputLabel htmlFor="fullname">Auteur</InputLabel>
                    <Input
                      id="fullname"
                      name="fullname"
                      autoComplete="fullname"
                      disabled
                      value={currentUserInfo.fullName || ''}
                    />
                  </FormControl>
                </Grid>

                <Grid item className='gridItem' xs={12} md={6} lg={6}  >
                  <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
                    <InputLabel htmlFor="titre">Titre du message</InputLabel>
                    <Input
                      id="titre"
                      name="titre"
                      autoComplete="titre"
                      required

                      value={title || ''}

                      onChange={(e) => {
                        const newValue = capitalizeTheFirstLetter(e.target.value);
                        setTitle(newValue)
                        setTitleError('')
                      }}

                      endAdornment={
                        title && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setTitle('')}
                              sx={{ cursor: 'pointer' }}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                    {titleError && <Typography variant="caption" color="error">{titleError}</Typography>}
                  </FormControl>
                </Grid>

                {(messageType.idtypeMessage === 3 || messageType.idtypeMessage === 1) &&
                  <>
                    <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                      <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                        <DatePicker
                          label="Date début d'affichage"
                          format="YYYY/MM/DD"
                          openTo="day"
                          views={["year", "month", "day"]}
                          slotProps={{
                            textField: {
                              variant: "filled",
                              size: "small",
                              style: { width: '100%', height: '100%' }
                            }
                          }}

                          value={startDate || null}
                          onChange={(newValue) => {
                            handleStartDateChange(newValue)
                          }}
                        />
                      </LocalizationProvider>
                      {startDateError && <p style={{ color: 'red' }}>{startDateError}</p>}
                    </Grid>

                    <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                      <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                        <DatePicker
                          label="Date fin d'affichage"
                          format="YYYY/MM/DD"
                          openTo="day"
                          views={["year", "month", "day"]}
                          slotProps={{
                            textField: {
                              variant: "filled",
                              size: "small",
                              style: { width: '100%', height: '100%' }
                            }
                          }}

                          value={endDate || null}
                          onChange={(newValue) => {
                            handleEndDateChange(newValue)
                          }}
                        />
                      </LocalizationProvider>
                      {endDateError && <p style={{ color: 'red' }}>{endDateError}</p>}
                    </Grid>
                  </>
                }

                <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>
                  <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                    <FormControlLabel className='gridItem-formControlLabel left'
                      control={
                        <Checkbox
                          className='publicVersion checkbox-version'
                          id="publicVersion"
                          name="publicVersion"

                          checked={publicVersion || false}
                          onChange={(e) => {
                            setPublicVersion(e.target.checked)
                            setVersionError('');
                          }}
                          color="primary"
                          inputProps={{ 'aria-label': 'publicVersion checkbox' }}
                        />
                      }
                      label={
                        <div className='version-label'>
                          Version publique
                        </div>
                      }
                      labelPlacement="end"
                    />
                  </FormControl>

                  <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                    <FormControlLabel className='gridItem-formControlLabel right'
                      control={
                        <Checkbox
                          className='privateVersion checkbox-version'
                          id="privateVersion"
                          name="privateVersion"

                          checked={privateVersion || false}
                          onChange={(e) => {
                            setPrivateVersion(e.target.checked);
                            setVersionError('');
                          }}
                          color="primary"
                          inputProps={{ 'aria-label': 'contact checkbox' }}
                        />
                      }
                      label={
                        <div className='version-label'>
                          {/* <PermContactCalendarIcon
                            sx={{
                              // color: contact ? 'green' : (municipality ? 'action.active' : 'lightgray'), // Use light gray if disabled

                              // color: contact ? 'green' : 'action.active',
                              marginRight: '0.5rem'
                            }}
                          /> */}
                          Version privée
                        </div>
                      }
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>


                {(!publicVersion
                  && privateVersion
                  && selectedMunicipalities.length === 1
                  && (messageType.idtypeMessage === 3 || messageType.idtypeMessage === 1)
                  && selectedMunicipalities[0].cityCode !== '-') &&
                  <Grid item className='gridItem' xs={12} md={6} lg={6} >
                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                      <Stack spacing={1} >
                        <Autocomplete
                          {...servicesDefaultProps}
                          id="services"
                          name="services"

                          isOptionEqualToValue={(option, value) => option.idService === value.idService}
                          // Trouver l'objet complet ou retourner null si non trouvé
                          value={services.find(service => service.idService === idService) || null}
                          onChange={(event, newValue) => {
                            // Si newValue n'est pas null avant d'essayer d'accéder à sa propriété
                            if (newValue) {
                              setIdService(newValue.idService);
                            } else {
                              // Gérer le cas où aucune option n'est sélectionnée ou toute autre valeur par défaut appropriée
                              setIdService(null);
                            }
                          }}

                          clearOnEscape
                          renderInput={(params) => <TextField {...params} label="Services" variant="standard" name="services" />}
                        />
                      </Stack>
                    </FormControl>
                  </Grid>
                }


                {messageType.idtypeMessage === 1 &&
                  <>
                    <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>

                      <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                        <FormControlLabel
                          className='gridItem-formControlLabel left'
                          control={
                            <Checkbox
                              className='autoClose checkbox-version'
                              id="autoClose"
                              name="autoClose"

                              checked={autoClose || true}
                              onChange={(e) =>
                                setAutoClose(e.target.checked)
                              }

                              color="primary"
                              inputProps={{ 'aria-label': 'autoClose checkbox' }}
                            />
                          }
                          label={
                            <div className='version-label'>
                              Auto-fermeture
                            </div>
                          }
                          labelPlacement="end"
                        />
                      </FormControl>

                      <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                        <FormControlLabel
                          className='gridItem-formControlLabel right'
                          control={
                            <Checkbox
                              className='userClose checkbox-version'
                              id="userClose"
                              name="userClose"

                              checked={userClose || false}
                              onChange={(e) =>
                                setUserClose(e.target.checked)
                              }

                              color="primary"
                              inputProps={{ 'aria-label': 'userClose checkbox' }}
                            />
                          }
                          label={
                            <div className='version-label'>
                              Fermeture par l'utilisateur
                            </div>
                          }
                          labelPlacement="end"
                        />
                      </FormControl>

                    </Grid>

                    <Grid item className='gridItem' xs={12} md={6} lg={6} >
                      <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                        <div className='sliderLabel'>
                          <Typography gutterBottom>Temps d'affichage &nbsp; </Typography>
                          <div className='sliderInput'>

                            <Input
                              size="small"

                              value={displayTime / 1000 || 0}
                              onChange={(e) => {
                                setDisplayTime(e.target.value === '' ? 0 : Number(e.target.value) * 1000);
                              }}

                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 60,
                                type: 'number',
                              }}
                            />
                            <Typography gutterBottom>
                              &nbsp; <span style={{ fontStyle: 'italic', fontSize: 'small' }}>sec</span>
                            </Typography>
                          </div>
                        </div>

                        <Slider
                          aria-label="Temps"
                          defaultValue={displayTime / 1000 || 0}
                          valueLabelDisplay="auto"
                          valuelabelplacement="bottom"

                          value={displayTime / 1000 || 0}
                          onChange={(e) => {
                            setDisplayTime(e.target.value === '' ? 0 : Number(e.target.value) * 1000);
                          }}

                          step={1}
                          min={0}
                          max={60}
                          aria-labelledby="input-slider"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item className='gridItem' xs={12} md={6} lg={6}>
                      <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
                        <div className='orderBy'>
                          <Typography gutterBottom>Ordre d'affichage &nbsp; </Typography>
                          <Input
                            className='orderBy-control'
                            id="orderBy"
                            name="orderBy"

                            size="small"

                            value={orderBy || 0}
                            onChange={(e) => {
                              setOrderBy(e.target.value === '' ? 1 : Number(e.target.value));
                            }}

                            inputProps={{
                              step: 1,
                              min: 0,
                              max: 100,
                              type: 'number',
                            }}
                          />
                        </div>
                      </FormControl>
                    </Grid>
                  </>
                }

                <hr />
                <Grid item className='gridError' xs={12} md={12} lg={12} >
                  {versionError && <Typography variant="caption" color="error">{versionError}</Typography>}
                </Grid>

                <Grid item className='gridError' xs={12} md={12} lg={12} >
                  {textError && <Typography variant="caption" color="error">{textError}</Typography>}
                </Grid>

                <hr />

                <EditorWysiwyg language={'français'} message={frMessage} setMessage={setFrMessage} setMessageError={setTextError} />

                <EditorWysiwyg language={'anglais'} message={enMessage} setMessage={setEnMessage} setMessageError={setTextError} />

                <hr />

                <div className='footer-addNew-message'>

                  <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }} onClick={handleCancel}>
                    Annuler
                  </Button>

                  <Button className='btnSubmit' type="reset" variant="contained" sx={{ width: '200px', cursor: 'pointer' }} onClick={handleReset}>
                    Réinitialiser
                  </Button>

                  <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                    Enregistrer
                  </Button>
                </div>

              </Grid>
            }

          </Grid>

        </form>
      </Paper>
    </div>
  )
}

