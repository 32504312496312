import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router";
import './PopUpForm.scss';
import {
    getServices,
    addMuMessage,
    updateMuMessage,
} from '../httpQuerys';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Grid,
    Input,
    InputLabel,
    InputAdornment,
    Typography,
    Stack,
    FormControl,
    Paper,
    Button,
    FormControlLabel,
    Checkbox,
    // Slider,
    IconButton,
    Autocomplete,
    TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditorWysiwyg from './Editor-Wysiwyg/EditorWysiwyg';
import DialogConfirm from './DialogConfirm';

export default function PopUpForm({ messageToEdit, action }) {
    const PopUpMessagesFormRef = useRef(null);
    const navigate = useNavigate();

    // console.log({ messageToEdit, action })

    const { cityCode } = useParams();
    const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
    // const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

    const [popUpMessage, setPopUpMessage] = useState()

    const [author, setAuthor] = useState('');

    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');

    // Définir les dates initiales
    const [startDate, setStartDate] = useState(dayjs());
    const [startDateError, setStartDateError] = useState('');
    // endDate = Date d'aujourd'hui plus un mois par défaut
    const [endDate, setEndDate] = useState(dayjs().add(1, 'month'));
    const [endDateError, setEndDateError] = useState('');

    const [privateVersion, setPrivateVersion] = useState(false);
    const [publicVersion, setPublicVersion] = useState(false);
    const [versionError, setVersionError] = useState('');

    const [autoClose, setAutoClose] = useState(false);
    const [userClose, setUserClose] = useState(false);
    const [displayTime, setDisplayTime] = useState(30000);
    const [orderBy, setOrderBy] = useState(1);

    const [, setTypeText] = useState('');
    const [frMessage, setFrMessage] = useState('');
    const [enMessage, setEnMessage] = useState('');
    const [textError, setTextError] = useState('');

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [open, setOpen] = useState(false);

    // // const [openAutoC, setOpenAutoC] = useState(false);
    // // const [loading, setLoading] = useState(true);
    // // const loading = openAutoC && municipalities.length === 0;

    const [services, setServices] = useState([]);
    const [idService, setIdService] = useState(null);

    const [popUpData, setPopUpData] = useState();

    useEffect(() => {
        const fetchServices = async () => {
            let foundService = null;
            let filteredServices = [];

            try {
                const data = await getServices(cityCode);

                if ((!publicVersion && !privateVersion) || (publicVersion && privateVersion)) {
                    filteredServices = [];
                    foundService = null;
                } else if (publicVersion && !privateVersion) {
                    filteredServices = data.filter(service => service.servicePublic === true);
                    foundService = filteredServices.length > 0 ? filteredServices[0].idService : null;
                } else if (!publicVersion && privateVersion) {
                    filteredServices = data.filter(service => service.servicePublic === false);
                }

                setServices(filteredServices);
                setIdService(foundService);
            } catch (error) {
                console.error('Error fetching services:', error);
                setServices([]);
                setIdService(null);
            }
        };

        fetchServices();
    }, [cityCode, messageToEdit?.idService, privateVersion, publicVersion]);

    useEffect(() => {
        if (action === 'Update') {
            setPopUpData(messageToEdit)
            setAuthor(messageToEdit.author)
            setTitle(messageToEdit.title)
            setStartDate(dayjs(messageToEdit.dateStart))
            setEndDate(dayjs(messageToEdit.dateEnd))
            setPrivateVersion(messageToEdit.privateVersion)
            setPublicVersion(messageToEdit.publicVersion)
            setAutoClose(messageToEdit.autoClose)
            setUserClose(messageToEdit.userClose)
            setDisplayTime(messageToEdit.displayTime)
            setOrderBy(messageToEdit.orderBy)
            setTypeText((messageToEdit.idtypeMessage === 4 || messageToEdit.idtypeMessage === 5) ? 'print' : 'html')
            setFrMessage(messageToEdit.text_fr)
            setEnMessage(messageToEdit.text_en)
            setIdService(messageToEdit.idService)

            // getPopUpMessageById(cityCode, idCityMessage).then((data) => {
            //     console.log(data[0].idService)
            //     // // setAuthorID(data[0].idUser)
            //     // // setAuthorFullname(data[0].fullName)
            //     setPopUpData(data[0])
            //     setAuthor(data[0].author)
            //     setTitle(data[0].title)
            //     setPrivateVersion(data[0].privateVersion)
            //     setPublicVersion(data[0].publicVersion)
            //     setStartDate(dayjs(data[0].dateStart))
            //     setEndDate(dayjs(data[0].dateEnd))
            //     setAutoClose(data[0].autoClose)
            //     setDisplayTime(data[0].displayTime);
            //     setOrderBy(data[0].orderBy)
            //     // // setFrequency(data[0].frequency)
            //     setFrMessage(data[0].text_fr)
            //     setEnMessage(data[0].text_en)
            //     setIdService(data[0].idService)
            // })
        }
    }, [action, messageToEdit]);

    // ---------------------------------------------------------------
    // Configuration de la locale pour Day.js
    dayjs.locale('fr-ca');
    // const today = dayjs().isValid() ? dayjs().locale('fr-ca').format('YYYY-MM-DD HH:mm:ss') : null;
    // ---------------------------------------------------------------


    // données des Options de la liste déroulante des services
    const servicesDefaultProps = {
        options: services,
        getOptionLabel: (option) => option.aliasName ? `${option.idService} - ${option.aliasName}` : ''
    };

    const capitalizeTheFirstLetter = (str) => {
        //seulement la premiere lettre en majuscule
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleStartDateChange = (newValue) => {
        // Vérifier si la date de debut est inférieure à la date d'aujourd'hui
        if (dayjs(newValue).isBefore(dayjs().subtract(1, 'days'))) {
            setStartDateError('La date de début doit être supérieure ou égale à la date d\'aujourd\'hui.');
        } else {
            setStartDate(newValue);
            setEndDate(dayjs(newValue).add(30, 'days'));
            setStartDateError('');
            setEndDateError('');
        }
    };

    const handleEndDateChange = (newValue) => {
        // Vérifier si la date de fin est inférieure à la date de début
        if (dayjs(newValue).isBefore(startDate)) {
            setEndDateError('La date de fin doit être supérieure à la date de début.');
            // Ajouter automatiquement 30 jours à la date de début
            setEndDate(dayjs(startDate).add(30, 'days'));
        } else {
            setEndDate(newValue);
            setEndDateError('');
        }
    };

    // Effacer le contenu detous les champs
    const handleClearAllFields = () => {
        setTitle('')
        setTitleError('')
        setPublicVersion(false)
        setPrivateVersion(false)
        setVersionError('')
        setStartDate(dayjs());
        setStartDateError('');
        setEndDate(dayjs().add(1, 'month'));
        setEndDateError('');
        setAutoClose(false);
        setUserClose(false);
        setDisplayTime(10000);
        setOrderBy(0);
        setFrMessage('')
        setEnMessage('')
        setTextError('')
        setIdService(null)
        // setTypeText('')
    };

    const handleCancel = () => {
        handleClearAllFields()
        navigate(`/dashboard/municipalites/${cityCode}/mu-messages/mu-messages-list`)
    }

    const handleReset = () => {
        // Réinitialiser tous les états
        handleClearAllFields();

        if (action === 'Update') {
            setPopUpData(messageToEdit)
            setAuthor(messageToEdit.author)
            setTitle(messageToEdit.title)
            setStartDate(dayjs(messageToEdit.dateStart))
            setEndDate(dayjs(messageToEdit.dateEnd))
            setPrivateVersion(messageToEdit.privateVersion)
            setPublicVersion(messageToEdit.publicVersion)
            setAutoClose(messageToEdit.autoClose)
            setUserClose(messageToEdit.userClose)
            setDisplayTime(messageToEdit.displayTime)
            setOrderBy(messageToEdit.orderBy)
            setTypeText((messageToEdit.idtypeMessage === 4 || messageToEdit.idtypeMessage === 5) ? 'print' : 'html')
            setFrMessage(messageToEdit.text_fr)
            setEnMessage(messageToEdit.text_en)
            setIdService(messageToEdit.idService)
            // getPopUpMessageById(cityCode, idCityMessage).then((data) => {
            //     setPopUpData(data[0])
            //     setAuthor(data[0].author)
            //     setTitle(data[0].title)
            //     setPrivateVersion(data[0].privateVersion)
            //     setPublicVersion(data[0].publicVersion)
            //     setStartDate(dayjs(data[0].dateStart))
            //     setEndDate(dayjs(data[0].dateEnd))
            //     setAutoClose(data[0].autoClose)
            //     setDisplayTime(data[0].displayTime);
            //     setOrderBy(data[0].orderBy)
            //     setFrMessage(data[0].text_fr)
            //     setEnMessage(data[0].text_en)
            //     setIdService(data[0].idService)
            // })
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // le type de texte est 'html', donc on modifie les messages en conséquence
        const newFrMessage = frMessage ? frMessage.replace(/'/g, '’') : '';
        const newEnMessage = enMessage ? enMessage.replace(/'/g, '’') : '';

        const messageToSave = {
            idCityMessage: action === 'Update' ? popUpData.idCityMessage : null,
            cityCode: currentCity.cityCode,
            dateStart: startDate,
            dateEnd: endDate,
            autoClose: autoClose || false,
            blocking: 0,
            idtypeMessage: 3,
            privateVersion: privateVersion,
            publicVersion: publicVersion,
            userClose: userClose || false,
            orderBy: orderBy,
            frequency: 1,
            nameMessage: title,
            idService: idService ?? null,
            isAzimut: 0,
            author: author || null,
            displayTime: displayTime || null,

            idMessage: action === 'Update' ? popUpData.idMessage : null,
            title: title,
            text_en: newEnMessage,
            text_es: null,
            text_fr: newFrMessage,
        }

        // Validation des champs
        if (title === '')
            setTitleError('Le champ titre est obligatoire.')
        else
            setTitleError('')

        if (!publicVersion && !privateVersion)
            setVersionError('Au moins l\'une des deux versions doit être sélectionnée.')
        else
            setVersionError('')

        if ((frMessage === '' && enMessage === '')) {
            setTextError("Aucun message n'a été saisi. Cela implique qu'il n'y aura pas de message à afficher.");
        } else {
            setTextError('');
        }

        // Vérification de la validité du formulaire
        const isFormValid =
            (title !== '' && !titleError) &&
            ((publicVersion === true || privateVersion === true) && !versionError) &&
            (startDate !== '' && !startDateError) &&
            (endDate !== '' && !endDateError) &&
            ((frMessage !== '' || enMessage !== '') && !textError);

        if (isFormValid) {
            if (action === 'Update') {
                const result = await updateMuMessage(messageToSave);
                if (result)
                    console.log('Soumission du Formulaire de mise à jour pop-up message');
            } else if (action === 'AddNew') {
                const result = await addMuMessage(messageToSave);
                if (result)
                    console.log('Soumission du Formulaire d\'ajout d\'un nouveau pop-up message');
            }

            setIsFormValidated(true);
            setPopUpMessage(messageToSave)
            // permet d'afficher la boite de dialogue à la soumission du formulaire
            setOpen(true);
        } else {
            console.log('Un des champs est manquant ou mal saisi');
            setIsFormValidated(false);
            setOpen(true);
        }
    }

    return (
        <div className='PopUpMessages'>
            {open && (
                <DialogConfirm
                    popUpMessage={popUpMessage}
                    open={open}
                    setOpen={setOpen}
                    action={action}
                    isFormValidated={isFormValidated}
                    titleError={titleError}
                    versionError={versionError}
                    textError={textError}
                    handleClearAllFields={handleClearAllFields}
                />
            )}

            <Paper elevation={5} className='PopUpMessages-paper' sx={{ height: 600, overflowY: 'auto' }}>

                <form ref={PopUpMessagesFormRef} className='PopUpMessages-form' onSubmit={handleSubmit}>

                    <Grid container className='gridContainer' spacing={2} m='0' >

                        <Grid item className='gridItem' xs={12} md={6} lg={6}>
                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                <InputLabel htmlFor="fullname">Auteur</InputLabel>
                                <Input
                                    id="fullname"
                                    name="fullname"
                                    autoComplete="fullname"
                                    disabled
                                    // value={currentUserInfo?.fullName || ''}
                                    value={author || 'Information non précisée'}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
                                <InputLabel htmlFor="titre">Titre du message</InputLabel>
                                <Input
                                    id="titre"
                                    name="titre"
                                    autoComplete="titre"
                                    required

                                    value={title || ''}

                                    onChange={(e) => {
                                        const newValue = capitalizeTheFirstLetter(e.target.value);
                                        setTitle(newValue)
                                        setTitleError('')
                                    }}

                                    endAdornment={
                                        title && (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setTitle('')}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                                {titleError && <Typography variant="caption" color="error">{titleError}</Typography>}
                            </FormControl>
                        </Grid>

                        <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                                <DatePicker
                                    label="Date début d'affichage"
                                    format="YYYY/MM/DD"
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    slotProps={{
                                        textField: {
                                            variant: "filled",
                                            size: "small",
                                            style: { width: '100%', height: '100%' }
                                        }
                                    }}

                                    value={startDate || null}
                                    onChange={(newValue) => {
                                        handleStartDateChange(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                            {startDateError && <p style={{ color: 'red' }}>{startDateError}</p>}
                        </Grid>

                        <Grid item className='gridItem gridItem-date' xs={12} md={6} lg={6} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr-FR" >
                                <DatePicker
                                    label="Date fin d'affichage"
                                    format="YYYY/MM/DD"
                                    openTo="day"
                                    views={["year", "month", "day"]}
                                    slotProps={{
                                        textField: {
                                            variant: "filled",
                                            size: "small",
                                            style: { width: '100%', height: '100%' }
                                        }
                                    }}

                                    value={endDate || null}
                                    onChange={(newValue) => {
                                        handleEndDateChange(newValue)
                                    }}
                                />
                            </LocalizationProvider>
                            {endDateError && <p style={{ color: 'red' }}>{endDateError}</p>}
                        </Grid>

                        <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>
                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                <FormControlLabel className='gridItem-formControlLabel left'
                                    control={
                                        <Checkbox
                                            className='checkbox-version'
                                            id="publicVersion"
                                            name="publicVersion"

                                            checked={publicVersion || false}
                                            onChange={(e) => {
                                                setPublicVersion(e.target.checked)
                                                setVersionError('');
                                            }}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'publicVersion checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className='version-label'>
                                            Version publique
                                        </div>
                                    }
                                    labelPlacement="end"
                                />
                            </FormControl>

                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                <FormControlLabel className='gridItem-formControlLabel right'
                                    control={
                                        <Checkbox
                                            className='checkbox-version'
                                            id="privateVersion"
                                            name="privateVersion"

                                            checked={privateVersion || false}
                                            onChange={(e) => {
                                                setIdService(null)
                                                setPrivateVersion(e.target.checked);
                                                setVersionError('');
                                            }}
                                            color="primary"
                                            inputProps={{ 'aria-label': 'contact checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className='version-label'>
                                            {/* <PermContactCalendarIcon
                  sx={{
                    // color: contact ? 'green' : (municipality ? 'action.active' : 'lightgray'), // Use light gray if disabled

                    // color: contact ? 'green' : 'action.active',
                    marginRight: '0.5rem'
                  }}
                /> */}
                                            Version privée
                                        </div>
                                    }
                                    labelPlacement="end"
                                />
                            </FormControl>
                        </Grid>

                        {(!publicVersion && privateVersion) &&
                            <Grid item className='gridItem' xs={12} md={6} lg={6} >
                                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                    <Stack spacing={1} >
                                        <Autocomplete
                                            {...servicesDefaultProps}
                                            id="services"
                                            name="services"

                                            isOptionEqualToValue={(option, value) => option.idService === value.idService}
                                            // Trouver l'objet complet ou retourner null si non trouvé
                                            value={services.find(service => service.idService === idService) || null}
                                            onChange={(event, newValue) => {
                                                // Si newValue n'est pas null avant d'essayer d'accéder à sa propriété
                                                if (newValue) {
                                                    setIdService(newValue.idService);
                                                } else {
                                                    // Gérer le cas où aucune option n'est sélectionnée ou toute autre valeur par défaut appropriée
                                                    setIdService(null);
                                                }
                                            }}

                                            clearOnEscape
                                            renderInput={(params) => <TextField {...params} label="Services" variant="standard" name="services" />}
                                        />
                                    </Stack>
                                </FormControl>
                            </Grid>
                        }

                        {/* <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>

                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                <FormControlLabel
                                    className='gridItem-formControlLabel left'
                                    control={
                                        <Checkbox
                                            className='checkbox-version'
                                            id="autoClose"
                                            name="autoClose"

                                            checked={autoClose || false}
                                            onChange={(e) =>
                                                setAutoClose(e.target.checked)
                                            }

                                            color="primary"
                                            inputProps={{ 'aria-label': 'autoClose checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className='version-label'>
                                            Auto-fermeture
                                        </div>
                                    }
                                    labelPlacement="end"
                                />
                            </FormControl>

                            <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                                <FormControlLabel
                                    className='gridItem-formControlLabel right'
                                    control={
                                        <Checkbox
                                            className='checkbox-version'
                                            id="userClose"
                                            name="userClose"

                                            checked={userClose || false}
                                            onChange={(e) =>
                                                setUserClose(e.target.checked)
                                            }

                                            color="primary"
                                            inputProps={{ 'aria-label': 'userClose checkbox' }}
                                        />
                                    }
                                    label={
                                        <div className='version-label'>
                                            Fermeture par l'utilisateur
                                        </div>
                                    }
                                    labelPlacement="end"
                                />
                            </FormControl>

                        </Grid>

                        <Grid item className='gridItem' xs={12} md={6} lg={6} >
                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                                <div className='sliderLabel'>
                                    <Typography gutterBottom>Temps d'affichage &nbsp; </Typography>
                                    <div className='sliderInput'>

                                        <Input
                                            size="small"

                                            value={displayTime / 1000 || 0}
                                            onChange={(e) => {
                                                setDisplayTime(e.target.value === '' ? 0 : Number(e.target.value) * 1000);
                                            }}

                                            inputProps={{
                                                step: 1,
                                                min: 0,
                                                max: 60,
                                                type: 'number',
                                            }}
                                        />
                                        <Typography gutterBottom>
                                            &nbsp; <span style={{ fontStyle: 'italic', fontSize: 'small' }}>sec</span>
                                        </Typography>
                                    </div>
                                </div>

                                <Slider
                                    aria-label="Temps"
                                    defaultValue={displayTime / 1000 || 0}
                                    valueLabelDisplay="auto"
                                    valuelabelplacement="bottom"

                                    value={displayTime / 1000 || 0}
                                    onChange={(e) => {
                                        setDisplayTime(e.target.value === '' ? 0 : Number(e.target.value) * 1000);
                                    }}

                                    step={1}
                                    min={0}
                                    max={60}
                                    aria-labelledby="input-slider"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item className='gridItem' xs={12} md={6} lg={6}>
                            <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
                                <div className='orderBy'>
                                    <Typography gutterBottom>Ordre d'affichage &nbsp; </Typography>
                                    <Input
                                        className='orderBy-control'
                                        id="orderBy"
                                        name="orderBy"

                                        size="small"

                                        value={orderBy || 0}
                                        onChange={(e) => {
                                            setOrderBy(e.target.value === '' ? 0 : Number(e.target.value));
                                        }}

                                        inputProps={{
                                            step: 1,
                                            min: 0,
                                            max: 100,
                                            type: 'number',
                                        }}
                                    />
                                </div>
                            </FormControl>
                        </Grid> */}

                        <hr />
                        <Grid item className='gridError' xs={12} md={12} lg={12} >
                            {versionError && <Typography variant="caption" color="error">{versionError}</Typography>}
                        </Grid>


                        <Grid item className='gridError' xs={12} md={12} lg={12} >
                            {textError && <Typography variant="caption" color="error">{textError}</Typography>}
                        </Grid>

                        <hr />

                        <EditorWysiwyg language={'français'} message={frMessage} setMessage={setFrMessage} setMessageError={setTextError} />

                        <EditorWysiwyg language={'anglais'} message={enMessage} setMessage={setEnMessage} setMessageError={setTextError} />

                        <hr />

                    </Grid>

                    <div className='footerPopUpgMessages'>

                        <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }} onClick={handleCancel}>
                            Annuler
                        </Button>

                        <Button className='btnSubmit' type="reset" variant="contained" sx={{ width: '200px', cursor: 'pointer' }} onClick={handleReset}>
                            Réinitialiser
                        </Button>

                        <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
                            Enregistrer
                        </Button>
                    </div>

                </form>
            </Paper>
        </div>

    );
}
