
import React from 'react';
// import './EditorWysiwyg.scss'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export default function EditorWysiwyg(props) {

  const { typeText, language, message, setMessage, setMessageError, disabled } = props

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      [{ 'color': [] }], // Ajout de l'option couleur
      ['clean']
    ],
  }
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image',
    'color' // Ajout du format couleur
  ]

  const handleChange = (content, delta, source, editor) => {

    // Accéder à la propriété getText de l'instance de React Quill
    const textContent = editor.getText().trim();

    if (textContent !== '') {
      // Remplacer tous les caractères simples (') par des apostrophes courbes (’)
      const updatedContent = content.replace(/'/g, '’');

      if (typeText === 'print') {
        // Remplacer les retours à la ligne par '\n'
        const textWithNewLines = updatedContent.replace(/\\n/g, '<br />');
        setMessage(textWithNewLines);

      } else if (typeText === 'html') {
        // Garder le texte avec les balises HTML
        setMessage(textContent !== '' ? updatedContent : '');
      }

      setMessageError('')
    } else {
      setMessage('');
    }
  };

  return (
    <div className="container">

      <div className="editorLabel">
        Message en {language}
      </div>

      <div className="editor" >
        <ReactQuill
          readOnly={disabled}
          theme="snow"
          modules={modules}
          formats={formats}
          value={message}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

// <div className="preview" hidden
// dangerouslySetInnerHTML={{ __html: value }}
// />
