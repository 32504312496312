import React, { useState, useMemo, useEffect } from "react";
import { Box } from '@mui/material'
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import './GridTable.scss'
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/fr';


const normalizeString = (str) => {
    // Normalise la chaîne de caractères en tenant compte des caractères spéciaux français
    // exp : remplace les caractères accentués par leur équivalent non accentué
    return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
};

function CustomToolbar({ setFilterButtonRef }) {
    return (
        <div className='grid-customToolbar-container'>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton ref={setFilterButtonRef} />
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Données-municipalités',
                        delimiter: ';',
                        utf8WithBom: true,
                        columnsToExport: ['cityCode', 'cityName', 'mrcCode', 'mrcName', 'populationYear', 'fuseau', 'maxDateMaj', 'url'],
                    }}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>
        </div>
    );
}

CustomToolbar.propTypes = {
    setFilterButtonRef: PropTypes.func.isRequired
};

// const generateInitialState = (defaultValue) => {
//     const fields = ['cityCode', 'cityName', 'field3', 'mrcCode', 'mrcRealName', 'population', 'fuseau', 'maxDateMaj', 'url'];
//     const filterItems = fields.map((field) => ({
//         field,
//         value: defaultValue,
//     }));

//     return {
//         pagination: { paginationModel: { pageSize: 50 } },
//         filter: {
//             filterModel: {
//                 items: filterItems,
//             },
//         },
//     };
// };

// const defaultValue = 'xxxxxxxx';
// const initialState = generateInitialState(defaultValue);


export default function GridTable({ data }) {

    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(data)
    }, [data]);

    const handleMuniLocalStorage = (currentMunicipality) => {
        localStorage.setItem('currentMunicipality', JSON.stringify(currentMunicipality))
    }

    const CustomCellContent = ({ value }) => {
        return (
            <div className="cellContent" title={value}>
                {value}
            </div>
        );
    };

    const columns = useMemo(
        () => [
            {
                field: 'cityCode',
                headerName: 'Code',
                width: 100,
                editable: false,
                hide: true,
                renderCell: (params) => (
                    <CustomCellContent value={params.value} />
                ),
            },
            {
                field: 'cityName',
                headerName: 'Municipalité',
                type: 'string',
                valueGetter: (params) => params.value && `${normalizeString(params.value)} ${params.value} `,
                width: 240,
                editable: false,
                renderCell: (params) => {
                    const { cityCode, cityName } = params.row;
                    // console.log(normalizeString(cityName))
                    // console.log(cityName)

                    // Vérifie si cityCode ne commence pas par "C00" ou "c00"
                    const showLink = !cityCode.startsWith('C00') && !cityCode.startsWith('c00');

                    return (
                        showLink ? (
                            <Link
                                className="cellContent"
                                to={`/dashboard/municipalites/${params.row.cityCode}/mu-statistiques`}
                                onClick={() => handleMuniLocalStorage(params.row)}
                            >
                                {cityName}
                            </Link>
                        ) : (
                            <span>{cityName}</span>
                        )
                    );
                },
            },
            { field: 'mrcCode', headerName: 'Code MRC', width: 100, editable: false, },
            { field: 'mrcRealName', headerName: 'MRC', width: 200, editable: false },
            { field: 'population', headerName: 'Population', width: 100, editable: false, },
            { field: 'fuseau', headerName: 'Fuseau', width: 100, editable: false, },
            { field: 'maxDateMaj', headerName: 'MAJ', width: 100, editable: false,
                renderCell: (params) => (
                    <span>{moment(params.value).format('YYYY-MM-DD')}</span>
                )
            },
            { field: 'url', headerName: 'URL', width: 240, editable: false,
                renderCell: (params) => (
                    <a className="cellContent" href={params.row.url} target="_blank" rel="noopener noreferrer">GOnet {params.row.cityName}</a>
                ),

            },
        ],
        []
    );

    return (
        <Box
            className='dataGridBox'
            sx={{
                width: '100%',
            }}
        >
            <DataGrid
                slots={{
                    toolbar: CustomToolbar,
                }}

                slotProps={{
                    panel: {
                        anchorEl: filterButtonRef,
                    },
                    toolbar: {
                        setFilterButtonRef,
                    }
                }}

                // initialState={initialState}
                initialState={{
                    pagination: { paginationModel: { pageSize: 50 } },
                }}
                pageSizeOptions={[25, 50, 100]}

                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                // getRowSpacing={(params) => ({
                //     top: params.isFirstVisible ? 0 : 5,
                //     bottom: params.isLastVisible ? 0 : 5,
                // })}

                columns={columns}
                rows={rows}
                getRowId={(row) => row.cityCode}
            />
        </Box>
    )
}
