import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router'
import './Dashboard.scss'
import Sidebar from '../../layout/Sidebar/Sidebar'
import LandingPage from '../../layout/Landing/LandingPage';
import { useAuth } from '../../contexts/AuthContext';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';

export default function Dashboard() {
    const { currentUser } = useAuth();
    const location = useLocation();

    const [isSidebarOpened, setIsSidebarOpened] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpened(!isSidebarOpened);
    };

    return (
        currentUser &&
        <>
            <div className="menuOpenIcon" onClick={toggleSidebar}>
                {isSidebarOpened ? <MenuOpenIcon /> : <MenuIcon />}
            </div>

            <div className='Dashboard'>
                {isSidebarOpened && (
                    <div className="leftSide">
                        <Sidebar />
                    </div>
                )}
                <div className={`mainSide ${isSidebarOpened ? 'mainSideReducedScreen' : 'mainSideFullScreen'}`}>
                    {
                        location.pathname === '/dashboard' ? <LandingPage /> : <Outlet />
                    }
                </div>
            </div>
        </>
    )
}
