import { useEffect, useState } from "react";
import './SpanningTable.scss';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Paper } from "@mui/material";
import moment from 'moment';
import "moment/locale/fr";

export default function SpanningTable({ dataTable }) {

  const [formattedDuration, setFormattedDuration] = useState("");

  useEffect(() => {
    const totalSeconds = dataTable.reduce((total, row) => total + moment.duration(row.Duree).asSeconds(), 0) / dataTable.length;
    const duration = moment.duration(totalSeconds, "seconds");
    const formattedDuration = `${duration.hours()}h ${duration.minutes()}min ${duration.seconds()}s`;
    setFormattedDuration(formattedDuration);
  }, [dataTable]);

  const styles = {
    row: {
      backgroundColor: '#fff'
    },
    oddRow: {
      backgroundColor: '#e0e0e9'
    }
  };
  return (

    <TableContainer component={Paper} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
      <Table size="small">

        <TableHead className="sticky-row">

          <TableRow className="header-row ">
            <TableCell style={{ backgroundColor: "white" }} />
            <TableCell style={{ backgroundColor: "white" }} />
            <TableCell style={{ backgroundColor: "white" }} />
            <TableCell style={{ backgroundColor: "white" }} />
            <TableCell style={{ textAlign: "center", backgroundColor: "#6b819c", color: "white" }} colSpan={2}>Moyennes par session</TableCell>
          </TableRow>

          <TableRow className="header-row ">
            <TableCell style={{ textAlign: "center", color: "black", fontSize: "16px", fontWeight: "600" }} >Mois</TableCell>
            <TableCell style={{ textAlign: "center", color: "black", fontSize: "16px", fontWeight: "600" }} >Utilisateurs</TableCell>
            <TableCell style={{ textAlign: "center", color: "black", fontSize: "16px", fontWeight: "600" }} >Sessions</TableCell>
            <TableCell style={{ textAlign: "center", color: "black", fontSize: "16px", fontWeight: "600" }} >Requêtes</TableCell>
            <TableCell style={{ textAlign: "center", backgroundColor: "#6b819c", color: "white" }}>Requêtes</TableCell>
            <TableCell style={{ textAlign: "center", backgroundColor: "#6b819c", color: "white" }}>Durée</TableCell>

          </TableRow>
        </TableHead>

        <TableBody>
          {dataTable.map((row, index) => (
            <TableRow key={index} style={{ height: '30px', ...(index % 2 === 0 ? styles.row : styles.oddRow) }}>
              <TableCell style={{ textAlign: "center" }}>{moment().month(row.Mois - 1).format('MMMM')}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{row.Utilisateurs.toLocaleString('fr-FR')}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{row.Sessions.toLocaleString('fr-FR')}</TableCell>
              <TableCell style={{ textAlign: "center" }}>{row.Requetes.toLocaleString('fr-FR')}</TableCell>
              <TableCell style={{ textAlign: "center", backgroundColor: "#537db1", color: "white" }}>{row.RequetesParSession}</TableCell>
              <TableCell style={{ textAlign: "center", backgroundColor: "#537db1", color: "white" }}>{row.Duree}</TableCell>
            </TableRow>
          ))}
          <TableRow className="total-row ">
            <TableCell style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}>Total :</TableCell>
            <TableCell style={{ textAlign: "center" }}>{dataTable.reduce((total, row) => total + row.Utilisateurs, 0).toLocaleString('fr-FR')}</TableCell>
            <TableCell style={{ textAlign: "center" }}>{dataTable.reduce((total, row) => total + row.Sessions, 0).toLocaleString('fr-FR')}</TableCell>
            <TableCell style={{ textAlign: "center" }}>{dataTable.reduce((total, row) => total + row.Requetes, 0).toLocaleString('fr-FR')}</TableCell>
            {/* <TableCell style={{ textAlign: "center" }}>{dataTable.reduce((total, row) => total + row.RequetesParSession, 0).toFixed(2)}</TableCell> */}
            <TableCell style={{ textAlign: "center", backgroundColor: "#537db1", color: "white" }}></TableCell>
            <TableCell style={{ textAlign: "center", backgroundColor: "#537db1", color: "white" }}></TableCell>
          </TableRow>

          <TableRow className="average-row">
            <TableCell style={{ textAlign: "center", fontSize: "16px", fontWeight: "600" }}>Moyenne :</TableCell>
            <TableCell style={{ textAlign: "center" }}>{(dataTable.reduce((total, row) => total + row.Utilisateurs, 0) / dataTable.length).toLocaleString('fr-FR', { maximumFractionDigits: 0 })}</TableCell>
            <TableCell style={{ textAlign: "center" }}>{(dataTable.reduce((total, row) => total + row.Sessions, 0) / dataTable.length).toLocaleString('fr-FR', { maximumFractionDigits: 0 })}</TableCell>
            {/* <TableCell style={{ textAlign: "center" }}>{(dataTable.reduce((total, row) => total + row.Requetes, 0) / dataTable.length).toFixed(0)}</TableCell> */}
            <TableCell style={{ textAlign: "center" }}>{(dataTable.reduce((total, row) => total + row.Requetes, 0) / dataTable.length).toLocaleString('fr-FR', { maximumFractionDigits: 0 })}</TableCell>

            <TableCell style={{ textAlign: "center", backgroundColor: "#6b819c", color: "white" }}>{(dataTable.reduce((total, row) => total + row.RequetesParSession, 0) / dataTable.length).toLocaleString('fr-FR', { maximumFractionDigits: 2 })}</TableCell>
            <TableCell style={{ textAlign: "center", backgroundColor: "#6b819c", color: "white" }}>{formattedDuration}</TableCell>

          </TableRow>
        </TableBody>

      </Table>
    </TableContainer>


  );
}
