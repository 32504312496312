import dataService from "../../../httpService";

// Fonction pour récupérer un pop-up par son id
async function getPopUpMessageById(cityCode, idCityMessage) {
    try {
        const response = await dataService.get('/messages/pop-up-message-byId', {
            params: {
                cityCode: cityCode,
                idCityMessage: idCityMessage
            }
        })
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour ajouter un nouveau pop-up message à la municipalité
async function addMuMessage(messageToAdd) {
    try {
        const response = await dataService.post('/messages/add-new-message', messageToAdd)
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour mettre à jour un pop-up message
async function updateMuMessage(messageToUpdate) {
    try {
        const response = await dataService.put('/messages/update-message', messageToUpdate)
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour supprimer un pop-up message (row) parmis la liste de sélections (rows)
async function deleteSelectedPopUpMessage(row) {
    try {
        const response = await dataService.delete('/messages/delete-message', {
            params: {
                idCityMessage: row.idCityMessage,
                idMessage: row.idMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste de tous les profils
async function getServices(cityCode) {
    try {
        const response = await dataService.get('/user/municipality/services', {
            params: {
                cityCode
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les rôles possible de les sélectionner
async function getRoles() {
    try {
        const response = await dataService.get('/user/roles');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// liste des message pop-up type 3 avec ses détails pour une municipalité donnée
async function getPopUpMessagesList(cityCode) {
    try {
        const response = await dataService.get('/messages/pop-up-messages-list', {
            params: {
                cityCode: cityCode
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir les données des images d'une municipalité
async function getImagesDetails(cityCode) {
    try {
        const response = await dataService.get('/user/municipalitie/images', {
            params: {
                cityCode,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des tyes de messages d'avertissement
async function getTypeMessageList() {
    try {
        const response = await dataService.get('/messages/message-type-list');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des détails des messages d'avertissement selon la municipalité cityCode
async function getMessagesList(cityCode, idtypeMessage) {
    try {
        const response = await dataService.get('/messages/messages-list', {
            params: {
                cityCode,
                idtypeMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des messages d'avertissement de la municipalité
async function getCityMessagesList(idCityMessage) {
    try {
        const response = await dataService.get('/messages/city-messages-list', {
            params: {
                idCityMessage,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}



// Exporter les fonctions
export {
    getPopUpMessageById,
    addMuMessage,
    getServices,
    updateMuMessage,
    deleteSelectedPopUpMessage,
    getRoles,
    getPopUpMessagesList,
    getImagesDetails,
    getTypeMessageList,
    getMessagesList,
    getCityMessagesList,
};