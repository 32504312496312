import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
// import MuHeader from '../../../../components/mu-header/MuHeader';
import './MuImages.scss';
import { getImagesDetails } from '../httpQuerys';
import PermMediaRoundedIcon from '@mui/icons-material/PermMediaRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Card from './Card';
// import DialogConfirmImages from './DialogConfirmImages';

import Grid from '@mui/material/Grid';

export default function MuImages({ allowedManagement }) {

  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));

  // console.log(currentCity)

  const cityNameDescription = currentCity.cityName ?
    (currentCity.article === 'La' ?
      `de la ${currentCity.description}`
      : `du ${currentCity.description}`
    )
    : 'pas';

  // const cityName = currentCity.cityName ? `${cityNameDescription} ${currentCity.cityName}` : null;

  // const tabLogo = currentCity.logo.split('/')
  // const logoNameFile = tabLogo[tabLogo.length - 1]

  const [logo, setLogo] = useState('');
  const [fileNameLogo, setFileNameLogo] = useState('');

  // const [logo, setLogo] = useState(currentCity.logo ? currentCity.logo : 'https://www.goazimut.com/GOnet6/images/print/Azimut_print.png');
  // const [fileNameLogo, setFileNameLogo] = useState(currentCity.logo ? logoNameFile : 'azimut-logo-print.png');
  // const [fileNameLogo, setFileNameLogo] = useState(currentCity.logo ? logoNameFile : 'Pas de fichier sélectionné...');


  const [fondEcran, setFondEcran] = useState('');
  const [fileNameFondEcran, setFileNameFondEcran] = useState('');
  // const [fileNameFondEcran, setFileNameFondEcran] = useState('Pas de fichier sélectionné...');

  const [imageFile, setImageFile] = useState();

  useEffect(() => {
    getImagesDetails(cityCode).then(data => {
      // console.log(data);
      setLogo(data.logoUrl);
      setFileNameLogo(data.logo)
      setFondEcran(data.splashUrl);
      setFileNameFondEcran(data.splashFileName)
    });
  }, [cityCode]);


  return (
    currentCity &&
    <div className='main-wrapper'>

      <div className='main-container-MuImages'>

        <div className="topPage">
          <div className="topTitle">
            <PermMediaRoundedIcon />
            <div>Modifier Logo / Fond d'écran {cityNameDescription}  </div>
            <span><em>{currentCity.cityName}</em></span>
          </div>
          <div className="topNav">
            <Link className='topNav-close' to={`/dashboard/municipalites/${cityCode}/mu-statistiques`} style={{ textDecoration: "none", cursor: 'pointer' }}>
              <CloseRoundedIcon />
            </Link>
            {/* {allowedManagement ? (
              <DialogConfirmImages
                cityCode={cityCode}
                cityName={currentCity.cityName}
                fileNameLogo={fileNameLogo}
                fileNameFondEcran={fileNameFondEcran}
                imageFile={imageFile}
              />
            ) : null} */}
          </div>
        </div>

        <Grid
          className="cards-container"
          container
          rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          columnSpacing={5}
        >

          <Grid item className="card-item first-item" xs={12} sm={12} md={6} lg={6}>
            <Card
              cityCode={cityCode}
              cityName={currentCity.cityName}
              text={"LOGO"}
              image={logo}
              setImage={setLogo}
              fileName={fileNameLogo}
              setFileName={setFileNameLogo}
              allowedManagement={allowedManagement}
              imageFile={imageFile}
              setImageFile={setImageFile}
            />
          </Grid>

          <Grid item className="card-item second-item" xs={12} sm={12} md={6} lg={6}>
            <Card
              cityCode={cityCode}
              cityName={currentCity.cityName}
              text={"FOND D'ÉCRAN"}
              image={fondEcran}
              setImage={setFondEcran}
              fileName={fileNameFondEcran}
              setFileName={setFileNameFondEcran}
              allowedManagement={allowedManagement}
              imageFile={imageFile}
              setImageFile={setImageFile}
            />
          </Grid>

        </Grid>

      </div>
    </div>
  )
}
