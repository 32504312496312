import React, { useEffect, useState } from "react";
import './MessagesList.scss';
import GridTable from "../data-grid/GridTable";
import SearchBar from "../../../components/search-bar/SearchBar";
import { getAllMessagesData } from "../httpQuerys";

export default function MessagesList({ idUser, allowedManagement }) {

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [mustReload, setMustReload] = useState();
  // const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

  useEffect(() => {
    getAllMessagesData(idUser).then((data) => {
      // console.log(data.filter(item => [1, 3].includes(item.idtypeMessage)))
      setData(data.filter(item => [1, 3].includes(item.idtypeMessage)))
      setMustReload(false)
    });
  }, [idUser, mustReload]);

  // search bar fonction
  const tabKeys = ['idCityMessage', 'nameMessage', 'cityCode', 'cityName', 'mrcRealName', 'idtypeMessage'];
  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    <div className='MessagesList'>
      <SearchBar query={query} setQuery={setQuery} />
      <GridTable data={showList(data)} setData={setData} setMustReload={setMustReload} allowedManagement={allowedManagement} />
    </div>
  );
}