import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { QueryClient, QueryClientProvider } from 'react-query';//++++++++++++++++

const queryClient = new QueryClient();//++++++++++++++++

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>{/* ++++++++++ */}
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>{/* ++++++++++ */}
    </BrowserRouter>
  </React.StrictMode>,
);
