import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import './EditOneUserOfAllForm.scss'
import {
  getUserDetail,
} from "../httpQuerys";
import {
  transformResultToUserToEdit,
} from '../FunctionsUtils'
import { Paper, Button } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DialogConfirm from './DialogConfirm';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import UserInfoForm from './User-Info-Form/UserInfoForm';
import UserProfilesServicesForm from './User-Profiles-Services-Form/UserProfilesServicesForm';

export default function EditOneUserOfAllForm(params) {

  const { idUser } = params;
  const editOneUserOfAllFormRef = useRef(null);

  const [userToEdit, setUserToEdit] = useState();
  const [lastUserValues, setLastUserValues] = useState();
  const [fullNameError, setFullNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordStrength, setPasswordStrength] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telephoneError, setTelephoneError] = useState('');

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [open, setOpen] = useState(false);

  //  J'utilise à ce niveau userQuery pour éviter de télécharger les données d'un utilisateur inutilement
  // et exploiter la cache ceci est l'equivalent de useEffect
  const userDetailQuery = useQuery(['userDetail', idUser], () => getUserDetail(idUser));
  // console.log(userDetailQuery.data)
  useEffect(() => {
    if (userDetailQuery.data) {
      const userDetail = transformResultToUserToEdit(userDetailQuery.data)
      setUserToEdit(userDetail);
      setLastUserValues(userDetail);
    }
  }, [userDetailQuery.data]);

  // useEffect(() => {
  //   getUserDetail(idUser).then(data => { setUserToEdit({ ...data[0] }); setLastUserValues({ ...data[0] }); });
  //   getTitles().then(data => setTitles(data));
  //   getDepratements().then(data => setDepartments(data));
  // }, [idUser]);

  //!----------------------------------------------------------------

  const handleReset = () => {
    console.log('reset user information')
    setUserToEdit(lastUserValues);
    setFullNameError('')
    setPasswordError('')
    setPasswordStrength('')
    setEmailError('')
    setTelephoneError('')
  }

  // ----------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (userToEdit.fullName !== '' && !fullNameError) &&
      (userToEdit.password !== '' && passwordStrength !== 'Faible') &&
      (userToEdit.email !== '' && !emailError) &&
      ((userToEdit.telephone !== '' && !telephoneError) || userToEdit.telephone === '') &&
      (userToEdit.profiles.length > 0)
    ) {
      const userUpdated = {
        ...userToEdit,
        profiles: userToEdit.profiles.map(profile => ({
          ...profile,
          // Si la date d'expiration n'est pas valide, assigner null
          expiration: dayjs(profile.expiration).isValid() ? profile.expiration : null
        }))
      };

      // console.log({userUpdated})
      setUserToEdit(userUpdated)
      console.log('Le formulaire peut être soumis ');
      setIsFormValidated(true)
    } else {
      console.log('Un des champs est manquant ou mal saisi')
      setIsFormValidated(false);
    }
    // permet d'afficher la boite de dialogue à la soumission du formulaire
    setOpen(true);
  }

  return (

    userToEdit &&
    <div className="editOneUserOfAll">
      {open && (
        <DialogConfirm
          userToEdit={userToEdit}
          lastUserValues={lastUserValues}
          open={open}
          setOpen={setOpen}
          isFormValidated={isFormValidated}
        />
      )}

      <div className="topPage">
        <div className="topTitle">
          <ManageAccountsRoundedIcon />
          <div>Edition de l'utilisateur</div>
          <span><em>{idUser}</em></span>
        </div>
        <div className="topNav">
          <Link
            className="topNav-close"
            to={`/dashboard/utilisateurs`}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <CloseRoundedIcon />
          </Link>
          <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
            Enregistrer
          </Button>
        </div>
      </div>

      <Paper elevation={5} className='editOneUserOfAll-paper' sx={{ height: 600, overflowY: 'auto' }}>
        <form ref={editOneUserOfAllFormRef} className='editOneUserOfAll-form' onSubmit={handleSubmit}>

          <UserInfoForm
            userToEdit={userToEdit}
            setUserToEdit={setUserToEdit}
            fullNameError={fullNameError}
            setFullNameError={setFullNameError}
            emailError={emailError}
            setEmailError={setEmailError}
            telephoneError={telephoneError}
            setTelephoneError={setTelephoneError}
            passwordError={passwordError}
            setPasswordError={setPasswordError}
            passwordStrength={passwordStrength}
            setPasswordStrength={setPasswordStrength}
          />

          <hr />

          <UserProfilesServicesForm
            userToEdit={userToEdit}
            setUserToEdit={setUserToEdit}
          />

          <hr />

          <div className='footerEditOneUserOfAll'>
            <Link to={`/dashboard/utilisateurs`} style={{ textDecoration: "none" }}>
              <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                Annuler
              </Button>
            </Link>

            <Button
              className='btnSubmit'
              variant="contained"
              sx={{ width: '200px', cursor: 'pointer' }}
              disabled={userToEdit === lastUserValues}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>

            <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
              Enregistrer
            </Button>
          </div>

        </form>
      </Paper>
    </div>
  )
}
