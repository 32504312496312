import React, { useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import { useNavigate } from 'react-router';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { InputAdornment } from '@mui/material';
import styled from '@emotion/styled';
// import { checkUserPassword, sendEmailToUpdateMuImages, updateMuImagesDetails } from '../httpQuerys';
import { checkUserPassword, sendEmailToUpdateMuImages } from '../httpQuerys';



const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  m: '10px',
  width: '240px',
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  "&:disabled": {
    backgroundColor: 'grey',
    color: 'white'
  }
}))

export default function DialogConfirmImages({ open, setOpen, cityCode, cityName, text, fileName, imageFile }) {

  // console.log(cityCode, cityName, text, fileName, imageFile)

  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);

  const [password, setPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)

    navigate(`/dashboard/municipalites/${cityCode}/mu-configuration`);
    // navigate(`/dashboard/utilisateurs`);
  };


  const handleCancel = () => {
    setOpen(false);
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  // Vérifie si la touche pressée est la touche "Échap" (code 27) pour fermer la fenêtre
  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      handleClose(); // Appelle la fonction handleClose
    }
  };

  const handleChange = (e) => {
    setPassword(e.target.value)
    if (e.target.value === '')
      setErrorPassword(false)
  }

  const [showPassword, setShowPassword] = useState(false);
  const handleClickPassword = () => {
    setShowPassword(!showPassword)
  }



  //! =================================================================
  // Nettoyage du nom de la ville
  // function generateFileName(cityName, fileNameLogo) {
  //   // supprimer les caractères non-alphanumériques
  //   const cleanedCityName = cityName.replace(/[^a-zA-Z0-9]/g, ' ');

  //   // Sépare les mots par les espaces, puis les rejoint sans espace
  //   // Le filtre supprime les éléments vides
  //   const words = cleanedCityName.split(' ').filter(Boolean);
  //   let baseFileName = words.join('');

  //   // Récupération de l'extension du fichier
  //   const fileExtension = fileNameLogo.split('.').pop();

  //   // Génération du nom de fichier avec le nom de la ville nettoyé, timestamp et extension
  //   const timestamp = Date.now();
  //   const fileName = `${baseFileName}_${timestamp}.${fileExtension}`;

  //   return fileName;
  // }

  //! =================================================================
  //! =================================================================

  // async function uploadFile(file) {
  // function uploadFile(fileNameLogo) {
    // Créer un nom de fichier unique
    // const fileName = generateFileName(cityName, fileNameLogo);
    // console.log(fileName)

    // Créer un FormData et y ajouter le fichier avec le nouveau nom
    // const formData = new FormData();
    // formData.append('file', file, fileName);

    // Faire une requête POST vers le serveur pour uploader le fichier
    // try {
    //   // ! envoyer une requete pour mettre à jour la configuration des images
    //   updateMuImagesDetails(cityCode, fileNameLogo, newFileNameLogo, fileNameFondEcran).then(data => {
    //     console.log(data);
    //   });


    //   if (response.ok) {
    //     console.log('Fichier uploadé avec succès');
    //   } else {
    //     console.error('Erreur lors de l\'upload du fichier');
    //   }
    // } catch (error) {
    //   console.error('Erreur réseau lors de l\'upload du fichier :', error);
    // }
  // }


  const handleConfirm = async () => {
    // Vérification du mot de passe de l'utilisateur
    const passwordMatch = await checkUserPassword(password);

    if (passwordMatch) {

      await sendEmailToUpdateMuImages(currentUserInfo, cityCode, cityName, text, fileName, imageFile)

      //   //! Appeler la fonction pour renommer et uploader le fichier
      //   uploadFile(fileNameLogo)
      // setOpen(false);
      setOpenSecondDialog(true);
      // navigate(`/dashboard/municipalites/${cityCode}/mu-configuration`);
    } else {
      setErrorPassword(true)
    }

  };

  return (
    openSecondDialog ? (

      <Dialog open={openSecondDialog} onClose={handleClose} onKeyDown={handleEscape}>
        <DialogTitle>Confirmation d'envoi de courriel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Un courriel pour demande d'approbation de l'image a été envoyé.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>

    ) : (
      <div>
        <SaveButton type="submit" variant="contained" onClick={handleClickOpen}>
          Envoyer une demande
        </SaveButton>

        <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
          <DialogTitle>Confirmer les modifications</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Pour confirmer les modification à apporter aux images vous êtes demandé
              d'entrer votre mot de passe par mesures de sécurité !
            </DialogContentText>

            <TextField
              id="confirmation"
              name="confirmation"
              label="Mot de passe"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              variant="standard"
              autoFocus
              margin="dense"

              InputProps={{
                style: { width: 300, color: 'blue' },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleClickPassword}
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}

              value={password}
              onChange={handleChange}

              error={errorPassword}
              FormHelperTextProps={{ sx: { color: 'red' } }}
            />
            {errorPassword && (
              <FormHelperText error={errorPassword}>Mot de passe invalide</FormHelperText>
            )}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>ANNULER</Button>
            <Button onClick={handleConfirm}>CONFIRMER</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
}