import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './AddNewUser.scss';
// import dayjs from 'dayjs';
// import 'dayjs/locale/fr-ca';
import {
  validatePassword,
  validateField,
  validateEmail,
  validateUserName,
} from '../FunctionsUtils';
import {
  addUserMunicipalitiesAndServices
} from '../httpQuerys'
import {
  Paper,
  Button,
} from '@mui/material';
import UserInfoForm from './User-Info-Form/UserInfoForm';
import UserProfilesServicesForm from './User-Profiles-Services-Form/UserProfilesServicesForm';
import DialogConfirm from './DialogConfirm';


export default function AddNewUser(params) {
  const addNewUserFormRef = useRef(null);
  // const { gde } = JSON.parse(localStorage.getItem('portailclient'));
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  const { idGroup } = currentUserInfo;

  const [newUserToAdd, setNewUserToAdd] = useState(
    {
      idGroup: idGroup,
      prenom: '',
      nom: '',
      fullName: '',
      userName: '',
      password: '',
      email: '',
      telephone: '',
      ext: '',
      genre: null,
      title: null,
      idtitle: null,
      Description: '',
      iddepartment: null,
      roleName: '',
      idRole: null,
      userProfiles: [],
    }
  )

  const [prenomError, setPrenomError] = useState('');
  const [nomError, setNomError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telephoneError, setTelephoneError] = useState('')
  const [passwordStrength, setPasswordStrength] = useState('');

  const [isAccordionValidated, setIsAccordionValidated] = useState(false);
  const [isFormValidated, setIsFormValidated] = useState(false);
  const [open, setOpen] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log({ newUserToAdd })

    setIsAccordionValidated('')

    validateField(newUserToAdd.prenom, 'Prénom', setPrenomError);
    validateField(newUserToAdd.nom, 'Nom', setNomError);
    await validateUserName(newUserToAdd.userName, "Identifiant de l'utilisateur", setUserNameError);
    validateEmail(newUserToAdd.email, setEmailError)
    validatePassword(newUserToAdd.password, setPasswordError, setPasswordStrength)

    if (newUserToAdd.userProfiles.length === 0) {
      setIsAccordionValidated('Aucun profil n\'est sélectionné')
    } else {
      setIsAccordionValidated('')
    }

    let validateForm =
      (newUserToAdd.prenom !== '' && !prenomError) &&
      (newUserToAdd.nom !== '' && !nomError) &&
      (newUserToAdd.userName !== '' && !userNameError) &&
      (newUserToAdd.userProfiles.length > 0) &&
      (newUserToAdd.password !== '') &&
      (newUserToAdd.email !== '' && !emailError)
    // (newUserToAdd.password !== '' && !passwordError) &&
    // (newUserToAdd.telephone !== '' && !telephoneError) &&

    // console.log({ validateForm })

    if (validateForm) {
      const result = await addUserMunicipalitiesAndServices(newUserToAdd);
      // const result = true;
      if (result) {
        console.log('Soumission du Formulaire');
        setIsFormValidated(true);
        // permet d'afficher la boite de dialogue à la soumission du formulaire
        setOpen(true);
      }
    }
    else {
      console.log('Un des champs est manquant ou mal saisi');
      setIsFormValidated(false);
    }


    // permet d'afficher la boite de dialogue à la soumission du formulaire
    setOpen(true);

  };


  return (
    <div className='addNewUser'>
      {open && (
        <DialogConfirm
          newUserToAdd={newUserToAdd}
          open={open}
          setOpen={setOpen}
          isFormValidated={isFormValidated}
        />
      )}

      <Paper elevation={5} className='addNewUser-paper' sx={{ height: 600, overflowY: 'auto' }}>
        <form ref={addNewUserFormRef} className='addNewUser-form' onSubmit={handleSubmit}>

          <UserInfoForm
            newUserToAdd={newUserToAdd}
            setNewUserToAdd={setNewUserToAdd}
            prenomError={prenomError}
            setPrenomError={setPrenomError}
            nomError={nomError}
            setNomError={setNomError}
            userNameError={userNameError}
            setUserNameError={setUserNameError}
            passwordError={passwordError}
            setPasswordError={setPasswordError}
            emailError={emailError}
            setEmailError={setEmailError}
            telephoneError={telephoneError}
            setTelephoneError={setTelephoneError}
            passwordStrength={passwordStrength}
            setPasswordStrength={setPasswordStrength}
          />
          <hr />

          <UserProfilesServicesForm
            newUserToAdd={newUserToAdd}
            setNewUserToAdd={setNewUserToAdd}
            isAccordionValidated={isAccordionValidated}
          />
          <hr />

          <div className='footerAddNewUser'>
            <Link to={`/dashboard/utilisateurs`} style={{ textDecoration: "none" }}>
              <Button className='btnSubmit' variant="contained" sx={{ width: '200px', cursor: 'pointer' }}>
                Annuler
              </Button>
            </Link>
            <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
              Enregistrer
            </Button>
          </div>

        </form>
      </Paper>

    </div>
  )
}
