import React, { useRef, useState } from 'react'
import './Card.scss'
import { Button } from '@mui/material';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import styled from '@emotion/styled';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DialogConfirmImages from './DialogConfirmImages';

const EditButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    m: '10px',
    width: '240px',
    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },
    "&:disabled": {
        backgroundColor: 'grey',
        color: 'white'
    }
}))


// export default function Card({ cityName, text, image, setImage, fileName, setFileName, allowedManagement, imageFile, setImageFile }) {
export default function Card({ cityCode, cityName, text, image, setImage, fileName, setFileName, allowedManagement, imageFile, setImageFile }) {

    // console.log({ cityCode, cityName, text, image, setImage, fileName, setFileName, allowedManagement, imageFile, setImageFile })


    const refInput = useRef()

    const [open, setOpen] = useState(false);

    const handleUploadClick = () => {
        const fileInput = refInput;
        fileInput.current.click();
    }

    const handleChangeImage = (e) => {
        if (allowedManagement) {
            const file = e.target.files[0]
            if (file) {
                setImageFile(file)
                setFileName(file.name)
                const objectURL = URL.createObjectURL(file);
                setImage(objectURL)
            }
        }
    }
    const handleUploadedImageSubmit = (e) => {
        setOpen(true)
    }

    return (
        <div>
            <div className="file-card" >
                {open && (
                    <DialogConfirmImages
                        open={open}
                        setOpen={setOpen}
                        cityCode={cityCode}
                        cityName={cityName}
                        text={text}
                        FileName={fileName}
                        imageFile={imageFile}
                    />
                )}

                <div className="titre-image">{text} <em>{cityName}</em></div>

                {allowedManagement ? (
                    <Button className="btn-approbation" type="submit" variant="contained" onClick={handleUploadedImageSubmit}>
                        Demande d'approbation pour le {text}
                    </Button>
                ) : null}

                <section className="file-inputs" onClick={handleUploadClick}>

                    <input
                        type={allowedManagement ? "file" : "text"}
                        ref={refInput}
                        onChange={handleChangeImage}
                    />
                    {image ?
                        <div className='imageToUpload'>
                            <img src={image} alt='Logo ou fond d écran' />
                        </div>
                        :
                        <EditButton
                            id='uploadBtn'
                            className='btn-upload'
                            startIcon={<PublishedWithChangesOutlinedIcon />}
                            variant="contained"
                        >Modifier {text}</EditButton>
                    }
                </section>

                {allowedManagement &&
                    <section className="file-name">
                        <FilePresentRoundedIcon />
                        {image ?
                            <span className='name-and-icon'>
                                {fileName}
                                <DeleteForeverRoundedIcon
                                    className='delete-icon'
                                    onClick={() => {
                                        setFileName('Pas de fichier sélectionné...')
                                        setImage(null)
                                    }} />
                            </span>
                            :
                            <span className='name-and-icon'>
                                {fileName}
                            </span>
                        }
                    </section>
                }
            </div>
        </div>
    )
}
