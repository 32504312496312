import "./sidebar.scss";
import Logo from '../../assets/images/logoAzimut.png'
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import FolderSharedRoundedIcon from '@mui/icons-material/FolderSharedRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
// import PhotoLibraryRoundedIcon from '@mui/icons-material/PhotoLibraryRounded';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import MuseumRoundedIcon from '@mui/icons-material/MuseumRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import { useAuth } from "../../contexts/AuthContext";
// import { DarkModeContext } from "../../context/darkModeContext";

// let muIdValid = false;

const Sidebar = () => {
  const { cityCode } = useParams();
  const localMuni = JSON.parse(localStorage.getItem('currentMunicipality'));
  const { currentUser, logout } = useAuth();
  const [menuIsOpen, setMenuIsOpen] = useState(true);

  const location = useLocation();

  const storedItem = JSON.parse(localStorage.getItem('selectedMenuItem'));

  const [selected, setSelected] = useState(storedItem.idMenu);
  // const [selectedItem, setSelectedItem] = useState(storedItem.menuItemSelected);

  // // Utiliser des expressions régulières pour gérer les parties dynamiques de l'URL
  // const routes = [
  //   { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-statistiques/, id: 11 },
  //   { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-utilisateurs/, id: 12 },
  //   { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-cartographie/, id: 13 },
  //   { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-mise-en-ligne/, id: 14 },
  //   { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-configuration/, id: 15 },
  //   { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-messages/, id: 16 },
  //   { path: "/dashboard", id: 0 },
  //   { path: "/dashboard/municipalites", id: 10 },
  //   { path: "/dashboard/utilisateurs", id: 20 },
  //   { path: "/dashboard/utilisateurs/add-user", id: 21 },
  //   { path: "/dashboard/utilisateurs/edit-user/abusatta", id: 22 },
  //   { path: "/dashboard/messages", id: 30 },
  //   { path: "/dashboard/profile", id: 40 },
  // ];

  // const findMatchingRoute = () => {
  //   const { pathname } = location;
  //   const matchedRoute = routes.find((route) => route.regex ? route.regex.test(pathname) : route.path === pathname);
  //   return matchedRoute ? matchedRoute.id : 0;
  // };

  // const dynamicPart = location.pathname.match(regexMuStatistiques)[1];
  // console.log({dynamicPart});
  // Utiliser la partie dynamique, par exemple :
  // id = parseInt(dynamicPart, 10); // Convertir en nombre entier

  useEffect(() => {
    // Utiliser des expressions régulières pour gérer les parties dynamiques de l'URL
    const routes = [
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-statistiques/, id: 11 },
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-utilisateurs/, id: 12 },
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-cartographie/, id: 13 },
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-mise-en-ligne/, id: 14 },
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-configuration/, id: 15 },
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-configuration\/mu-image/, id: 15 },
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-configuration\/warning-messages/, id: 15 },
      { regex: /^\/dashboard\/municipalites\/(\d+)\/mu-messages/, id: 16 },
      { path: "/dashboard", id: 0 },
      { path: "/dashboard/municipalites", id: 10 },
      { path: "/dashboard/utilisateurs", id: 20 },
      { path: "/dashboard/utilisateurs/users-list", id: 20 },
      { path: "/dashboard/utilisateurs/add-new-user", id: 20 },
      { path: "/dashboard/utilisateurs/edit-user", id: 20 },
      { path: "/dashboard/messages", id: 30 },
      { path: "/dashboard/profile", id: 40 },
    ];

    const { pathname } = location;
    const matchedRoute = routes.find((route) => route.regex ? route.regex.test(pathname) : route.path === pathname);
    let id = matchedRoute ? matchedRoute.id : 0;

    localStorage.setItem('selectedMenuItem', JSON.stringify(
      {
        idMenu: id,
        menuItemSelected: location.pathname
      }
    ));

    // setSelectedItem(location.pathname);
    setSelected(id);

  }, [location]);


  const handleItemClick = (item, id) => {
    // setSelectedItem(item);
    setSelected(id);
    localStorage.setItem('selectedMenuItem', JSON.stringify({
      idMenu: id,
      menuItemSelected: item
    }));
  };

  const muIdValid = (cityCode && localMuni && cityCode === localMuni.cityCode);
  if (typeof muIdValid !== 'undefined' && !muIdValid) {
    window.location.href = "/dashboard/municipalites";
  }

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
    // setSelected(11);
  }


  // const { dispatch } = useContext(DarkModeContext);
  return (
    <div className="sidebar">

      <div className='top' >
        <div className='logo'>
          <img src={Logo} alt='logo Azimut' />
        </div>
      </div>

      <hr />
      <div className="bonjourUser">Bonjour <em><strong>{currentUser?.fullName}</strong></em></div>

      <hr />

      <div className="center">

        <div className="title">GOnet ...</div>

        <Link to="/dashboard" onClick={() => handleItemClick('/dashboard', 0)} style={{ textDecoration: "none" }}>
          <div className="sub-title ">Portail Client</div>
        </Link>

        <div className="menu" >
          <ul>

            {/* Municipalités */}
            <li>
              <div className={selected === 10 ? 'menuLink active' : 'menuLink'} key={10} onClick={() => handleItemClick('/dashboard/municipalites', 10)} >
                <MapsHomeWorkRoundedIcon className="icon" />
                <Link to="/dashboard/municipalites" style={{ textDecoration: "none" }}>
                  <span>MUNICIPALITÉS</span>
                </Link>
              </div>
            </li>

            {muIdValid &&
              <div className="sub-menu">
                <ul>

                  <div className="menuLink parent" onClick={toggleMenu}>
                    <div className="iconAndText">
                      <MuseumRoundedIcon className="icon" />
                      <Link to={`/dashboard/municipalites/${cityCode}`} style={{ textDecoration: "none" }}>
                        <span> {muIdValid && localMuni.cityName}</span>
                      </Link>
                    </div>
                    {menuIsOpen
                      ? <KeyboardArrowUpRoundedIcon className="icon arrowUpIcon" />
                      : <KeyboardArrowDownRoundedIcon className="icon arrow" />
                    }
                  </div>

                  {/* {menuIsOpen && ( */}
                  <div className={menuIsOpen ? "up-down open" : "up-down close"} >
                    <li className="li-sub-menu">
                      <div className={selected === 11 ? 'menuLink active' : 'menuLink'} key={11} onClick={() => handleItemClick(`/dashboard/municipalites/${cityCode}/mu-statistiques`, 11)}>
                        <InsertChartRoundedIcon className="icon" />
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-statistiques`} >
                          <span>STATISTIQUES</span>
                        </Link>
                      </div>
                    </li>

                    <li className="li-sub-menu">
                      <div className={selected === 12 ? 'menuLink active' : 'menuLink'} key={12} onClick={() => handleItemClick(`/dashboard/municipalites/${cityCode}/mu-utilisateurs`, 12)} >
                        <FolderSharedRoundedIcon className="icon" />
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-utilisateurs`}>
                          <span>UTILISATEURS</span>
                        </Link>
                      </div>
                    </li>

                    <li className="li-sub-menu">
                      <div className={selected === 15 ? 'menuLink active' : 'menuLink'} key={15} onClick={() => handleItemClick(`/dashboard/municipalites/${cityCode}/mu-configuration`, 15)}>
                        <SettingsSuggestIcon className="icon" />
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-configuration`}>
                          <span>CONFIGURATION</span>
                        </Link>
                      </div>
                    </li>

                    <li className="li-sub-menu">
                      <div className={selected === 13 ? 'menuLink active' : 'menuLink'} key={13} onClick={() => handleItemClick(`/dashboard/municipalites/${cityCode}/mu-cartographie`, 13)} >
                        <PublicRoundedIcon className="icon" />
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-cartographie`}>
                          <span>CARTOGRAPHIE</span>
                        </Link>
                      </div>
                    </li>

                    <li className="li-sub-menu">
                      <div className={selected === 14 ? 'menuLink active' : 'menuLink'} key={14} onClick={() => handleItemClick(`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne`, 14)} >
                        <CloudUploadRoundedIcon className="icon" />
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne`}>
                          <span>MISES EN LIGNE</span>
                        </Link>
                      </div>
                    </li>

                    <li className="li-sub-menu">
                      <div className={selected === 16 ? 'menuLink active' : 'menuLink'} key={16} onClick={() => handleItemClick(`/dashboard/municipalites/${cityCode}/mu-messages`, 16)}>
                        <MarkEmailUnreadRoundedIcon className="icon" />
                        <Link to={`/dashboard/municipalites/${cityCode}/mu-messages`}>
                          <span>MESSAGES POP-UP</span>
                        </Link>
                      </div>
                    </li>
                  </div>
                  {/* )} */}
                </ul>
              </div>
            }

            {/* Utilisateurs */}
            <li>
              <div className={selected === 20 ? 'menuLink active' : 'menuLink'} key={20} onClick={() => handleItemClick('/dashboard/utilisateurs', 20)}>
                <PeopleAltRoundedIcon className="icon" />
                <Link to="/dashboard/utilisateurs" style={{ textDecoration: "none" }}>
                  <span>UTILISATEURS</span>
                </Link>
              </div>
            </li>

            {/* Messages */}
            <li>
              <div className={selected === 30 ? 'menuLink active' : 'menuLink'} key={30} onClick={() => handleItemClick('/dashboard/messages', 30)}>
                <MailRoundedIcon className="icon" />
                <Link to="/dashboard/messages" style={{ textDecoration: "none" }}>
                  <span>GESTION DES MESSAGES</span>
                </Link>
              </div>
            </li>

          </ul>

        </div>

      </div>

      <hr />

      <div className="bottom">

        {/* <div className="bottom-title">Bonjour {currentUser?.fullName}</div> */}

        <ul>
          <li>
            <div
              key={40}
              className={`PROFIL ${selected === 40 ? 'menuLink active' : 'menuLink'}`}
              onClick={() => handleItemClick('/dashboard/profile', 40)}
            >
              <AccountCircleRoundedIcon className="icon" />
              <Link to="/dashboard/profile" style={{ textDecoration: "none" }}>
                <span>PROFIL</span>
                {/* <span className="bottom-title">{currentUser?.idUser}</span> */}
              </Link>
            </div>
          </li>

          <li>
            <div
              key={50}
              className={selected === 50 ? 'menuLink active' : 'menuLink'}
              onClick={() => { setSelected(50); logout() }}
            >
              <ExitToAppIcon className="icon" />
              <Link to="/" style={{ textDecoration: "none" }}>
                <span>DÉCONNEXION</span>
              </Link>
            </div>
          </li>
        </ul>

        {/* <div className="colorOption">
          <div className="light"
          // onClick={() => dispatch({ type: "LIGHT" })}
          ></div>
          <div className="dark"
          // onClick={() => dispatch({ type: "DARK" })}
          ></div>
        </div> */}
      </div>

    </div>
  );
};

export default Sidebar;

