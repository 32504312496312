import React, { useEffect, useState } from 'react';
import './UserProfilesServicesForm.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import {
  groupMunicipalitiesGetList,
  getProfileList,
  getServicesProfile,
} from '../../httpQuerys'
import {
  // Paper,
  TextField,
  // IconButton,
  Button,
  Grid,
  // Input,
  // InputAdornment,
  // InputLabel,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useQuery } from 'react-query';
import AccordionProfilesAndServices from '../Accordion-Profiles-services/AccordionProfilesAndServices';


export default function UserProfilesServicesForm(props) {

  // console.log(props)

  const {
    // newUserToAdd,
    setNewUserToAdd,
    isAccordionValidated,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { gde } = JSON.parse(localStorage.getItem('portailclient'));
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

  const [, setUserProfile] = useState(
    {
      idUserProfile: null,
      idGroup: '',
      mrcCode: null,
      mrcRealName: '',
      cityCode: null,
      cityName: '',
      nameProfile: null,
      creationUserProfile: toDay,
      lastUpdate: '',
      expiration: toDay,
      contact: false,

      servicesProfile: [
        {
          idUserProfile: null,
          defaultService: null,
          idService: null,
        }
      ]
    }
  )

  const [municipalities, setMunicipalities] = useState([]);
  const [municipality, setMunicipality] = useState(null);
  const [municipalitiesError, setMunicipalitiesError] = useState([]);

  const [profilesList, setProfilesList] = useState([]);
  const [profile, setProfile] = useState(null);
  const [profileError, setProfileError] = useState('');

  const [profileServicesList, setProfileServicesList] = useState([]);
  const [profileServices, setProfileServices] = useState([]);
  const [profileServicesError, setProfileServicesError] = useState('');

  const [defaultServiceList, setDefaultServiceList] = useState([]);
  const [defaultService, setDefaultService] = useState(null);
  const [defaultServiceError, setDefaultServiceError] = useState('');

  const [contact, setContact] = useState(false);
  const [expiration, setExpiration] = useState(dayjs());

  const [allMuSelect, setAllMuSelect] = useState(false);
  const [finalProfilesList, setFinalProfilesList] = useState([]);

  const [openAutoC, setOpenAutoC] = useState(false);
  const [loading, setLoading] = useState(true);

  // ---------------------------------------------------------------
  // Configuration de la locale pour Day.js
  dayjs.locale('fr-ca');
  // ---------------------------------------------------------------

  const { data, isLoading, isError } = useQuery('Municipalities', () => groupMunicipalitiesGetList(gde, currentUserInfo));
  // getUserMunicipalitesData(gde, currentUserInfo).then(data => setMunicipalities(data));

  //  valeurs a récupérer de la BD titres et departments attentions avec le e dans 'depart e ment'
  //  récuperer, une seule fois lors du chargement, toutes les données dont on a besoin de la BD
  useEffect(() => {
    setAllMuSelect(false)
    setLoading(openAutoC && municipalities.length === 0)

    if (!isLoading && !isError) {
      // setMunicipalities(data);
      // setMunicipalities(data)
      setMunicipalities([{ cityCode: ' - ', mrcCode: '', cityName: 'Toutes les municipalités avec service de base' }, ...data])

    }

    getProfileList(currentUserInfo.idGroup).then(data => setProfilesList(data));
    // if (userProfile.cityCode && userProfile.nameProfile) {
    if (municipality && profile) {
      // getServicesProfile(userProfile.cityCode, userProfile.idGroup, userProfile.nameProfile).then(data => setProfileServicesList(data));
      getServicesProfile(municipality.cityCode, municipality.idGroup, profile.nameProfile)
        .then(data => setProfileServicesList(data));
    }
  }, [currentUserInfo.idGroup, data, isError, isLoading, municipalities.length, municipality, openAutoC, profile]);

  useEffect(() => {
    setNewUserToAdd((prevState) => (
      {
        ...prevState,
        userProfiles: finalProfilesList,
      }
    ))
  }, [finalProfilesList, setNewUserToAdd])


  //todo données des Options de la liste déroulante pour les municipalities
  // const municipalitiesDefaultProps = {
  //   options: municipalities,
  //   getOptionLabel: (option) => `${option.cityName} (${option.cityCode})`,
  // };

  const municipalitiesDefaultProps = {
    // Trier les options par mrcCode
    options: municipalities.sort((a, b) => a.mrcCode.localeCompare(b.mrcCode)),

    groupBy: (option) => option.mrcRealName, // Regrouper les options par mrcName
    getOptionLabel: (option) => option.cityCode ? `${option.cityName} (${option.cityCode})` : ''
  };


  // données des Options de la liste déroulante pour les profils
  const profilesDefaultProps = {
    options: profilesList,
    getOptionLabel: (option) => option.nameProfile
  };

  // données des Options de la liste déroulante pour les servicesProfile
  const profileServicesDefaultProps = {
    options: profileServicesList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  // données des Options de la liste déroulante des servicesProfile accordés à l'utilisateur
  const defaultServiceProfileDefaultProps = {
    // options: userProfile.servicesProfile,
    options: defaultServiceList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  const handleDateExpirationChange = (newValue) => {
    const formattedDateTime = newValue.locale('fr').format('YYYY-MM-DD HH:mm:ss');
    setExpiration(newValue);
    setNewUserToAdd(prevUser => ({ ...prevUser, expiration: formattedDateTime }))
  }


  const handleAddNewProfil = () => {
    let validateUserProfile = true;

    if (!municipality) { setMunicipalitiesError('Sélectionner une municipalité.'); validateUserProfile = false; }
    if (!profile) { setProfileError('Un profil est obligatoire.'); validateUserProfile = false; }
    if (profileServices.length === 0) { setProfileServicesError('Au minimum un service est obligatoire.'); validateUserProfile = false; }
    if (!defaultService) { setDefaultServiceError('Le champ service par défaut est obligatoire.'); validateUserProfile = false; }

    if (validateUserProfile) {

      const updatedServicesProfile = profileServices.map(item => ({
        idService: item.idService,
        aliasName: item.aliasName,
        defaultService: item.idService === defaultService.idService ? 1 : 0
      }));

      // Créer le nouvel état à appliquer
      const newProfile = {
        idGroup: municipality.idGroup,
        mrcCode: municipality.mrcCode,
        mrcRealName: municipality.mrcRealName,
        cityCode: municipality.cityCode,
        cityName: municipality.cityName,
        logo: municipality.logo,
        nameProfile: profile.nameProfile,
        servicesProfile: updatedServicesProfile,
        creationUserProfile: toDay,
        expiration: expiration,
        contact: contact,
      };

      // Vérifier si newProfile existe déjà dans finalProfilesList
      const isProfileAlreadyExists = finalProfilesList.some(
        (profile) => profile.cityName === newProfile.cityName
      );

      if (isProfileAlreadyExists) {
        // Afficher un message dans une boîte de dialogue
        alert(`Le profil ${newProfile.cityName} existe déjà parmis les profils de cet utilisateur !`);
        return; // Arrêter la fonction ici pour éviter d'ajouter un profil existant
      }

      // Mettre à jour les deux états en même temps
      setUserProfile((prevState) => ({
        ...prevState,
        ...newProfile
      }));

      // Mise à jour de setFinalProfilesList après que userProfile ait été mis à jour
      setFinalProfilesList((prevState) => [...prevState, newProfile]);

      // Réinitialisation des états
      setMunicipality(null);
      setProfile(null);
      setProfileServices([]);
      setDefaultService(null);
      setContact(false);
      setExpiration(dayjs());
    }
  };


  // const allprofilsAndDefaultServices = () => {
  //   let muDefaultProfileServices = []

  //   municipalities.map((municipality) => {
  //     getServicesProfile(municipality.cityCode, municipality.idGroup, 'BasePrivate')
  //       .then(data => {
  //         muDefaultProfileServices = [...muDefaultProfileServices,
  //         {
  //           cityCode: municipality.cityCode,
  //           cityName: municipality.cityName,
  //           contact: 0,
  //           creationUserProfile: toDay,
  //           expiration: dayjs().add(1, 'year').format('YYYY-MM-DD HH:mm:ss'),
  //           idGroup: municipality.idGroup,
  //           logo: municipality.logo || 'https://www.goazimut.com/GOnet6/images/print/azimut-logo-print.png',
  //           mrcCode: municipality.mrcCode,
  //           mrcRealName: municipality.mrcRealName,
  //           nameProfile: 'BasePrivate',
  //           servicesProfile:
  //             [
  //               {
  //                 aliasName: municipality.cityName,
  //                 defaultService: 1,
  //                 idService: data[0].idService,
  //               }
  //             ]
  //         }
  //         ]

  //         // console.log(muDefaultProfileServices)

  //         // Utilisation de setFinalProfilesList pour ajouter le nouvel élément à la liste
  //         // setFinalProfilesList(prevState => [...prevState, muDefaultProfileServices]);
  //       })
  //   })
  //   setFinalProfilesList(muDefaultProfileServices)
  // }


  // console.log(finalProfilesList)
  const allprofilsAndDefaultServices = async () => {
    let muDefaultProfileServices = [];

    // eliminer la municipalité dont le code est ' - ' pour toutes sélectionnées
    const filtredMunicipalities = municipalities.filter(municipality => municipality.cityCode !== ' - ')

    // Utilisez Promise.all pour attendre que toutes les promesses soient résolues
    await Promise.all(
      filtredMunicipalities.map(async (municipality) => {
        try {
          const data = await getServicesProfile(municipality.cityCode, municipality.idGroup, 'BasePrivate');

          muDefaultProfileServices = [
            ...muDefaultProfileServices,
            {
              cityCode: municipality.cityCode,
              cityName: municipality.cityName,
              contact: 0,
              creationUserProfile: toDay,
              expiration: dayjs().add(1, 'year').format('YYYY-MM-DD HH:mm:ss'),
              idGroup: municipality.idGroup,
              logo: municipality.logo || 'https://www.goazimut.com/GOnet6/images/print/azimut-logo-print.png',
              mrcCode: municipality.mrcCode,
              mrcRealName: municipality.mrcRealName,
              nameProfile: 'BasePrivate',
              servicesProfile: [
                {
                  aliasName: municipality.cityName,
                  defaultService: 1,
                  idService: data[0].idService,
                },
              ],
            },
          ];
        } catch (error) {
          console.error('Erreur lors de la récupération des services de profil :', error);
        }
      })
    );

    // Mettre à jour finalProfilesList après que toutes les promesses sont résolues
    setFinalProfilesList(muDefaultProfileServices);
  };


  return (
    <div className='UserProfilesServicesForm'>

      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem checkboxItem ' xs={12} md={12} lg={12} >
          <FormLabel className='checkboxItem-title'>PROFIL ET SERVICES</FormLabel>
        </Grid>

        {/* <Grid item className='gridItem' xs={12} md={12} lg={12}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
            <FormControlLabel
              className='allMuSelect-formControl'
              control={
                <Checkbox
                  className='allMuSelect-checkbox'
                  id="allMuSelect"
                  name="allMuSelect"
                  disabled={true}
                  checked={allMuSelect}
                  onChange={(e) => {
                    setAllMuSelect(e.target.checked);
                    allprofilsAndDefaultServices()
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'allMuSelect checkbox' }}
                />
              }
              label={
                <div className='allMuSelect-label'>
                  Ajouter toutes les municipalités avec les services de base
                </div>
              }
              labelPlacement="end"
            />
          </FormControl>
        </Grid> */}

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...municipalitiesDefaultProps}
                id="municipalities"
                name="municipalities"
                // disabled={allMuSelect}

                // multiple
                // disableCloseOnSelect

                open={openAutoC}
                onOpen={() => {
                  setOpenAutoC(true);
                }}
                onClose={() => {
                  setOpenAutoC(false);
                }}
                loading={loading}

                isOptionEqualToValue={(option, value) => option.cityName === value.cityName}

                value={allMuSelect ? null : municipality}

                onChange={(e, newValue) => {
                  if (newValue) {
                    // console.log(newValue)
                    if (newValue.cityCode === ' - ') {
                      // console.log('allprofilsAndDefaultServices() : ', newValue.cityCode)
                      allprofilsAndDefaultServices()
                    } else {
                      setMunicipality(newValue)
                      setMunicipalitiesError('');
                    }
                  } else {
                    // L'utilisateur a effacé la valeur sélectionnée
                    setMunicipality(null)
                  }
                  setProfile(null)
                  setProfileServices([])
                  setDefaultService(null)
                }}

                onInputChange={(e, newInputValue) => {
                  if (!newInputValue) {
                    // L'utilisateur a effacé le champ au clavier
                    setMunicipality(null)
                    setProfile(null)
                    setProfileServices([])
                    setDefaultService(null)
                  }
                }}

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Municipalités" variant="standard" name="municipalities" />}
              />
            </Stack>
            {municipalitiesError && <Typography variant="caption" color="error">{municipalitiesError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...profilesDefaultProps}
                id="profilesList"
                name="profilesList"
                // disabled={!userProfile.cityCode}
                disabled={!municipality}

                isOptionEqualToValue={(option, value) => option.nameProfile === value.nameProfile}

                value={profile}

                onChange={(e, newValue) => {
                  setProfileError('');
                  setProfileServicesError('')
                  setDefaultServiceError('')
                  if (newValue) {
                    setProfile(newValue);
                    setProfileServices([])
                    setDefaultService(null)
                  }
                  else {
                    // L'utilisateur a effacé la valeur sélectionnée
                    setProfile(null);
                    setProfileServices([])
                    setDefaultService(null)
                  }
                }}

                onInputChange={(e, newInputValue) => {
                  if (!newInputValue) {
                    // L'utilisateur a effacé le champ au clavier
                    setProfile(null);
                    setProfileServices([])
                    setDefaultService(null)
                  }
                }}

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Profils" variant="standard" name="profilesList" />}
              />
            </Stack>
            {profileError && <Typography variant="caption" color="error">{profileError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...profileServicesDefaultProps}
                multiple
                id="multipleProfileServices"
                name="multipleProfileServices"
                disableCloseOnSelect
                // disabled={!userProfile.nameProfile}
                disabled={!profile}

                isOptionEqualToValue={(option, value) => option.aliasName === value.aliasName}

                // value={userProfile.servicesProfile}
                value={profileServices}

                onChange={(e, newValue) => {
                  if (newValue) {
                    setProfileServices(newValue)
                    setDefaultServiceList(newValue)
                    // newValue.length === 1 ? setDefaultService(newValue[0]) : setDefaultService(null)
                    setDefaultService(newValue?.length === 1 ? newValue[0] : null);
                    setProfileServicesError('');
                    setDefaultServiceError('');
                  }
                }}

                // disableCloseOnSelect

                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.aliasName}
                    </li>
                  )
                }}

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Services du profil" variant="standard" name="multipleProfileServices" />}
              />
            </Stack>
            {profileServicesError && <Typography variant="caption" color="error">{profileServicesError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...defaultServiceProfileDefaultProps}
                id="defaultServiceProfile"
                name="defaultServiceProfile"
                // disabled={userProfile.servicesProfile.length === 0}
                disabled={profileServices.length === 0}

                isOptionEqualToValue={(option, value) => option.defaultServiceProfile === value.defaultServiceProfile}

                // value={userProfile.servicesProfile.find((item) => item.defaultService === 1) || null}
                value={defaultService}

                onChange={(e, newValue) => {
                  // console.log(newValue)
                  setDefaultServiceError('');
                  if (newValue) {
                    setDefaultService(newValue)
                  } else {
                    // L'utilisateur a effacé la valeur sélectionnée
                    setDefaultService(null)
                  }
                }}

                onInputChange={(e, newInputValue) => {
                  if (!newInputValue) {
                    setDefaultService(null)
                    // L'utilisateur a effacé le champ au clavier
                    // setUserProfile((prevState) => ({
                    //   ...prevState,
                    //   servicesProfile: userProfile.servicesProfile.map((item) => ({ ...item, defaultService: 0 })),
                    // }));
                  }
                }}

                clearOnEscape
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Service par defaut du profil"
                      variant="standard"
                      name="defaultServiceProfile"
                    />
                  )
                }}
              />
            </Stack>
            {defaultServiceError && <Typography variant="caption" color="error">{defaultServiceError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
            <FormControlLabel
              className='contact-formControl'
              control={
                <Checkbox
                  className='contact-checkbox'
                  id="contact"
                  name="contact"
                  disabled={!municipality}
                  checked={contact}
                  onChange={(e) => setContact(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'contact checkbox' }}
                />
              }
              label={
                <div className='contact-label'>
                  <PermContactCalendarIcon
                    sx={{
                      color: contact ? 'green' : (municipality ? 'action.active' : 'lightgray'), // Use light gray if disabled

                      // color: contact ? 'green' : 'action.active',
                      marginRight: '0.5rem'
                    }}
                  />
                  Contact
                </div>
              }
              labelPlacement="start"
            />
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            // locale={frFR} // Utilisez la locale française canadienne pour @mui/x-date-pickers
            locale="fr-FR"
          >
            <DatePicker
              label="Date d'expiration"
              format="YYYY/MM/DD"
              openTo="day"
              views={["year", "month", "day"]}
              slotProps={{
                textField: {
                  variant: "filled",
                  size: "small",
                  // helperText: "Saisir une date AAAA/MM/JJ"
                }
              }}
              disabled={!municipality}
              value={expiration}
              onChange={(newValue) => handleDateExpirationChange(newValue)}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item className='gridItem' xs={12} md={12} lg={12} >
          <Button
            className='Btn-AddProfile'
            variant="contained"
            sx={{ width: '300px' }}
            disabled={!municipality}
            onClick={handleAddNewProfil}>
            Ajouter ce profil et ses services
          </Button>
        </Grid>

        {/* ==================================================== */}

        <AccordionProfilesAndServices
          finalProfilesList={finalProfilesList}
          setFinalProfilesList={setFinalProfilesList}
          isAccordionValidated={isAccordionValidated}
        />

      </Grid>

    </div>
  )
}
