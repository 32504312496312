import dataService from "../../httpService";


// Fonction pour obtenir tous les messages avec leurs détails pour un utilisateur donné
async function getAllMessagesData(idUser) {
    try {
        const response = await dataService.get('/messages/get-all-messages', {
            params: {
                idUser
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir les détails d'un messages par son idCityMessage
async function getMessageDetailsById(idCityMessage) {
    try {
        const response = await dataService.get('/messages/get-message-by-id', {
            params: {
                idCityMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des municipalités de l'utilisateur à éditer
async function getUserMunicipalitesList(idUser) {
    try {
        const response = await dataService.get('/user/all-users/user/to-edit/municipalites', {
            params: {
                idUser
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des tyes de messages d'avertissement
async function getMessageTypeList() {
    try {
        const response = await dataService.get('/messages/message-type-list');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des détails des messages d'avertissement selon la municipalité cityCode
async function getMessagesListByType(cityCode, idtypeMessage) {
    try {
        const response = await dataService.get('/messages/messages-list-by-type', {
            params: {
                cityCode,
                idtypeMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour ajouter un nouveau message à la municipalité
async function addMuMessage(messageToSave) {
    try {
        const response = await dataService.post('/messages/add-new-message', messageToSave)
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour ajouter un message à une ou plusieurs municipalités
// async function addMultiMuMessage(messageMultiToSave) {
//     console.log(messageMultiToSave)
//     try {
//         for (const municipality of messageMultiToSave.municipalities) {
//             // console.log(municipality)

//             const messageToSave = { ...messageMultiToSave, cityCode: municipality.cityCode };
//             console.log(messageToSave)
//             const response = await dataService.post('/messages/add-new-message', messageToSave);

//             // messageToSave.cityCode = municipality.cityCode
//             // const response = await dataService.post('/messages/add-multi-message', messageToSave)
//             // return response.data;

//         }
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
//     // return true
// }

// Fonction pour ajouter un message à une ou plusieurs municipalités
async function addMultiMuMessage(messageMultiToSave) {
    try {
        const responses = [];
        for (const municipality of messageMultiToSave.municipalities) {
            const messageToSave = { ...messageMultiToSave, cityCode: municipality.cityCode };
             const response = await dataService.post('/messages/add-new-message', messageToSave);
            responses.push(response.data);
        }
        return responses;
    } catch (error) {
        throw error;
    }
}

// Fonction pour mettre à jour un pop-up message
async function updateMuMessage(messageToUpdate) {
    try {
        const response = await dataService.put('/messages/update-message', messageToUpdate)
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour mettre à jour un message à une municipalité
async function updateMultiMuMessage(messageToSave) {
    try {
        const messageToUpdate = { ...messageToSave, cityCode: messageToSave.municipalities[0].cityCode };
        // console.log(messageToUpdate);
        const response = await dataService.put('/messages/update-message', messageToUpdate)
        return response;
        // return true

    } catch (error) {
        throw error;
    }
}

// Fonction pour supprimer un message (row) parmis la liste de sélections (rows)
async function deleteSelectedMessage(row) {
    try {
        const response = await dataService.delete('/messages/delete-message', {
            params: {
                idCityMessage: row.idCityMessage,
                idMessage: row.idMessage,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste de tous les profils
async function getServices(cityCode) {
    try {
        const response = await dataService.get('/user/municipality/services', {
            params: {
                cityCode
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// liste des message pop-up type 3 avec ses détails pour une municipalité donnée
async function getPopUpMessagesList(cityCode) {
    try {
        const response = await dataService.get('/messages/pop-up-messages-list', {
            params: {
                cityCode: cityCode
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des tyes de messages d'avertissement
async function getTypeMessageList() {
    try {
        const response = await dataService.get('/messages/message-type-list');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des détails des messages d'avertissement selon la municipalité cityCode
async function getMessagesList(cityCode, idtypeMessage) {
    try {
        const response = await dataService.get('/messages/messages-list', {
            params: {
                cityCode,
                idtypeMessage
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir la liste des messages d'avertissement de la municipalité
async function getCityMessagesList(idCityMessage) {
    try {
        const response = await dataService.get('/messages/city-messages-list', {
            params: {
                idCityMessage,
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Exporter les fonctions
export {
    getAllMessagesData,
    getMessageDetailsById,
    getUserMunicipalitesList,
    addMultiMuMessage,
    updateMultiMuMessage,
    getMessageTypeList,
    getMessagesListByType,
    addMuMessage,
    getServices,
    updateMuMessage,
    deleteSelectedMessage,
    getPopUpMessagesList,
    getTypeMessageList,
    getMessagesList,
    getCityMessagesList,
};