import React, { useState } from "react";
import "./Card.scss";
import { motion, LayoutGroup } from "framer-motion";
import ZoomInMapRoundedIcon from "@mui/icons-material/ZoomInMapRounded";
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import Chart from 'react-apexcharts'
import { IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';


const Card = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <LayoutGroup>
      {expanded ? (
        <ExpandedCard key="expanded-card" param={props} setExpanded={() => setExpanded(false)} />
      ) : (
        <CompactCard key="compact-card" param={props} setExpanded={() => setExpanded(true)} />
      )}
    </LayoutGroup>
  );
};

function ExpandedCard({ param, setExpanded }) {
  const data = {
    options: {
      chart: {
        type: "area",
        height: "auto",
      },

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },

      fill: {
        colors: ["#fff"],
        type: "gradient",
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        // colors: ["white"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      grid: {
        show: true,
      },
      xaxis: {
        categories: param.seriesX
      },
    },
  };

  return (
    <motion.div
      className="ExpandedCard-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}

      style={{
        background: param.color.backGround,
        boxShadow: param.color.boxShadow,
      }}
    >
      <motion.div
        className="ExpandedCard"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}

        style={{
          background: param.color.backGround,
          boxShadow: param.color.boxShadow,
        }}
      >
        <div className="expCardTop">

          <div className="expCardTitle">{param.title}</div>

          <IconButton className="zoomOut" onClick={setExpanded}>
            <ZoomInMapRoundedIcon />
          </IconButton>

        </div>

        <div className="chartContainer">
          <Chart className='apexCharts' options={data.options} series={param.seriesData} type='area' />
        </div>
      </motion.div>

      {/* <motion.div
        className="ExpandedCard-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}

        style={{
          // background: param.color.backGround,
          boxShadow: param.color.boxShadow,
        }}
      >
      </motion.div> */}

    </motion.div>
  );
}

function CompactCard({ param, setExpanded }) {
  const Png = param.png;

  return (

    param.title !== 'Économies' ?
      <motion.div
        className="CompactCard"
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        style={{
          background: param.color.backGround,
          boxShadow: param.color.boxShadow,
        }}
        onClick={setExpanded}
      >
        <div className="box-title separator">
          <div className="leftDetail">
            <motion.div
              className="png"
              initial={{ opacity: 0, x: -25 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Png />
            </motion.div>
            <motion.span
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
            >
              {param.title}
            </motion.span>
          </div>
          <div className="rightDetail">
            <QueryStatsRoundedIcon />
          </div>
        </div>

        <div className="box-detail">
          <div className="box-detail-info">
            <span>Total </span>
            <div>{Number(param.total).toLocaleString('fr-FR')}</div>
          </div>
          <div className="box-detail-info">
            <span>Moyenne par mois </span>
            <div>{Number(param.moyenne).toLocaleString('fr-FR')}</div>
          </div>
        </div>

      </motion.div>
      :
      <Tooltip
        title="* Le calcul des économies réalisées se base sur une durée moyenne d'interventions de 15 minutes à un coût évalué à 20 $ l'heure."
        placement="bottom"
      >
        <motion.div
          className="CompactCard last-card"
          style={{
            background: param.color.backGround,
            boxShadow: param.color.boxShadow,
          }}
        >
          <div className="box-title separator">
            <div className="leftDetail">
              <motion.div className="png">
                <Png />
              </motion.div>
              <motion.span>
                {param.title}*
              </motion.span>
            </div>
          </div>

          {param.isCurrentYear ?
            <div className="box-detail">
              <div className="box-detail-info">
                <span>À ce jour </span>
                <div className="box-currency">
                  {param.totalEconomies.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })}
                </div>
              </div>

              <div className="box-detail-info">
                <span>Prévisions pour l’année </span>
                <div className="box-currency">
                  {param.previsions.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })}
                </div>
              </div>
            </div>
            :
            <div className="box-detail">
              <div className="box-detail-info">

                <span>Total des Économies </span>
                <div className="box-currency">

                  {param.totalEconomies.toLocaleString('fr-CA', { style: 'currency', currency: 'CAD' })}

                </div>

              </div>
            </div>
          }

        </motion.div>
      </Tooltip>
  );
}

export default Card;
