import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


// export default function ProfileDialogConfirm ({ onConfirm, onCancel }) {
export default function ProfileDialogConfirm(props) {

    const {
        userToEdit,
        // setUserToEdit,
        // updatedUserToEdit,
        onConfirm,
        onCancel,
    } = props

    const [isOpen, setIsOpen] = useState(true);

    const handleConfirm = () => {
        // setUserToEdit(updatedUserToEdit);
        setIsOpen(false);
        onConfirm();
    };

    const handleCancel = () => {
        setIsOpen(false);
        onCancel();
    };

    return (
        // Implémentez votre boîte de dialogue personnalisée ici
        <div>
            {/* <Dialog open={isOpen} onConfirm={handleConfirm} onCancel={handleCancel}> */}
            <Dialog open={isOpen}>
                <DialogTitle>Vérification du profil </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ce profil est déjà attribué à l'utilisateur "<strong><em>{userToEdit.idUser}</em></strong>", êtes-vous sûr de vouloir le modifier ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Annuler</Button>
                    <Button onClick={handleConfirm}>Modifier</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

