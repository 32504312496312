import React, { useRef, useState } from 'react';
import './ResetPassword.scss';
import Logo from '../../assets/images/logo512.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    validatePassword,
} from './FunctionsUtils';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';

export default function ResetPassword() {

    const { validateToken } = useAuth();
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // const [loading, setLoading] = useState(false);

    const [passwordError, setPasswordError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');

    const navigate = useNavigate();

    const submitBtnRef = useRef();

    // -------------------pour rendre le password / confirmation visible(s) ou non ---------------------------
    const [showPassword, setShowPassword] = useState(false);
    const handleClickPassword = (e) => {
        setShowPassword(!showPassword)
    }
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickConfirmPassword = (e) => {
        setShowConfirmPassword(!showConfirmPassword)
    }
    // -------------------------------------------------------------------------------------

    const handleChange = async (e) => {

        setErrorMessage("");
        submitBtnRef.current.classList.remove('nope');

        if (e.target.name === 'newPassword') {
            validatePassword(e.target.value, setPasswordError, setPasswordStrength)
            setNewPassword(e.target.value)
        }

        if (e.target.name === 'confirmPassword') {
            setConfirmPassword(e.target.value)
        }
        // setPasswordError('false')
        // console.log({ passwordStrength })
    }


    const handleSubmit = async (e) => {

        e.preventDefault();
        // setLoading(true);
        setErrorMessage("");

        const submitButton = submitBtnRef.current;
        if (submitButton) {
            submitButton.classList.remove('nope');
        }

        if (newPassword === "" || confirmPassword === "") {
            if (submitButton) {
                submitButton.classList.add('nope');
            }
            setErrorMessage("Veuillez remplir tous les champs.");
            return;
        }

        if (newPassword !== confirmPassword) {
            if (submitButton) {
                submitButton.classList.add('nope');
            }
            setErrorMessage("Les mots de passe ne correspondent pas.");
            return;
        }



        try {
            const response = await validateToken(token, newPassword);
            // console.log({ response })

            if (response.status === 200) {
                setPasswordChanged(true);
                navigate('/')
            } else {
                if (submitButton) {
                    submitButton.classList.add('nope');
                }
                setErrorMessage("Erreur lors de la réinitialisation du mot de passe !");
            }


        } catch (error) {
            if (submitButton) {
                submitButton.classList.add('nope');
            }
            const errorMessageWithLink = (
                <span>
                    Erreur lors de la réinitialisation du mot de passe !{' '}
                    <a href="/">Revenir à la page d'accueil</a>
                </span>
            );

            setErrorMessage(errorMessageWithLink);
        }
        // setLoading(false);
    };


    return (
        <div>
            {passwordChanged ? (
                <div>
                    <p>Mot de passe réinitialisé avec succès.</p>
                </div>
            ) : (
                <div className='ResetPassWord'>
                    <form onSubmit={handleSubmit}>
                        <div className="ResetPassWordHeader">
                            <div className="ResetPassWordHeader__logo">
                                <img src={Logo} alt='logo Azimut' />
                            </div>
                            <div className='titles'>
                                <h1 className="ResetPassWordHeader__title">PORTAIL CLIENT</h1>
                                <h4 className="ResetPassWordHeader__subtitle">Choisir un nouveau mot de passe</h4>
                            </div>
                        </div>

                        <FormControl className="inputField">

                            <div className='inputField-label'>
                                Nouveau mot de passe
                            </div>

                            <TextField
                                className="inputField-NewPW"
                                id="newPassword"
                                name="newPassword"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Saisir un Nouveau mot de passe"
                                size="small"
                                value={newPassword}
                                // handleChange={(e) => setNewPassword(e.target.value)}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton className='visibilityIcon' onClick={handleClickPassword}>
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {passwordError && (
                                <div className={`passwordMessage ${passwordStrength}`}>
                                    {passwordError}
                                </div>
                            )}
                        </FormControl>



                        <FormControl className="inputField">

                            <div className='inputField-label'>
                                Validation du nouveau mot de passe
                            </div>

                            <TextField
                                className="inputField-NewPW confirmationField"
                                id="confirmPassword"
                                name="confirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                placeholder="Re-saisir le nouveau mot de passe"
                                size="small"
                                value={confirmPassword}
                                onChange={handleChange}

                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton className='visibilityIcon' onClick={handleClickConfirmPassword}>
                                                {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>

                        {/* <FormInput
                            className="inputPW last"
                            type="password"
                            label="Validation du Nouveau mot de passe"
                            placeholder="Resaisir votre Nouveau mot de passe"
                            value={confirmPassword}
                            handleChange={(e) => setConfirmPassword(e.target.value)}
                        /> */}

                        <button
                            className='submitButton'
                            type="submit"
                            ref={submitBtnRef}
                        // disabled={loading}
                        >
                            {!errorMessage ? 'Valider' : 'Erreur de Réinitialisation'}
                        </button>

                        <div className='errorMessage'>
                            {errorMessage && <p>{errorMessage}</p>}
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}
