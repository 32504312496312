import React from 'react'
import './Muheader.scss'
import moment from 'moment'
import "moment/locale/fr"
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
// import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import Logo from '../../assets/images/logo512.png'


export default function MuHeader({ currentCity }) {

  let version = '';
  if (currentCity.GOnet6public)
    version = 'publique';

  if (currentCity.GOnet6private)
    version += version ? ' et réservée' : 'réservée';

  const date = moment(currentCity.maxDateMaj);
  const maxDateMaj = date.isValid() ? date.locale("fr").format("ddd DD MMM YYYY") : null;
  // maxDateMaj && console.log({maxDateMaj})

  function isMaxDateMajExpired(maxDateMaj, months) {
    const currentDate = moment();
    const expirationDate = moment(maxDateMaj, "ddd DD MMM YYYY").add(months, 'months');
    return currentDate.isAfter(expirationDate);
  }

  return (
    currentCity &&
    <div className='header-mainContent'>

      <div className='city-logo'>
        <img
          src={currentCity.logo}
          onError={(e) => {
            e.target.src = Logo;
          }}

          alt='logo de la municipalité' />
      </div>

      <div className='header-middle'>
        <div className='cityName'>{currentCity.cityName} ( {currentCity.cityCode} )</div>
        <div className='ville'>{currentCity.description}</div>
        <div className='mrc'>MRC {currentCity.mrcRealName} ( {currentCity.mrcCode} )</div>
        <div className='fuseau'>Projection : MTM Fuseau {currentCity.fuseau}</div>
      </div>

      <div className='header-right'>

        <div className='version'>Version {version}</div>

        <div className='wrapTextAlert'>
          <div className='text'>{`Population : ${currentCity.population?.toLocaleString('fr-FR')}`}</div>
        </div>

        <div className='wrapTextAlert'>
          <div className='text'>
            {maxDateMaj ? `Dernière mise en ligne ${maxDateMaj}` : 'Pas de mise en ligne'}
          </div>
          {maxDateMaj && isMaxDateMajExpired(maxDateMaj, 6) ? (
            <div className='icon'>
              <WarningAmberRoundedIcon fontSize='small' style={{ color: 'red' }}
              />
            </div>
          ) :
            maxDateMaj && isMaxDateMajExpired(maxDateMaj, 3) && (
              <div className='icon'>
                <WarningAmberRoundedIcon fontSize='small' style={{ color: 'orange' }}
                />
              </div>
            )}
        </div>


        {/* <div className='wrapTextAlert'>
          <div className='text'>Mise en ligne en attente : oui</div>
          <div className='icon'><ErrorOutlineRoundedIcon fontSize='small' style={{ color: 'rgb(233, 85, 85)' }} /></div>
        </div> */}

      </div>

    </div>
  )
}
