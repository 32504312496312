import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Profile.scss";
import {
  getUserDetail,
  getTitles,
  getDepratements
} from './httpQuerys';
import {
  // validatePassword,
  validateField,
  validateEmail,
  formatPhoneNumber,
  // generateRandomPassword
} from './FunctionsUtils';
import DialogConfirm from "./DialogConfirm";

import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button, Grid, Input, InputAdornment, InputLabel, Typography, Paper } from "@mui/material";
import TextField from '@mui/material/TextField';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import PhoneForwardedRoundedIcon from '@mui/icons-material/PhoneForwardedRounded';
import WcRoundedIcon from '@mui/icons-material/WcRounded';
// import LockRoundedIcon from '@mui/icons-material/LockRounded';
import DialogConfirmPasswordChange from './DialogChangePassword'
import DialogChangePassword from "./DialogChangePassword";

export default function Profile() {

  const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
  const { idUser } = currentUser;

  const [userToEdit, setUserToEdit] = useState();

  const [fullNameError, setFullNameError] = useState('');
  // const [passwordError, setPasswordError] = useState('');
  // const [passwordStrength, setPasswordStrength] = useState('');
  const [emailError, setEmailError] = useState('');
  const [telephoneError, setTelephoneError] = useState('');

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDCP, setOpenDCP] = useState(false);

  const [titles, setTitles] = useState([]);
  const [departments, setDepartments] = useState([]);

  const genres = [
    { id: 'M', label: "Masculin", value: "M" },
    { id: 'F', label: "Féminin", value: "F" },
    { id: 'N', label: "Non binaire", value: "N" },
    { id: 'A', label: "Ne pas répondre", value: "A" },
  ];

  //  valeurs a récupérer de la BD titres et departments attentions avec le e dans 'depart e ment'
  //  récuperer, une seule fois lors du chargement, toutes les données dont on a besoin de la BD
  useEffect(() => {
    // getUserDetail(idUser).then(data => { setUserToEdit({ ...data[0] }); setlastUserValues({ ...data[0] }); });
    getUserDetail(idUser).then(data => { setUserToEdit({ ...data[0] }); });
    getTitles().then(data => setTitles(data));
    getDepratements().then(data => setDepartments(data));
  }, [idUser]);


  // -------------------pour rendre le password visible ou non ---------------------------
  // const [showPassword, setShowPassword] = useState(false);
  // const handleClickPassword = () => {
  //   setShowPassword(!showPassword)
  // }
  // const handleMouseDownPassword = (e) => {
  //   e.preventDefault();
  //   setShowPassword(!showPassword)
  // }
  // -------------------------------------------------------------------------------------

  // données des Options de la liste déroulante pour les titres
  const titlesDefaultProps = {
    // options: [...titles, { title: null, idtitle: null }], //tableau des titres récupérés de la BD
    // getOptionLabel: (option) => option.title ? option.title : ''
    options: titles,
    getOptionLabel: (option) => option.title
  };

  // données des Options de la liste déroulante pour les départements
  const departementsDefaultProps = {
    // options: [...departments, { department: null, iddepartment: null }],
    // getOptionLabel: (option) => option.department ? option.department : ''
    options: departments,
    getOptionLabel: (option) => option.department
  };

  //!----------------------------------------------------------------

  const capitalizeFirstLetterOfWords = (str) => {
    //seulement la premiere lettre en majuscule
    return str.charAt(0).toUpperCase() + str.slice(1);
    //premiere lettre de chaque mot en majuscule
    // return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleChange = async (e) => {
    // const name = e.target.id.split('-')[0];
    // console.log(e.target.name)
    // console.log(e.target.value)

    if (e.target.name === 'fullName') {
      const formattedFullName = capitalizeFirstLetterOfWords(e.target.value);
      validateField(formattedFullName, 'Prénom et Nom', setFullNameError);
      setUserToEdit({ ...userToEdit, [e.target.name]: formattedFullName })
    }

    if (e.target.name === 'email') {
      validateEmail(e.target.value, setEmailError)
      setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value })
    }

    if (e.target.name === 'telephone') {
      validateField(e.target.value, 'Téléphone', setTelephoneError);
      const phoneNumber = e.target.value.replace(/[^\d]/g, ''); // Supprime les caractères non numérique
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      setUserToEdit({ ...userToEdit, [e.target.name]: formattedPhoneNumber })
      if (formattedPhoneNumber.length === 14) {
        setTelephoneError('');
      }
      return;
    }

    if (e.target.name === 'ext') {
      const extPoste = e.target.value.replace(/[^\d]/g, ''); // Supprime les caractères non numérique

      // Limiter la longueur à 5 chiffres
      const maxLength = 5;
      const truncatedValue = extPoste.slice(0, maxLength);

      if (!isNaN(truncatedValue) && truncatedValue.length > 0 && truncatedValue.length <= 5)
        setUserToEdit({ ...userToEdit, [e.target.name]: truncatedValue })
      return;
    }

    if (e.target.name === 'Description') {
      setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value });
      return;
    }
    // if (e.target.name === 'password') {
    //   validatePassword(e.target.value, setPasswordError, setPasswordStrength)
    // }

    // setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value })
  }

  // const handleGenerateRandomPassword = async (e) => {
  //   e.preventDefault();
  //   console.log('Generating random password')
  //   const newPW = generateRandomPassword();
  //   setUserToEdit({ ...userToEdit, password: newPW })
  //   setPasswordError('')
  // }

  // Effacer le contenu d'champ en cliquant sur le x
  const handleClear = (field) => {

    switch (field) {
      case 'fullName':
        setUserToEdit({ ...userToEdit, fullName: '' })
        setFullNameError('Le champ Prénom et Nom est obligatoire.')
        break;

      case 'email':
        setUserToEdit({ ...userToEdit, email: '' })
        setEmailError("L'adresse e-mail est obligatoire.")
        break;

      case 'telephone':
        setUserToEdit({ ...userToEdit, telephone: '', ext: '' })
        setTelephoneError('Veuillez saisir un numéro de téléphone avec 10 chiffres.');
        break;

      case 'poste':
        setUserToEdit({ ...userToEdit, ext: '' })
        break;

      case 'description':
        setUserToEdit({ ...userToEdit, Description: '' })
        break;

      default:
        break;
    }
  };

  //!----------------------------------------------------------------

  const handleChangePassword = () => {
    setOpenDCP(true);
    <DialogConfirmPasswordChange />
  }

  //!----------------------------------------------------------------

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log(passwordStrength)

    if (
      (userToEdit.fullName !== '' && !fullNameError) &&
      // (userToEdit.password !== '' && passwordStrength !== 'Faible') &&
      (userToEdit.email !== '' && !emailError) &&
      ((userToEdit.telephone !== '' && !telephoneError) || userToEdit.telephone === '')
    ) {
      console.log('le formulaire peut être soumis ');
      setIsFormValidated(true);
    } else {
      console.log('un des champs est manquant ou mal saisi')
      setIsFormValidated(false);
    }
    // permet d'afficher la boite de dialogue à la soumission du formulaire
    setOpen(true);
  }

  return (
    userToEdit &&
    <div className="profile">

      {open && (
        <DialogConfirm
          userToEdit={userToEdit}
          // lastUserValues={lastUserValues}
          open={open}
          setOpen={setOpen}
          isFormValidated={isFormValidated}
        />
      )}

      {openDCP && (
        <DialogChangePassword
          userToEdit={userToEdit}
          setUserToEdit={setUserToEdit}
          open={openDCP}
          setOpen={setOpenDCP}
          isFormValidated={isFormValidated}
        />
      )}

      <div className="topPage">
        <div className="topTitle">
          <MapsHomeWorkRoundedIcon />
          <div>Profil de <b><em>{currentUser.fullName}</em></b></div>
        </div>
        <div className="topNav">
          <Link
            className="topNav-close"
            to={`/dashboard`}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <CloseRoundedIcon />
          </Link>
          <Button className='btnSubmit' type="submit" variant="contained" sx={{ width: '200px' }} onClick={handleSubmit}>
            Enregistrer
          </Button>
        </div>
      </div>

      <Paper elevation={5} className='profile-paper' sx={{ height: 600, overflowY: 'auto' }}>

        <form className='profile-form' onSubmit={handleSubmit}>

          <Grid container className='gridContainer' spacing={2} m='0' >

            <Grid item className='gridItem' xs={12} md={6} lg={6}  >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
                <InputLabel htmlFor="fullName">Prénom et Nom</InputLabel>
                <Input
                  id="fullName"
                  name="fullName"
                  autoComplete="fullName"
                  required
                  value={userToEdit.fullName}
                  onChange={handleChange}

                  startAdornment={
                    <InputAdornment position="start">
                      <PersonAddRoundedIcon
                        sx={{
                          color:
                            (!userToEdit.fullName || fullNameError !== '') ? 'red' : 'green',
                          mr: 1,
                          my: 0.5
                        }}
                      />
                    </InputAdornment>
                  }

                  endAdornment={
                    userToEdit.fullName && (
                      <InputAdornment position="end">
                        <ClearIcon onClick={() => handleClear('fullName')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                      </InputAdornment>
                    )
                  }
                />
                {fullNameError && <Typography variant="caption" color="error">{fullNameError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6}>
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <InputLabel htmlFor="email">Courriel</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={userToEdit.email}
                  onChange={handleChange}
                  placeholder="email@exp-azimut.com"
                  type="email"
                  required
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailRoundedIcon
                        sx={{
                          color:
                            userToEdit.email && emailError === ''
                              ? 'green'
                              : emailError !== ''
                                ? 'red'
                                : 'action.active',
                          mr: 1,
                          my: 0.5
                        }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    userToEdit.email && (
                      <InputAdornment position="end">
                        <ClearIcon onClick={() => handleClear('email')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                      </InputAdornment>
                    )
                  }
                />
                {emailError && <Typography variant="caption" color="error">{emailError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl ' variant="standard" sx={{ mb: 2 }}>
                <Grid container >

                  <Grid item xs={8}> {/* 70% */}
                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                      <InputLabel htmlFor="telephone"  >Téléphone</InputLabel>
                      <Input

                        id="telephone"
                        name="telephone"
                        autoComplete="telephone"
                        label="TÉLÉPHONE"
                        value={userToEdit.telephone}
                        onChange={handleChange}
                        required

                        startAdornment={
                          <InputAdornment position="start">
                            <PhoneInTalkRoundedIcon
                              sx={{
                                color:
                                  userToEdit.telephone && telephoneError === ''
                                    ? 'green'
                                    : telephoneError !== ''
                                      ? 'red'
                                      : 'action.active',
                                mr: 1,
                                my: 0.5
                              }}
                            />
                          </InputAdornment>
                        }

                        endAdornment={
                          userToEdit.telephone && (
                            <InputAdornment position="end">
                              <ClearIcon onClick={() => handleClear('telephone')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                            </InputAdornment>
                          )
                        }
                      />
                      {telephoneError && <Typography variant="caption" color="error">{telephoneError}</Typography>}

                    </FormControl>
                  </Grid>

                  <Grid item xs={4}> {/* 30% */}
                    <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                      <InputLabel htmlFor="ext">Poste</InputLabel>
                      <Input
                        id="ext"
                        name='ext'
                        disabled={!userToEdit.telephone || telephoneError !== ''}
                        value={userToEdit.ext || ''}
                        onChange={handleChange}

                        startAdornment={
                          <InputAdornment position="start">
                            <PhoneForwardedRoundedIcon
                              sx={{
                                color:
                                  (userToEdit.ext) ? 'green' : 'action.active',
                                mr: 1,
                                my: 0.5
                              }}
                            />
                          </InputAdornment>
                        }

                        endAdornment={
                          // (userToEdit.ext || userToEdit.phone) && (
                          userToEdit.ext && (
                            <InputAdornment position="end">
                              <ClearIcon onClick={() => handleClear('poste')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                            </InputAdornment>
                          )
                        }
                      />
                    </FormControl>
                  </Grid>

                </Grid>
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Button className='btnSubmit' variant="contained" onClick={handleChangePassword}>
                  Changer votre mot de passe
                </Button>
              </FormControl>
            </Grid>

            <hr />

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    options={genres}
                    getOptionLabel={(option) => option.label}

                    id="genre"
                    name="genre"
                    clearOnEscape

                    isOptionEqualToValue={(option, value) => (option.label === value.label || option.label === '')}

                    value={genres.find((option) => option.value === userToEdit.genre) || null}

                    onChange={(e, newValue) => {
                      setUserToEdit((prevState) => ({
                        ...prevState,
                        genre: newValue ? newValue.value : '',
                      }));
                    }}

                    renderInput={
                      (params) => <TextField {...params} label="Genre" variant="standard" name="genre"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <WcRoundedIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    }

                  />
                </Stack>
              </FormControl>
            </Grid>
            <Grid item className='gridItem' xs={12} md={6} lg={6} >
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    {...titlesDefaultProps}
                    id="title"
                    name="title"

                    isOptionEqualToValue={(option, value) => option.title === value.title || value.title === ''}

                    value={{ title: userToEdit.title || '', idtitle: userToEdit.idtitle }}

                    onChange={(e, newValue) =>
                      setUserToEdit((prevState) => ({
                        ...prevState,
                        title: newValue ? newValue.title : '',
                        idtitle: newValue ? newValue.idtitle : null,
                      }))
                    }

                    clearOnEscape
                    renderInput={(params) => <TextField {...params} label="Titre" variant="standard" name="title" />}
                  />
                </Stack>
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    {...departementsDefaultProps}
                    id="department"
                    name="department"

                    isOptionEqualToValue={(option, value) => option.department === value.department || value.department === ''}

                    value={{ department: userToEdit.department || '', iddepartment: userToEdit.iddepartment }}

                    onChange={(e, newValue) =>
                      setUserToEdit((prevState) => ({
                        ...prevState,
                        department: newValue ? newValue.department : '',
                        iddepartment: newValue ? newValue.iddepartment : null,
                      }))
                    }

                    clearOnEscape
                    renderInput={(params) => <TextField {...params} label="Département" variant="standard" name="department" />}
                  />
                </Stack>
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={12} lg={12} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <TextField
                  id="Description"
                  label="Description"
                  name='Description'
                  autoComplete='Description'
                  helperText={userToEdit.Description ? "" : "Saisir une description"}
                  variant="standard"
                  type="search"
                  multiline
                  InputProps={{
                    endAdornment: userToEdit.Description && (
                      <ClearIcon onClick={() => handleClear('description')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                    ),
                  }}

                  value={userToEdit.Description}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>



          </Grid>

        </form>

      </Paper>


    </div>
  );
}
