import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import {
  sendEmailToNewUser,
} from '../httpQuerys';
import { useNavigate } from 'react-router';

export default function DialogConfirm({ newUserToAdd, open, setOpen, isFormValidated }) {

  // console.log({ newUserToAdd })

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)

    navigate(`/dashboard/utilisateurs`);
  };

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  // Vérifie si la touche pressée est la touche "Échap" (code 27) pour fermer la fenêtre
  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      handleClose(); // Appelle la fonction handleClose
    }
  };

  const handleConfirm = async () => {
    // si checkbox coché envoyer l'email
    if (sendEmail) {
      try {
        await sendEmailToNewUser(newUserToAdd);
        // const emailResponse = await sendEmailToNewUser(newUserToAdd);
        // console.log(emailResponse)
        // confirmationMessage = 'Le courriel a été envoyé.';
        setOpenSecondDialog(true);

      } catch (error) {
        console.error("Erreur lors de l'envoi :", error);
      }
    } else {
      // Rediriger vers la page utilisateurs
      navigate(`/dashboard/utilisateurs`);
    }
  }

  return (
    openSecondDialog ? (

      <Dialog open={openSecondDialog} onClose={handleClose} onKeyDown={handleEscape}>
        <DialogTitle>Confirmation d'envoi de courriel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Un courriel a été envoyé à l'adresse {newUserToAdd.email}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>

    ) : (
      isFormValidated ? (
        <div>
          <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
            <DialogTitle>Confirmation d'ajout d'un nouvel utilisateur</DialogTitle>
            <DialogContent>
              <DialogContentText>
                L'utilisateur <strong><em>{newUserToAdd.fullName}</em></strong> a été ajouté avec succés.
              </DialogContentText>

              <DialogContentText>
                Envoyer un courriel avec l'identifiant et le mot de passe à l'utilisateur en question.
                <Checkbox checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
              </DialogContentText>

            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleCancel}>ANNULER</Button> */}
              <Button onClick={handleConfirm}>{sendEmail ? 'Envoyer' : 'Fermer'}</Button>
            </DialogActions>
          </Dialog>

        </div>

      ) : (

        <div>
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Erreur dans le formulaire</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Un ou plusieurs champs du formulaire sont manquants ou érronés.
                Veuillez vérifier vos données !
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Retour au Formulaire</Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    )
  )
}
