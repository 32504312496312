import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import "./Utilisateurs.scss";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddNewUser from "./Add-NewUser/AddNewUser";
import UsersList from "./UsersList/UsersList";

export default function Utilisateurs() {
  const navigate = useNavigate();

  // Tableau des rôles autorisés pour la gestion des utilisateurs
  // ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_GESTION_UTILISATEURS', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const { roleName } = JSON.parse(localStorage.getItem('currentUserInfo'));
  const allowedManagement = allowedRolesForUserManagement.includes(roleName)

  const { page } = useParams();
  const [value, setValue] = useState(allowedManagement ? page : 'users-list');

  useEffect(() => {
    if (page === 'add-new-user' && !allowedManagement) {
      navigate('/dashboard/utilisateurs/users-list');
      setValue('users-list')
    } else {
      setValue(page)
    }
  }, [allowedManagement, navigate, page])

  const handleChange = (event, newValue) => {
    navigate(`/dashboard/utilisateurs/${newValue}`)
    setValue(newValue);
  };

  return (
    <div className="Utilisateurs ">
      <div className="topPage">
        <div className="topTitle">
          <PeopleAltRoundedIcon />
          <div>Gestion des utilisateurs du groupe </div>
        </div>
        <div className="topNav">
          <Link
            className="topNav-close"
            to={`/dashboard`}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <CloseRoundedIcon />
          </Link>
        </div>
      </div>

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} >
              <Tab label="Liste des utilisateurs" value='users-list' />
              {allowedManagement ? (
                <Tab label="Nouvel utilisateur" value='add-new-user' />
              ) : null}
            </TabList>
          </Box>

          <TabPanel value="users-list">
            <UsersList allowedManagement={allowedManagement} />
          </TabPanel>

          <TabPanel value="add-new-user" >
            <AddNewUser />
          </TabPanel>

        </TabContext>
      </Box>
    </div>
  );
}