import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#07659a',
            light: '#3687b5',
        },
        secondary: {
            main: '#92bc27',
            light: '#afdaf3',
        },
        orange: {
            main: '#ff9900',
            light: '#ffc266',
        }
    }
})
