
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Link } from "react-router-dom";
import MuHeader from '../../../components/mu-header/MuHeader';
import './MuMessages.scss'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import {
  Box,
  Tab
} from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import MuListeMessages from "./Mu-Messages-List/MuListeMessages";
import AddNewPopUpMessage from "./Mu-AddNew-Message/MuAddNewMessage";
;


export default function MuMessages() {

  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const navigate = useNavigate();

  // Tableau des rôles autorisés pour la gestion des utilisateurs
  // ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_GESTION_UTILISATEURS', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const { roleName } = JSON.parse(localStorage.getItem('currentUserInfo'));
  const allowedManagement = allowedRolesForUserManagement.includes(roleName)


  const { page } = useParams();
  const [value, setValue] = useState(allowedManagement ? page : 'mu-messages-list');

  // console.log(allowedManagement)
  // console.log(page)

  useEffect(() => {
    if (!allowedManagement) {
      navigate(`/dashboard/municipalites/${cityCode}/mu-messages/mu-messages-list`);
      setValue('mu-messages-list')
    } else {
      setValue(page)
    }
  }, [allowedManagement, cityCode, navigate, page])

  const handleChange = (event, newValue) => {
    navigate(`/dashboard/municipalites/${cityCode}/mu-messages/${newValue}`)
    setValue(newValue);
  };

  return (
    currentCity &&
    <div className='MuMessages'>

      <MuHeader currentCity={currentCity} />

      <div className="topPage">
        <div className="topTitle">
          <MarkUnreadChatAltIcon />
          <div>Messages contextuels {currentCity.description}  </div>
          <span><em>{currentCity.cityName}</em></span>
        </div>
        <div className="topNav">
          <Link className='topNav-close' to={`/dashboard/municipalites/${cityCode}/mu-statistiques`} style={{ textDecoration: "none", cursor: 'pointer' }}>
            <CloseRoundedIcon />
          </Link>
        </div>
      </div>

      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} >
              <Tab label="Liste des messages" value='mu-messages-list' />

              {allowedManagement ? (
                <Tab label="Nouveau message" value='mu-add-new-message' />
              ) : null}


            </TabList>
          </Box>

          <TabPanel value="mu-messages-list">
            <MuListeMessages allowedManagement={allowedManagement} />
          </TabPanel>

          <TabPanel value="mu-add-new-message">
            <AddNewPopUpMessage />
          </TabPanel>



        </TabContext>
      </Box>

    </div>
  )
}
