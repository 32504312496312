import dataService from "../../../httpService";

// Fonction pour obtenir les mises en ligne d'une municipalité
async function getMiseEnLigneData() {
  
  const { cityCode, mrcCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  try {
    const response = await dataService.get('/user/municipalitie/mu-mise-en-ligne', {
      params: {
        cityCode: cityCode,
        mrcCode: mrcCode
      }
    });
    // console.log(response.data)
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Exporter les fonctions
export { getMiseEnLigneData };