import React, { useEffect, useState } from 'react';
import './UserInfoForm.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import {
  getRoles,
  getTitles,
  getDepratements,
} from '../../httpQuerys';
import {
  validatePassword,
  validateField,
  validateEmail,
  formatPhoneNumber,
  generateRandomPassword,
} from '../../FunctionsUtils';
import {
  TextField,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneInTalkRoundedIcon from '@mui/icons-material/PhoneInTalkRounded';
import PhoneForwardedRoundedIcon from '@mui/icons-material/PhoneForwardedRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import WcRoundedIcon from '@mui/icons-material/WcRounded';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function UserInfoForm(props) {

  const currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
  const currentUserRole = currentUser.roleName;

  const {
    userToEdit,
    setUserToEdit,
    fullNameError,
    setFullNameError,
    emailError,
    setEmailError,
    telephoneError,
    setTelephoneError,
    passwordError,
    setPasswordError,
    passwordStrength,
    setPasswordStrength,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const genres = [
    { id: 'M', label: "Masculin", value: "M" },
    { id: 'F', label: "Féminin", value: "F" },
    { id: 'N', label: "Non binaire", value: "N" },
    { id: 'A', label: "Ne pas répondre", value: "A" },
  ];
  const [roles, setRoles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [departments, setDepartments] = useState([]);

  // ---------------------------------------------------------------
  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");
  // ---------------------------------------------------------------

  //  récuperer, une seule fois lors du chargement, toutes les données dont on a besoin de la BD
  useEffect(() => {
    getRoles().then(data => setRoles(data));
    getTitles().then(data => setTitles(data));
    getDepratements().then(data => setDepartments(data));
  }, []);

  // Selon le rôle de l'utilisateur actuel (currentUserRole), on filte la liste des droits qu'il peut attribuer.
  const filteredRoles =
    currentUserRole === 'ROLE_ADMIN'
      ? roles
      : roles.filter(role => [
        'ROLE_CONSULT',
        'ROLE_GESTION_UTILISATEURS',
        'ROLE_MISE_EN_LIGNE',
        'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE',
        'ROLE_BANNI'
      ].includes(role.roleName));

  // données des Options de la liste déroulante pour les rôles
  const rolesDefaultProps = {
    options: filteredRoles,
    getOptionLabel: (option) => option.roleLabel
  };

  // données des Options de la liste déroulante pour les titres
  const titlesDefaultProps = {
    options: titles,
    getOptionLabel: (option) => option.title
  };

  // données des Options de la liste déroulante pour les départements
  const departementsDefaultProps = {
    options: departments,
    getOptionLabel: (option) => option.department
  };

  //! ----------------------------------------------------------------
  const handleClickPassword = (e) => {
    setShowPassword(!showPassword)
  }

  const capitalizeFirstLetterOfWords = (str) => {
    //seulement la premiere lettre en majuscule
    return str.charAt(0).toUpperCase() + str.slice(1);
    //premiere lettre de chaque mot en majuscule
    // return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Effacer le contenu d'champ en cliquant sur le x
  const handleClear = (field) => {
    switch (field) {
      case 'fullName':
        setUserToEdit({ ...userToEdit, fullName: '' })
        setFullNameError('Le champ Prénom et Nom est obligatoire.')
        break;

      case 'email':
        setUserToEdit({ ...userToEdit, email: '' })
        setEmailError("L'adresse e-mail est obligatoire.")
        break;

      case 'telephone':
        setUserToEdit({ ...userToEdit, telephone: '', ext: '' })
        setTelephoneError('Veuillez saisir un numéro de téléphone à 10 chiffres.');
        break;

      case 'password':
        setUserToEdit({ ...userToEdit, password: '' })
        setPasswordStrength('Faible')
        setPasswordError('Le champ Mot de passe est obligatoire.')
        break;

      case 'poste':
        setUserToEdit({ ...userToEdit, ext: '' })
        break;

      case 'description':
        setUserToEdit({ ...userToEdit, Description: '' })
        break;

      default:
        break;
    }
  };

  const handleChange = async (e) => {
    // const name = e.target.id.split('-')[0];
    // console.log(e.target.name)
    // console.log(e.target.value)

    if (e.target.name === 'fullName') {
      const formattedFullName = capitalizeFirstLetterOfWords(e.target.value);
      validateField(formattedFullName, 'Prénom et Nom', setFullNameError);
      // console.log([e.target.name], ' : ', formattedFullName)
      setUserToEdit({ ...userToEdit, [e.target.name]: formattedFullName })
    }

    if (e.target.name === 'password') {
      validatePassword(e.target.value, setPasswordError, setPasswordStrength)
      setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value });
    }

    if (e.target.name === 'email') {
      validateEmail(e.target.value, setEmailError)
      setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value })
    }

    if (e.target.name === 'telephone') {
      const inputValue = e.target.value;
      // Vérifier si le numéro de téléphone contient des caractères non numériques sauf () et -
      if (inputValue.match(/[^\d()\- ]/)) {
        setTelephoneError('Le numéro de téléphone ne peut contenir que des chiffres.');
      } else {
        setTelephoneError(''); // Réinitialiser le message d'erreur s'il n'y a pas de caractères non numériques non autorisés
      }
      const sanitizedPhoneNumber = inputValue.replace(/[^\d]/g, ''); // Supprime les caractères non numériques,
      const formattedPhoneNumber = formatPhoneNumber(sanitizedPhoneNumber);

      setUserToEdit({ ...userToEdit, [e.target.name]: formattedPhoneNumber });

      if (formattedPhoneNumber.length === 14) {
        setTelephoneError('');
      } else {
        setTelephoneError('Veuillez saisir un numéro de téléphone à 10 chiffres.');
      }
      return;
    }

    if (e.target.name === 'ext') {
      const extPoste = e.target.value.replace(/[^\d]/g, ''); // Supprime les caractères non numérique

      // Limiter la longueur à 5 chiffres
      const maxLength = 5;
      const truncatedValue = extPoste.slice(0, maxLength);

      if (!isNaN(truncatedValue) && truncatedValue.length > 0 && truncatedValue.length <= 5)
        setUserToEdit({ ...userToEdit, [e.target.name]: truncatedValue })
      return;
    }

    if (e.target.name === 'Description') {
      setUserToEdit({ ...userToEdit, [e.target.name]: e.target.value });
      return;
    }
  }

  const regenratePassword = () => {
    const passwordGenerated = generateRandomPassword();
    validatePassword(passwordGenerated, setPasswordError, setPasswordStrength)
    setUserToEdit({ ...userToEdit, password: passwordGenerated })
  }


  return (
    <div className='UserInfoForm'>

      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
            <InputLabel htmlFor="fullName">Prénom et Nom</InputLabel>
            <Input
              id="fullName"
              name="fullName"
              autoComplete="fullName"
              required
              value={userToEdit.fullName}
              onChange={handleChange}

              startAdornment={
                <InputAdornment position="start">
                  <PersonAddRoundedIcon
                    sx={{
                      // color:
                      //   (!userToEdit.fullName || fullNameError !== '') ? 'red' : 'green',
                      color:
                        (userToEdit.fullName && fullNameError === '')
                          ? 'green'
                          : fullNameError !== ''
                            ? 'red'
                            : 'action.active',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }

              endAdornment={
                userToEdit.fullName && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => handleClear('fullName')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  </InputAdornment>
                )
              }
            />
            {fullNameError && <Typography variant="caption" color="error">{fullNameError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <InputLabel htmlFor="password">Mot de Passe</InputLabel>
            <Input
              id="password"
              name="password"
              // autoComplete="password"
              autoComplete="off"
              type={showPassword ? 'text' : 'password'}
              required
              value={userToEdit.password || ''}
              onChange={handleChange}

              startAdornment={
                <InputAdornment position="start">
                  <LockRoundedIcon
                    sx={{
                      color:
                        (userToEdit.password && !passwordError) ? 'green' :
                          (passwordStrength === 'Faible') ? 'red' :
                            (passwordStrength === 'Moyen') ? '#e59d11' :
                              (passwordStrength === 'Élevé') ? 'green' : 'gray',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position='end'>
                  <AutorenewIcon
                    onClick={regenratePassword}
                    sx={{ cursor: 'pointer' }}
                  />
                  <IconButton className='visibilityIcon'
                    onClick={handleClickPassword}
                  // onMouseDown={handleMouseDownPassword}
                  // onMouseUp={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                  {userToEdit.password && (
                    <ClearIcon onClick={() => handleClear('password')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  )}
                </InputAdornment>
              }
            />
            {passwordError && <Typography variant="caption" color="error" sx={{ ml: '32px' }}>{passwordError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <InputLabel htmlFor="email">Courriel</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="email"
              value={userToEdit.email}
              onChange={handleChange}
              placeholder="email@exp-azimut.com"
              type="email"
              required
              startAdornment={
                <InputAdornment position="start">
                  <EmailRoundedIcon
                    sx={{
                      color:
                        userToEdit.email && emailError === ''
                          ? 'green'
                          : emailError !== ''
                            ? 'red'
                            : 'action.active',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }
              endAdornment={
                userToEdit.email && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => handleClear('email')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                  </InputAdornment>
                )
              }
            />
            {emailError && <Typography variant="caption" color="error">{emailError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl ' variant="standard" sx={{ mb: 2 }}>
            <Grid container >

              <Grid item xs={8}> {/* 70% */}
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <InputLabel htmlFor="telephone"  >Téléphone</InputLabel>
                  <Input

                    id="telephone"
                    name="telephone"
                    autoComplete="telephone"
                    value={userToEdit.telephone || ''}
                    onChange={handleChange}
                    required

                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneInTalkRoundedIcon
                          sx={{
                            color:
                              userToEdit.telephone && telephoneError === ''
                                ? 'green'
                                : telephoneError !== ''
                                  ? 'red'
                                  : 'action.active',

                            mr: 1,
                            my: 0.5
                          }}
                        />
                      </InputAdornment>
                    }

                    endAdornment={
                      userToEdit.telephone && (
                        <InputAdornment position="end">
                          <ClearIcon onClick={() => handleClear('telephone')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                        </InputAdornment>
                      )
                    }
                  />
                  {telephoneError && <Typography variant="caption" color="error">{telephoneError}</Typography>}

                </FormControl>
              </Grid>

              <Grid item xs={4}> {/* 30% */}
                <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                  <InputLabel htmlFor="ext">Poste</InputLabel>
                  <Input
                    id="ext"
                    name='ext'
                    value={userToEdit.ext || ''}
                    onChange={handleChange}

                    startAdornment={
                      <InputAdornment position="start">
                        <PhoneForwardedRoundedIcon
                          sx={{
                            color:
                              (userToEdit.ext) ? 'green' : 'action.active',
                            mr: 1,
                            my: 0.5
                          }}
                        />
                      </InputAdornment>
                    }

                    endAdornment={
                      // (userToEdit.ext || userToEdit.phone) && (
                      userToEdit.ext && (
                        <InputAdornment position="end">
                          <ClearIcon onClick={() => handleClear('poste')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                        </InputAdornment>
                      )
                    }
                  />
                </FormControl>
              </Grid>

            </Grid>
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                options={genres}
                getOptionLabel={(option) => option.label}
                id="genre"
                name="genre"
                clearOnEscape

                isOptionEqualToValue={(option, value) => (option.label === value.label || option.label === '')}

                value={genres.find((option) => option.value === userToEdit.genre) || null}

                onChange={(e, newValue) => {
                  setUserToEdit((prevState) => ({
                    ...prevState,
                    genre: newValue ? newValue.value : '',
                  }));
                }}

                renderInput={
                  (params) => <TextField {...params} label="Genre" variant="standard" name="genre"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <WcRoundedIcon
                            sx={{
                              color: userToEdit && userToEdit.genre && userToEdit.genre.trim() !== ''
                                ? 'green'
                                : 'action.active',
                              mr: 1,
                              my: 0.5
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                }
              // disableClearable
              />
            </Stack>
          </FormControl>
        </Grid>
        {/* <Grid item className='gridItem' xs={12} md={6} lg={6} ></Grid> */}

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...rolesDefaultProps}

                id="role"
                name="role"
                clearOnEscape

                isOptionEqualToValue={(option, value) => (option.roleLabel === value.roleLabel || option.roleLabel === '')}

                value={roles.find((option) => option.roleName === userToEdit.roleName) || null}

                //! onChange={(e, newValue) => {
                //   setUserToEdit((prevState) => ({
                //     ...prevState,
                //     roleName: newValue ? newValue.roleName : 'ROLE_CONSULT',
                //     idRole: newValue ? newValue.idRole : roles.find(role => role.roleName === 'ROLE_CONSULT').idRole,
                //   }));
                // }}
                onChange={(e, newValue) => {
                  setUserToEdit(prevState => {
                    const updatedRole = newValue ? { roleName: newValue.roleName, idRole: newValue.idRole } : { roleName: null, idRole: null };
                    return { ...prevState, ...updatedRole };
                  });
                }}

                renderInput={
                  (params) => <TextField {...params} label="Rôle" variant="standard" name="role"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <AdminPanelSettingsIcon
                            sx={{
                              color: userToEdit && userToEdit.roleName
                                ? userToEdit.roleName === 'ROLE_ADMIN' ? '#a52525'
                                  : userToEdit.roleName === 'ROLE_CONSULT' ? 'green'
                                    : '#406cd6'
                                : 'action.active',
                              mr: 1,
                              my: 0.5
                            }}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                }
              // disableClearable
              />
            </Stack>
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...titlesDefaultProps}
                id="title"
                name="title"

                isOptionEqualToValue={(option, value) => option.title === value.title || value.title === ''}

                value={{ title: userToEdit.title || '', idtitle: userToEdit.idtitle }}

                onChange={(e, newValue) =>
                  setUserToEdit((prevState) => ({
                    ...prevState,
                    title: newValue ? newValue.title : '',
                    idtitle: newValue ? newValue.idtitle : null,
                  }))
                }
                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Titre" variant="standard" name="title" />}
              />
            </Stack>
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...departementsDefaultProps}
                id="department"
                name="department"

                isOptionEqualToValue={(option, value) => option.department === value.department || value.department === ''}

                value={{ department: userToEdit.department || '', iddepartment: userToEdit.iddepartment }}

                onChange={(e, newValue) =>
                  setUserToEdit((prevState) => ({
                    ...prevState,
                    department: newValue ? newValue.department : '',
                    iddepartment: newValue ? newValue.iddepartment : null,
                  }))
                }

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Département" variant="standard" name="department" />}
              />
            </Stack>
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={12} lg={12} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <TextField
              id="Description"
              label="Description"
              name='Description'
              autoComplete='Description'
              helperText={userToEdit.Description ? "" : "Saisir une description"}
              variant="standard"
              type="search"
              multiline
              InputProps={{
                endAdornment: userToEdit.Description && (
                  <ClearIcon onClick={() => handleClear('description')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                ),
              }}

              value={userToEdit.Description}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

      </Grid>

    </div>
  )
}
