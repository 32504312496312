import dataService from "../../httpService";

// Fonction pour obtenir les données de l'utilisateur à éditer
async function getUserDetail(idUser) {
    try {
        const response = await dataService.get('/user/details', {
            params: {
                idUser
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les titres possible de les sélectionner
async function getTitles() {
    try {
        const response = await dataService.get('/user/titles');
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour obtenir tous les départements possible de les sélectionner
async function getDepratements() {

    try {
        const response = await dataService.get('/user/departements');
        return response.data;
    } catch (error) {
        throw error;
    }
}


// Fonction pour vérifier l'unicité de l'email de l'utilisateur à ajouter
async function checkUserEmailUniqueness(email) {
    try {
        const response = await dataService.post('/user/all-users/new-user/check-user-email', {
            email: email
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour vérifier le mot de passe de l'utilisateur principal
async function checkUserPassword(password) {
    try {
        const response = await dataService.get('/user/check-password', {
            params: {
                password
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Fonction pour mettre à jour un utilisateur 
async function updateUserInfo(userToEdit) {
    try {
        const response = await dataService.put('/user/update-userInfo', {
            userToEdit:userToEdit
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// Exporter les fonctions
export {
    getUserDetail,
    getTitles,
    getDepratements,
    checkUserEmailUniqueness,
    checkUserPassword,
    updateUserInfo,
};