import React from 'react'
import './EditOneUserOfAll.scss'
import EditOneUserOfAllForm from './EditOneUserOfAllForm'
import { useParams } from 'react-router-dom';

export default function EditOneUserOfAll() {
    const { idUser } = useParams();

    return (
        <EditOneUserOfAllForm idUser={idUser} />
    )
}
