import React, { useState, useMemo, useEffect } from "react";
import { Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import './GridTable.scss'
import moment from 'moment';

function CustomToolbar({ setFilterButtonRef }) {
    const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

    return (
        <div className='grid-customToolbar-container'>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton ref={setFilterButtonRef} />
                <GridToolbarExport
                    csvOptions={{
                        fileName: `Liste-Mise-en-Ligne-${currentCity.description}-${currentCity.cityName}`,
                        delimiter: ';',
                        utf8WithBom: true,
                        columnsToExport: ['demandeur', 'typedata', 'statut', 'MiseEnLigne'],
                    }}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>
        </div>
    );
}

CustomToolbar.propTypes = {
    setFilterButtonRef: PropTypes.func.isRequired
};

export default function GridTable({ data }) {

    const [filterButtonRef, setFilterButtonRef] = useState(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const rowsWithIds = data.map((row, index) => ({ ...row, id: index }));
        setRows(rowsWithIds)
    }, [data]);

    const columns = useMemo(
        () => [
            { field: 'demandeur', headerName: 'Demandeur', flex: 1, editable: false, },
            { field: 'typedata', headerName: 'Type', flex: 1, editable: false, },
            { field: 'statut', headerName: 'Statut', flex: 1, editable: false, },
            {
                field: 'MiseEnLigne', headerName: 'Mise En Ligne', flex: 1.5, editable: false,
                renderCell: (params) => (
                    <>
                        <span>{moment(params.value).format('ddd DD MMM YYYY')}</span>
                        {/* <span>{moment(params.value).format('YYYY-MM-DD')}</span> */}
                        <span>&nbsp;à&nbsp;</span>
                        <span>{moment(params.value).format('HH:mm:ss')}</span>
                    </>
                )
            },
        ],
        []
    );

    return (
        // currentCity &&
        <Box
            className='dataGridBox'
            sx={{
                width: '100%',
            }}
        >
            <DataGrid
                slots={{
                    toolbar: CustomToolbar,
                }}

                slotProps={{
                    panel: {
                        anchorEl: filterButtonRef,
                    },
                    toolbar: {
                        setFilterButtonRef,
                    }
                }}

                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}

                pageSizeOptions={[10, 50, 100]}

                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                // getRowSpacing={(params) => ({
                //     top: params.isFirstVisible ? 0 : 5,
                //     bottom: params.isLastVisible ? 0 : 5,
                // })}

                columns={columns}
                rows={rows}
                getRowId={(row) => row.id}

                disableRowSelectionOnClick

            // components={{
            //     footer: GridFooterContainer,
            // }}
            // styleOverrides={{
            //     footer: {
            //         root: 'customFooter',
            //     },
            // }}
            />
        </Box>
    )
}
