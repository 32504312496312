import React, { useEffect, useRef, useState } from 'react';
import './WarningMessages.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ClearIcon from '@mui/icons-material/Clear';
import {
  TextField,
  Grid,
  Input,
  InputLabel,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
  Paper,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@mui/material';

import {
  getMessageTypeList,
  getMessagesListByType,
  updateMuMessage,
  addMuMessage,
} from '../httpQuerys';

import EditorWysiwyg from './Editor-Wysiwyg/EditorWysiwyg';
import DialogConfirm from './DialogConfirm';


export default function WarningMessages() {
  const WarningMessagesFormRef = useRef(null);
  const navigate = useNavigate();

  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

  const [messageToAddOrEdit, setMessageToAddOrEdit] = useState({})

  const [, setAuthor] = useState('');

  const [messageTypeList, setMessageTypeList] = useState([])
  const [messageType, setMessageType] = useState(null)

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  const [privateVersion, setPrivateVersion] = useState(false);
  const [publicVersion, setPublicVersion] = useState(false);
  const [versionError, setVersionError] = useState('');

  const [typeText, setTypeText] = useState('');
  const [frMessage, setFrMessage] = useState('');
  const [enMessage, setEnMessage] = useState('');
  const [textError, setTextError] = useState('');

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState('');


  //  valeurs a récupérer de la BD pour les types de messages
  useEffect(() => {
    getMessageTypeList().then((data) => {
      setMessageTypeList(data.filter(item => [2, 4, 5, 6].includes(item.idtypeMessage)));
    });
  }, []);

  // une fois le type de message choisit on va chercher les détails du message
  useEffect(() => {
    if (messageType && messageType.idtypeMessage) {
      // determiner la nature du message à envoyer vers la BD pour impression ou sous format HTML selon le idtypeMessage
      const typeText = (messageType.idtypeMessage === 4 || messageType.idtypeMessage === 5) ? 'print' : 'html'
      setTypeText(typeText)

      // le resultat est un tableau de taille 0 ou 1 donc on va chercher la premiere ligne data[0]
      getMessagesListByType(cityCode, messageType.idtypeMessage).then((data) => {
        if (data.length > 0) {

          // console.log('====>',data[0])
          setMessageToAddOrEdit(data[0])
          setAuthor(data[0].author)
          setTitle(data[0].title)
          setPrivateVersion(data[0].privateVersion)
          setPublicVersion(data[0].publicVersion)
          setFrMessage(data[0].text_fr)
          setEnMessage(data[0].text_en)
        }
      });
    }
  }, [cityCode, messageType]);

  // données des Options de la liste déroulante pour les départements
  const messageTypeListDefaultProps = {
    options: messageTypeList,
    getOptionLabel: (option) => `${option.idtypeMessage} - ${option.typedescription}`
  };

  //!============================================================

  const capitalizeTheFirstLetter = (str) => {
    //seulement la premiere lettre en majuscule
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Effacer le contenu detous les champs
  const handleClearAllFields = () => {
    setMessageToAddOrEdit({})
    setMessageType(null)
    setTitle('')
    setTitleError('')
    setPublicVersion(false)
    setPrivateVersion(false)
    setVersionError('')
    setFrMessage('')
    setEnMessage('')
    setTextError('')
    setTypeText('')
  };

  // gestion de l'annulation
  const handleCancel = () => {
    handleClearAllFields()
    navigate(`/dashboard/municipalites/${cityCode}/mu-configuration`)
  }

  // gestion de la reinitialisation
  const handleReset = async () => {
    setMessageToAddOrEdit({})
    setTitle('')
    setTitleError('')
    setPublicVersion(false)
    setPrivateVersion(false)
    setVersionError('')
    setFrMessage('')
    setEnMessage('')
    setTextError('')
    setTypeText('')

    await getMessagesListByType(cityCode, messageType.idtypeMessage).then((data) => {
      if (data.length > 0) {
        setMessageToAddOrEdit(data[0])
        setAuthor(data[0].author)
        setTitle(data[0].title)
        setPrivateVersion(data[0].privateVersion)
        setPublicVersion(data[0].publicVersion)
        setFrMessage(data[0].text_fr)
        setEnMessage(data[0].text_en)
      }
    });
  }


  // gestion de la suppression d'un message d'avertissement
  const handleDelete = async () => {
    setAction('Delete');
    setOpen(true);
  }
  //!============================================================

  // gestion de la soumission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Copier les états actuels dans de nouvelles variables
    let newFrMessage = frMessage;
    let newEnMessage = enMessage;

    // Si le type de texte est 'print' ou 'html', modifier les messages en conséquence
    if (typeText === 'print') {
      newFrMessage = frMessage
        ? frMessage.replace(/<p><br><\/p>/g, '\\n').replace(/<\/\w+>/g, '\\n').replace(/<[^>]*>/g, '').replace(/'/g, '’')
        : '';
      newEnMessage = enMessage
        ? enMessage.replace(/<p><br><\/p>/g, '\\n').replace(/<\/\w+>/g, '\\n').replace(/<[^>]*>/g, '').replace(/'/g, '’')
        : '';
    } else if (typeText === 'html') {
      newFrMessage = frMessage ? frMessage.replace(/'/g, '’') : '';
      newEnMessage = enMessage ? enMessage.replace(/'/g, '’') : '';
    }



    // Créer un nouvel objet messageToSave avec les propriétés mises à jour
    let messageToSave = {
      idCityMessage: messageToAddOrEdit.idCityMessage ?? null,
      cityCode: cityCode,
      dateStart: null,
      dateEnd: null,
      autoClose: null,
      blocking: 0,
      idtypeMessage: messageType.idtypeMessage,
      privateVersion: privateVersion ?? false,
      publicVersion: publicVersion ?? false,
      userClose: null,
      orderBy: null,
      frequency: null,
      nameMessage: title,
      idService: null,
      isAzimut: 0,
      author: currentUserInfo.idUser,
      displayTime: null,

      idMessage: messageToAddOrEdit.idMessage ?? null,
      title: title,
      text_en: newEnMessage,
      text_es: null,
      text_fr: newFrMessage,

    }

    // Validation des champs
    if (!title)
      setTitleError('Le champ titre est obligatoire.')

    if (!privateVersion && !publicVersion)
      setVersionError('Au moins l\'une des deux versions doit être sélectionnée.')

    const existTextLang = (messageToSave.text_fr || messageToSave.text_en)

    // Vérification si un message existe et s'il a un texte associé
    if (!existTextLang) {
      console.log('Aucun message trouvé.');
      setTextError('Aucun message n\'a été saisi. Cela implique qu\'il n\'y aura pas de message à afficher.')
    } else {
      setTextError('')
    }

    // Vérification de la validité du formulaire
    const isFormValid =
      (titleError === '' && title)
      && (privateVersion || publicVersion)
      && (existTextLang)

    if (isFormValid) {
      setMessageToAddOrEdit(messageToSave)

      if (messageToSave.idCityMessage) {
        setAction('Update')
        const result = await updateMuMessage(messageToSave);
        if (result)
          console.log('Soumission du Formulaire de mise à jour du message');
      } else {
        setAction('AddNew')
        const result = await addMuMessage(messageToSave);
        if (result)
          console.log('Soumission du Formulaire d\'ajout d\'un nouveau message');
      }
      setIsFormValidated(true);

      // permet d'afficher la boite de dialogue à la soumission du formulaire
      setOpen(true);

    } else {
      console.log('Un des champs est manquant ou mal saisi');
      setIsFormValidated(false);
      setOpen(true);
    }
  }

  return (
    <div className='WarningMessages'>
      {open && (
        <DialogConfirm
          messageToAddOrEdit={messageToAddOrEdit}
          open={open}
          setOpen={setOpen}
          action={action}
          isFormValidated={isFormValidated}
          titleError={titleError}
          versionError={versionError}
          textError={textError}
          handleClearAllFields={handleClearAllFields}
        />
      )}

      <div className="topPage">
        <div className="topTitle">
          <MarkUnreadChatAltIcon />
          <div>Modifier les messages et textes d'alerte   </div>
          <span><em>{currentCity.cityName}</em></span>
        </div>
        <div className="topNav">
          <Link className='topNav-close' to={`/dashboard/municipalites/${cityCode}/mu-configuration`} style={{ textDecoration: "none", cursor: 'pointer' }}>
            <CloseRoundedIcon />
          </Link>
        </div>
      </div>

      <Paper elevation={5} className='WarningMessages-paper' sx={{ height: 600, overflowY: 'auto' }}>

        <form ref={WarningMessagesFormRef} className='WarningMessages-form' onSubmit={handleSubmit}>

          <Grid container className='gridContainer' spacing={2} m='0' >

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    {...messageTypeListDefaultProps}
                    id="messageTypeList"
                    name="messageTypeList"

                    isOptionEqualToValue={(option, value) => option.idtypeMessage === value.idtypeMessage}

                    value={messageType || null}
                    onChange={(e, newValue) => {
                      // console.log('Type de message newValue ===> ', newValue)
                      handleClearAllFields()
                      if (newValue) {
                        setMessageType(newValue)
                      }
                    }}

                    clearOnEscape
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type du message"
                        variant="standard"
                        name="messageTypeList"
                      />
                    )}
                  />
                </Stack>
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6}  >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
                <InputLabel htmlFor="title">Titre du message</InputLabel>
                <Input
                  id="title"
                  name="title"
                  autoComplete="title"
                  disabled={messageType === null}
                  required

                  value={title || ''}
                  onChange={(e) => {
                    const newValue = capitalizeTheFirstLetter(e.target.value);
                    setTitle(newValue)
                    setTitleError('')
                  }}

                  endAdornment={
                    title && (
                      <InputAdornment position="end">
                        <ClearIcon onClick={() => setTitle('')} sx={{ cursor: 'pointer', fontSize: 'small' }} />
                      </InputAdornment>
                    )
                  }
                />
                {titleError && <Typography variant="caption" color="error">{titleError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>
              <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                <FormControlLabel className='gridItem-formControlLabel left'
                  control={
                    <Checkbox
                      className='checkbox-version'
                      id="publicVersion"
                      name="publicVersion"
                      disabled={messageType === null}

                      checked={publicVersion || false}
                      onChange={(e) => {
                        setPublicVersion(e.target.checked)
                        setVersionError('');
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'publicVersion checkbox' }}
                    />
                  }
                  label={
                    <div className='version-label'>
                      Version publique
                    </div>
                  }
                  labelPlacement="end"
                />
              </FormControl>

              <FormControl className='gridItem-formControl-composite' variant="standard" sx={{ mb: 2 }} m='0'>
                <FormControlLabel className='gridItem-formControlLabel right'
                  control={
                    <Checkbox
                      className='checkbox-version'
                      id="privateVersion"
                      name="privateVersion"
                      disabled={messageType === null}

                      checked={privateVersion || false}
                      onChange={(e) => {
                        setPrivateVersion(e.target.checked);
                        setVersionError('');
                      }}
                      color="primary"
                      inputProps={{ 'aria-label': 'contact checkbox' }}
                    />
                  }
                  label={
                    <div className='version-label'>
                      Version privée
                    </div>
                  }
                  labelPlacement="end"
                />
              </FormControl>
            </Grid>
            <Grid item className='gridItem gridItem-composite' xs={12} md={6} lg={6}>
              {messageToAddOrEdit?.idMessage &&
                <Button
                  className='btnDelete' // classe CSS pour le style de suppression
                  variant="contained"
                  color="error" // couleur d'erreur pour indiquer une action de suppression
                  sx={{ width: '100%', cursor: 'pointer' }}
                  disabled={!messageType}
                  onClick={handleDelete} // assurez-vous que cette fonction handleReset gère la suppression
                >
                  Supprimer le message
                </Button>
              }
            </Grid>
            <hr />

            <Grid item className='gridError' xs={12} md={12} lg={12} >
              {versionError && <Typography variant="caption" color="error">{versionError}</Typography>}
            </Grid>

            <Grid item className='gridError' xs={12} md={12} lg={12} >
              {textError && <Typography variant="caption" color="error">{textError}</Typography>}
            </Grid>

            <hr />

            <EditorWysiwyg
              typeText={typeText}
              language={'français'}
              message={frMessage}
              setMessage={setFrMessage}
              setMessageError={setTextError}
              disabled={messageType === null}
            />
            <EditorWysiwyg
              typeText={typeText}
              language={'anglais'}
              message={enMessage}
              setMessage={setEnMessage}
              setMessageError={setTextError}
              disabled={messageType === null}
            />

            <hr />

          </Grid>

          <div className='footerWarningMessages'>
            <Button
              className='btnSubmit'
              variant="contained"
              sx={{ width: '200px', cursor: 'pointer' }}
              onClick={handleCancel}
            >
              Annuler
            </Button>

            <Button
              className='btnSubmit'
              variant="contained"
              sx={{ width: '200px', cursor: 'pointer' }}
              disabled={!messageType}
              onClick={handleReset}
            >
              Réinitialiser
            </Button>

            <Button
              className='btnSubmit'
              type="submit"
              variant="contained"
              sx={{ width: '200px' }}
              disabled={!messageType}
              onClick={handleSubmit}>
              Enregistrer
            </Button>
          </div>

        </form>
      </Paper>
    </div>
  )
}

