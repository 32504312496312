import React, {  useState } from "react";
import { Link } from "react-router-dom";
import "./MuListe.scss";
import { getUserMunicipalitesData } from './httpQuerys';
import GridTable from "./data-grid/GridTable";
import SearchBar from '../../../components/search-bar/SearchBar';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useQuery } from "react-query";

export default function MuListe() {

  localStorage.removeItem('currentMunicipality');
  const {gde} = JSON.parse(localStorage.getItem('portailclient'));
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  const [query, setQuery] = useState('');

  const { data, isLoading, isError } = useQuery('MuCities', () => getUserMunicipalitesData(gde, currentUserInfo));
  if (isLoading) {
    return <div>Chargement en cours...</div>;
  }
  if (isError) {
    return <div>Une erreur s'est produite lors de la récupération des données.</div>;
  }
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   getUserMunicipalitesData(gde).then(data => setData(data));
  // }, [gde]);

//!----------------------------------------------------------------

  const tabKeys = ['cityCode', 'cityName', 'mrcCode', 'mrcName', 'mrcRealName', 'populationYear'];
  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    data &&
    <div className="MuListe">

      <div className="topPage">
        <div className="topTitle">
          <MapsHomeWorkRoundedIcon />
          <div>Liste des municipalités </div>
        </div>
        <div className="topNav">
          <Link
            className="topNav-close"
            to={`/dashboard`}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <CloseRoundedIcon />
          </Link>
        </div>
      </div>

      <SearchBar query={query} setQuery={setQuery} />

      <GridTable data={showList(data)} />

    </div>
  );
}
