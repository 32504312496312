import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';

export default function DialogConfirm({ userToAdd, open, setOpen, isFormValidated }) {

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false)

    navigate(`/dashboard/municipalites/${userToAdd.currentCity.cityCode}/mu-utilisateurs`);
  };

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClose(); // Appelle la fonction handleConfirm
    }
  };


  return (

    isFormValidated ? (
      <div>
        <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
          <DialogTitle>Confirmation d'ajout d'un utilisateur</DialogTitle>
          <DialogContent>
            <DialogContentText>
              L'utilisateur <strong><em>{userToAdd.fullName}</em></strong> a été ajouté
              {userToAdd.currentCity.article === 'La' ? ' à la ' : userToAdd.currentCity.article === 'Le' ? ' au ' : ' aux '}  <strong><em>{userToAdd.currentCity.description} de {userToAdd.currentCity.cityName}</em></strong>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCancel}>ANNULER</Button> */}
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>

      </div>

    ) : (

      <div>
        <Dialog open={open} onClose={handleCancel}>
          <DialogTitle>Erreur dans le formulaire</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Un ou plusieurs champs du formulaire sont manquants ou érronés.
              Veuillez vérifier vos données !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Retour au Formulaire</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  )
}
