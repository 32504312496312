export default function ErrorPage() {

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Désolé, une erreur inattendue s'est produite.</p>
      <hr />
      <p>Sorry, an unexpected error has occurred.</p>
      <a href="/" >
        <span>Dashboard</span>
      </a>
    </div>
  );
}