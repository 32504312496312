import React, { useEffect, useState } from "react";
import './UsersList.scss';
import GridTable from "../data-grid/GridTable";
import SearchBar from "../../../components/search-bar/SearchBar";
import { getAllUsersData } from "../httpQuerys";

export default function UsersList({allowedManagement}) {

  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getAllUsersData().then((data) => setData(data));
  }, []);

  // search bar fonction
  const tabKeys = ["fullName", "email", "telephone", "title", "department"];
  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    <div className='UsersList'>
      <SearchBar query={query} setQuery={setQuery} />
      <GridTable data={showList(data)}  allowedManagement={allowedManagement}/>
    </div>
  );
}