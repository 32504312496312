import React, { useEffect, useState } from 'react';
import './UserProfilesServicesForm.scss';
import {
  groupMunicipalitiesGetList,
  getProfileList,
  getServicesProfile,
} from '../../httpQuerys';
import TextField from '@mui/material/TextField';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AccordionProfilesAndServices from '../Accordion-Profiles-services/AccordionProfilesAndServices'
import { useQuery } from 'react-query';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProfileDialogConfirm from './ProfileDialogConfirm';

export default function UserProfilesServicesForm(props) {

  const {
    userToEdit,
    setUserToEdit,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { gde } = JSON.parse(localStorage.getItem('portailclient'));
  const currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

  const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;
  const expiryDateNextYear = dayjs().add(1, 'year');

  const [municipalities, setMunicipalities] = useState([]);
  const [municipality, setMunicipality] = useState(null);
  const [municipalitiesError, setMunicipalitiesError] = useState([]);

  const [profilesList, setProfilesList] = useState([]);
  const [profile, setProfile] = useState(null);
  const [profileError, setProfileError] = useState('');

  const [profileServicesList, setProfileServicesList] = useState([]);
  const [profileServices, setProfileServices] = useState([]);
  const [profileServicesError, setProfileServicesError] = useState('');

  const [defaultServiceList, setDefaultServiceList] = useState([]);
  const [defaultService, setDefaultService] = useState(null);
  const [defaultServiceError, setDefaultServiceError] = useState('');

  const [contact, setContact] = useState(false);
  const [expiration, setExpiration] = useState(expiryDateNextYear);//dayjs()

  const [btnTitle, setBtnTitle] = useState('AJOUTER');
  const [foundedValue, setFoundedValue] = useState();

  const [showProfileDialog, setShowProfileDialog] = useState(false);

  // ---------------------------------------------------------------
  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");
  // ---------------------------------------------------------------

  const { data, isLoading, isError } = useQuery('Municipalities', () => groupMunicipalitiesGetList(gde, currentUserInfo));
  // getUserMunicipalitesData(gde, currentUserInfo).then(data => setMunicipalities(data));

  //  valeurs a récupérer de la BD titres et departments attentions avec le e dans 'depart e ment'
  //  récuperer, une seule fois lors du chargement, toutes les données dont on a besoin de la BD
  useEffect(() => {
    if (!isLoading && !isError) {
      setMunicipalities(data);
    }
    getProfileList(currentUserInfo.idGroup).then(data => setProfilesList(data));
    // if (userProfile.cityCode && userProfile.nameProfile) {
    if (municipality && profile) {
      // getServicesProfile(userProfile.cityCode, userProfile.idGroup, userProfile.nameProfile).then(data => setProfileServicesList(data));
      getServicesProfile(municipality.cityCode, municipality.idGroup, profile.nameProfile).then(data => setProfileServicesList(data));
    }
  }, [currentUserInfo.idGroup, data, isError, isLoading, municipality, profile]);

  useEffect(() => {
    if (foundedValue) {
      setProfile({ nameProfile: foundedValue.profile })
      setProfileServices(foundedValue.servicesProfile)
      setDefaultServiceList(foundedValue.servicesProfile)
      const foundDefultService = foundedValue.servicesProfile.find((service) => service.defaultService === true)
      setDefaultService(foundDefultService)
      setContact(foundedValue.contact)
      setExpiration(foundedValue.expiration)
    }
  }, [foundedValue]);

  // données des Options de la liste déroulante pour les municipalities
  const MunicipalitiesDefaultProps = {
    options: municipalities,
    getOptionLabel: (option) => `${option.cityName} (${option.cityCode})`,
  };

  // données des Options de la liste déroulante pour les profils
  const profilesDefaultProps = {
    options: profilesList,
    getOptionLabel: (option) => option.nameProfile
  };

  // données des Options de la liste déroulante pour les servicesProfile
  const profileServicesDefaultProps = {
    options: profileServicesList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  // données des Options de la liste déroulante des servicesProfile accordés à l'utilisateur
  const defaultServiceProfileDefaultProps = {
    // options: userProfile.servicesProfile,
    options: defaultServiceList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  //  valeurs a récupérer de la BD la liste des profils
  // useEffect(() => {
  //   getProfileList(userToEdit.idGroup).then(data => {
  //     setProfilesList(data)
  //     setProfile(data.find(item => item.nameProfile === userToEdit.profile))
  //   });

  //   setServicesProfile(userToEdit.servicesProfile)
  //   getServicesProfile(cityCode, userToEdit.idGroup, userToEdit.profile).then(data => {
  //     setProfileServicesList(data)
  //   });

  //   setDefaultServiceProfileList(userToEdit.servicesProfile)
  //   setDefaultServiceProfile(userToEdit.defaultServiceProfile)
  //   setExpiration(dayjs(userToEdit.expiration))
  // }, [cityCode, userToEdit]);

  // useEffect(() => {
  //   setFinalProfilesList(userToEdit.profiles)
  // }, [userToEdit]);


  const [isAddProfileVisible, setIsAddProfileVisible] = useState(false);

  const displayProfile = (importedProfile) => {
     if (importedProfile) {
      setBtnTitle('MODIFIER')
      const foundMunicipality = municipalities.find((muItem) => muItem.cityName === importedProfile.cityName)
      setMunicipality(foundMunicipality)
      setProfile({ nameProfile: importedProfile.profile })
      // getServicesProfile(foundMunicipality.cityCode, foundMunicipality.idGroup, importedProfile.profile)
      //   .then(data => setProfileServicesList(data)).then(setProfileServices(res));
      const res = importedProfile.servicesProfile.map((service) => (
        {
          aliasName: service.aliasName,
          cityCode: importedProfile.cityCode,
          idGroup: importedProfile.idGroup,
          idService: service.idService,
          idServiceType: service.idServiceType,
        }
      ))
      setProfileServices(res)
      setDefaultServiceList(res)
      const foundDefultService = importedProfile.servicesProfile.find((service) => service.defaultService === true)
      setDefaultService(foundDefultService)
      setContact(importedProfile.contact)
      setExpiration(dayjs(importedProfile.expiration))
      setIsAddProfileVisible(true);
    } else {
      setIsAddProfileVisible(!isAddProfileVisible);
    }
  }

  const formatDateTime = (newValue) => {
    const formattedDateTime = dayjs(newValue).locale('fr').format('YYYY-MM-DD HH:mm:ss');
    return formattedDateTime
    // setExpiration(newValue);
    // setUserToEdit(prevUser => ({ ...prevUser, expiration: formattedDateTime }))
  }

  const [updatedUserToEdit, setUpdatedUserToEdit] = useState();

  const handleProfilAndServices = () => {
    let validateUserProfile = true;
    if (!municipality) { setMunicipalitiesError('Sélectionner une municipalité.'); validateUserProfile = false; }
    if (!profile) { setProfileError('Un profil est obligatoire.'); validateUserProfile = false; }
    if (profileServices.length === 0) { setProfileServicesError('Au minimum un service est obligatoire.'); validateUserProfile = false; }
    if (!defaultService) { setDefaultServiceError('Le champ service par défaut est obligatoire.'); validateUserProfile = false; }

    if (validateUserProfile) {
      const updatedProfileServices = profileServices.map(item => ({
        idService: item.idService,
        aliasName: item.aliasName,
        defaultService: item.idService === defaultService.idService ? true : false,
        idServiceType: item.idServiceType,
      }));

      // Créer le nouvel état à appliquer
      const newProfile = {
        cityCode: municipality.cityCode,
        cityName: municipality.cityName,
        contact: contact,
        creationUserProfile: toDay,
        expiration: formatDateTime(expiration),
        // expiration: expiration,
        idGroup: municipality.idGroup,
        idUserProfile: null,
        logo: municipality.logo,
        profile: profile.nameProfile,
        mrcCode: municipality.mrcCode,
        mrcRealName: municipality.mrcRealName,
        servicesProfile: updatedProfileServices,
      };

      // Vérifier si newProfile existe déjà dans finalProfilesList
      // en cherchant l'indexe du newProfile.cityName parmis les profils de l'utilisateur
      const index = userToEdit.profiles.findIndex(
        (profile) => profile.cityName === newProfile.cityName
      );

      //  Le profil existe déjà dans finalProfilesList alors demander s'il on valide la modification
      if (index !== -1) {
        newProfile.idUserProfile = userToEdit.profiles[index].idUserProfile;
        // console.log(newProfile.idUserProfile, '<<====')
        const updatedProfiles = [...userToEdit.profiles];
        updatedProfiles[index] = newProfile;

        setUpdatedUserToEdit({
          ...userToEdit,
          profiles: updatedProfiles
        });
        // Afficher un message dans une boîte de dialogue
        setShowProfileDialog(true)

      } else { //  Le profil n'existe pas dans finalProfilesList donc l'ajouter
        const updatedUserToEdit = {
          ...userToEdit,
          profiles: [...userToEdit.profiles, newProfile]
        };
        setUserToEdit(updatedUserToEdit);
        // alert(`Le profil ${newProfile.cityName} a été ajouté.`);
      }

      // Réinitialisation des états
      setMunicipality(null);
      setProfile(null);
      setProfileServices([]);
      setDefaultService(null);
      setContact(false);
      setExpiration(expiryDateNextYear);//dayjs()
      setIsAddProfileVisible(false);
    }
  }

  const handleDialogConfirm = () => {
    // L'utilisateur a confirmé dans la boîte de dialogue personnalisée
    // Mettre à jour le profil existant
    setShowProfileDialog(false);
    // ... (code pour la mise à jour du profil existant)
    setUserToEdit(updatedUserToEdit);
  };

  const handleDialogCancel = () => {
    // L'utilisateur a annulé dans la boîte de dialogue personnalisée
    setShowProfileDialog(false);
    // ... (code pour traiter l'annulation)
  };


  return (
    <div className='UserProfilesServicesForm'>
      {showProfileDialog && (
        <ProfileDialogConfirm
          userToEdit={userToEdit}
          onConfirm={handleDialogConfirm}
          onCancel={handleDialogCancel}
        />
      )}

      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem checkboxItem ' xs={12} md={12} lg={6} >
          <div className='addProfileLabeContainer'>
            <FormLabel className='checkboxItem-title'>PROFILS ET SERVICES</FormLabel>
            <IconButton
              className='btn-AddProfile'
              onClick={() => displayProfile()}
            >
              <PostAddIcon />
            </IconButton>
          </div>
        </Grid>

        {isAddProfileVisible && (
          <>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    {...MunicipalitiesDefaultProps}
                    id="municipalities"
                    name="municipalities"

                    isOptionEqualToValue={(option, value) => option.cityName === value.cityName}

                    value={municipality}

                    onChange={(e, newValue) => {
                      // console.log(newValue);

                      if (newValue) {

                        const foundInMuList = userToEdit.profiles.find((item) => item.cityName === newValue.cityName)
                        // console.log(foundInMuList);
                        if (foundInMuList) {
                          setBtnTitle('MODIFIER')
                          setFoundedValue(foundInMuList)
                          // showInfoUser(foundInMuList)
                        } else {
                          setBtnTitle('AJOUTER')
                        }
                        setMunicipality(newValue)
                        setMunicipalitiesError('');

                      }
                      else {
                        // L'utilisateur a effacé la valeur sélectionnée
                        setMunicipality(null)
                      }
                      setProfile(null)
                      setProfileServices([])
                      setDefaultService(null)
                      setContact(false)
                      setExpiration(expiryDateNextYear)//dayjs()
                    }}

                    onInputChange={(e, newInputValue) => {
                      if (!newInputValue) {
                        // L'utilisateur a effacé le champ au clavier
                        setMunicipality(null)
                        setProfile(null)
                        setProfileServices([])
                        setDefaultService(null)
                        setBtnTitle('AJOUTER')
                      }
                    }}

                    clearOnEscape
                    renderInput={(params) => <TextField {...params} label="Municipalités" variant="standard" name="municipalities" />}
                  />
                </Stack>
                {municipalitiesError && <Typography variant="caption" color="error">{municipalitiesError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    {...profilesDefaultProps}
                    id="profilesList"
                    name="profilesList"
                    // disabled={!userProfile.cityCode}
                    disabled={!municipality}

                    isOptionEqualToValue={(option, value) => option.nameProfile === value.nameProfile}

                    value={profile}

                    onChange={(e, newValue) => {
                      if (newValue) {
                        setProfile(newValue);
                        setProfileServices([])
                        setDefaultService(null)
                        setProfileError('');
                      }
                      else {
                        // L'utilisateur a effacé la valeur sélectionnée
                        setProfile(null);
                        setProfileServices([])
                        setDefaultService(null)
                        // setUserProfile((prevState) => ({
                        //   ...prevState,
                        //   nameProfile: null,
                        //   servicesProfile: [],
                        // }));
                      }
                    }}

                    onInputChange={(e, newInputValue) => {
                      if (!newInputValue) {
                        // L'utilisateur a effacé le champ au clavier
                        setProfile(null);
                        setProfileServices([])
                        setDefaultService(null)
                        // setNewUserToAdd((prevState) => ({
                        //   ...prevState,
                        //   profile: null,
                        //   servicesProfile: []
                        // }));
                      }
                    }}

                    clearOnEscape
                    renderInput={(params) => <TextField {...params} label="Profils" variant="standard" name="profilesList" />}
                  />
                </Stack>
                {profileError && <Typography variant="caption" color="error">{profileError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    {...profileServicesDefaultProps}
                    multiple
                    id="multipleProfileServices"
                    name="multipleProfileServices"
                    disableCloseOnSelect
                    // disabled={!userProfile.nameProfile}
                    disabled={!profile}

                    isOptionEqualToValue={(option, value) => option.aliasName === value.aliasName}

                    // value={userProfile.servicesProfile}
                    value={profileServices}

                    onChange={(e, newValue) => {
                      // console.log(newValue)
                      if (newValue) {
                        setProfileServices(newValue)
                        setDefaultServiceList(newValue)
                        newValue.length === 1 ? setDefaultService(newValue[0]) : setDefaultService(null)
                        setProfileServicesError('');
                        setDefaultServiceError('');
                      }
                    }}

                    // disableCloseOnSelect

                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.aliasName}
                        </li>
                      )
                    }}

                    clearOnEscape
                    renderInput={(params) => <TextField {...params} label="Services du profil" variant="standard" name="multipleProfileServices" />}
                  />
                </Stack>
                {profileServicesError && <Typography variant="caption" color="error">{profileServicesError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
                <Stack spacing={1} >
                  <Autocomplete
                    {...defaultServiceProfileDefaultProps}
                    id="defaultServiceProfile"
                    name="defaultServiceProfile"
                    // disabled={userProfile.servicesProfile.length === 0}
                    disabled={profileServices.length === 0}

                    isOptionEqualToValue={(option, value) => option.defaultServiceProfile === value.defaultServiceProfile}

                    // value={userProfile.servicesProfile.find((item) => item.defaultService === 1) || null}

                    value={defaultService}

                    onChange={(e, newValue) => {

                      setDefaultServiceError('');
                      if (newValue) {
                        // console.log(newValue)
                        setDefaultService(newValue)
                      } else {
                        // L'utilisateur a effacé la valeur sélectionnée
                        // setUserProfile((prevState) => ({
                        //   ...prevState,
                        //   servicesProfile: userProfile.servicesProfile.map((item) => ({ ...item, defaultService: 0 })),
                        // }));
                      }
                    }}


                    onInputChange={(e, newInputValue) => {
                      if (!newInputValue) {
                        setDefaultService(null)
                        // L'utilisateur a effacé le champ au clavier
                        // setUserProfile((prevState) => ({
                        //   ...prevState,
                        //   servicesProfile: userProfile.servicesProfile.map((item) => ({ ...item, defaultService: 0 })),
                        // }));
                      }
                    }}

                    clearOnEscape
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Service par defaut du profil"
                          variant="standard"
                          name="defaultServiceProfile"
                        />
                      )
                    }}
                  />
                </Stack>
                {defaultServiceError && <Typography variant="caption" color="error">{defaultServiceError}</Typography>}
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6}>
              <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
                <FormControlLabel
                  className='contact-formControl'
                  control={
                    <Checkbox
                      className='contact-checkbox'
                      id="contact"
                      name="contact"
                      disabled={!municipality}
                      checked={contact}
                      onChange={(e) => setContact(e.target.checked)}
                      color="primary"
                      inputProps={{ 'aria-label': 'contact checkbox' }}
                    />
                  }
                  label={
                    <div className='contact-label'>
                      <PermContactCalendarIcon
                        sx={{
                          color: contact ? 'green' : (municipality ? 'action.active' : 'lightgray'), // Use light gray if disabled

                          // color: contact ? 'green' : 'action.active',
                          marginRight: '0.5rem'
                        }}
                      />
                      Contact
                    </div>
                  }
                  labelPlacement="start"
                />
              </FormControl>
            </Grid>

            <Grid item className='gridItem' xs={12} md={6} lg={6} >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                // locale={frFR} // Utilisez la locale française canadienne pour @mui/x-date-pickers
                locale="fr-FR"
              >
                <DatePicker
                  label="Date d'expiration"
                  format="YYYY/MM/DD"
                  openTo="day"
                  views={["year", "month", "day"]}
                  slotProps={{
                    textField: {
                      variant: "filled",
                      size: "small",
                      // helperText: "Saisir une date AAAA/MM/JJ"
                    }
                  }}
                  disabled={!municipality}
                  value={dayjs(expiration) || null}
                  onChange={(newValue) => setExpiration(newValue)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item className='gridItem' xs={12} md={12} lg={12} >
              <Button
                className='Btn-AddProfile'
                variant="contained"
                sx={{ width: '300px' }}
                disabled={!municipality}
                onClick={handleProfilAndServices}
              >
                {btnTitle} ce profil et ses services
              </Button>
            </Grid>
          </>
        )}

        {/* ==================================================== */}

        <AccordionProfilesAndServices
          userToEdit={userToEdit}
          setUserToEdit={setUserToEdit}
          displayProfile={displayProfile}
        />

      </Grid>
    </div>
  )
}



// Vérifier si newProfile existe déjà dans finalProfilesList
// const isProfileAlreadyExists = userToEdit.profiles.some(
//   (profile) => profile.cityName === newProfile.cityName
// );


// if (isProfileAlreadyExists) {
// Afficher un message dans une boîte de dialogue
// alert(`Le profil ${newProfile.cityName} existe déjà parmis les profils de cet utilisateur ! voulez vous le modifier`);
// return; // Arrêter la fonction ici pour éviter d'ajouter un profil existant


// const userResponse = window.confirm(`Le profil ${newProfile.cityName} existe déjà parmi les profils de cet utilisateur ! Voulez-vous le modifier ?`);
// // Si l'utilisateur a cliqué sur "Oui", mettre à jour le profil existant.
// if (userResponse) {
// const updatedProfiles = [...userToEdit.profiles];
// updatedProfiles[index] = newProfile;

// const updatedUserToEdit = {
//   ...userToEdit,
//   profiles: updatedProfiles
// };
//   setUserToEdit(updatedUserToEdit);
//   // Affichez une alerte
//   alert(`Le profil ${newProfile.cityName} a été mis à jour.`);

// } else {
//   // Si l'utilisateur a cliqué sur "Non".
//   // return; // Arrêter la fonction ici pour éviter d'ajouter un profil existant.
// }