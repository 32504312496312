import React, { useState } from 'react'
import './FormInput.scss'

export default function FormInput(props) {
    const { label, errorMessage, handleChange, ...inputProps } = props;

    const [focused, setFocused] = useState(false)

    const handleFocus = (e) => {
        setFocused(true)
    }

    return (
        <div className='formInput'>
            <label htmlFor={inputProps.id}>{label}</label>
            <input
                {...inputProps}
                onChange={handleChange}
                onBlur={handleFocus}
                onFocus={() => inputProps.name === 'confirmPassword' && setFocused(true)}
                focused={focused.toString()}
            />
            <span>{errorMessage}</span>
        </div>
    )
}
