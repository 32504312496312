import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router';

export default function DialogConfirm({ action, handleClearAllFields, popUpMessage, open, setOpen, isFormValidated, titleError, versionError, textError }) {

  const navigate = useNavigate();
  const { cityCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  const handleClose = () => {
    setOpen(false)
    navigate(`/dashboard/municipalites/${cityCode}/mu-messages/mu-messages-list`)
    handleClearAllFields()
  };

  const handleCancel = () => {
    setOpen(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClose();
    }
  };

  return (
    isFormValidated ? (
      <Dialog open={open} onClose={handleClose} onKeyDown={handleKeyDown}>
        <DialogTitle>Confirmation {action === 'Update' ? ' de mise à jour ' : ' d\'ajout '} du message contextuel d'alerte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Message d'alerte Pop-up {action === 'Update' ? ' mis à jour ' : ' ajouté '} correctement </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    ) : (
      <div>
        <Dialog open={open} onClose={handleCancel}>
          <DialogTitle>Erreur dans le formulaire</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Un ou plusieurs champs du formulaire sont manquants ou érronés.
              Veuillez vérifier vos données ! <br />
              {titleError && <span> - {titleError}<br /></span>}
              {versionError && <span> - {versionError}<br /></span>}
              {textError && <span> - {textError}<br /></span>}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Retour au Formulaire</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  )
}
