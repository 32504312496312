import React, { useEffect, useState } from 'react';
import './UserProfilesServicesForm.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import {
  getProfileList,
  getServicesProfile
} from '../../httpQuerys';
import {
  TextField,
  Grid,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function UserProfilesServicesForm(props) {

  const {
    userToAdd,
    setUserToAdd,
    profileError,
    setProfileError,
    profileServicesError,
    setProfileServicesError,
    defaultServiceError,
    setDefaultServiceError,
    // isAccordionValidated
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // const toDay = dayjs().isValid() ? dayjs().locale('frFR').format('YYYY-MM-DD HH:mm:ss') : null;

  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const { cityCode } = currentCity

  const [profilesList, setProfilesList] = useState([]);
  const [profile, setProfile] = useState(null);

  const [profileServicesList, setServicesProfile] = useState([]);
  const [profileServices, setProfileServices] = useState([]);

  // const [defaultServiceList, setDefaultServiceList] = useState([]);
  const [defaultService, setDefaultService] = useState(null);

  // ---------------------------------------------------------------
  const [expiration, setExpiration] = useState(dayjs());
  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");
  // ---------------------------------------------------------------

  //  valeurs a récupérer de la BD la liste des profils
  useEffect(() => {
    getProfileList(userToAdd.idGroup).then(data => setProfilesList(data));

    userToAdd.profile && getServicesProfile(cityCode, userToAdd.idGroup, userToAdd.profile).then(data => setServicesProfile(data));
  }, [cityCode, userToAdd]);

  // données des Options de la liste déroulante pour les profils
  const profilesDefaultProps = {
    options: profilesList,
    getOptionLabel: (option) => option.nameProfile
  };

  // données des Options de la liste déroulante pour les servicesProfile
  const profileServicesDefaultProps = {
    options: profileServicesList,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  // données des Options de la liste déroulante des servicesProfile accordés à l'utilisateur
  const defaultServiceProfileDefaultProps = {
    // options: [...servicesProfile],  //, { idService:'', aliasName:'' }
    options: userToAdd.servicesProfile,
    getOptionLabel: (option) => option.aliasName ? option.aliasName : ''
  };

  const handleDateExpirationChange = (newValue) => {
    const formattedDateTime = newValue.locale('fr').format('YYYY-MM-DD HH:mm:ss');
    setExpiration(newValue);
    setUserToAdd(prevUser => ({ ...prevUser, expiration: formattedDateTime }))
  }

  return (
    <div className='UserProfilesServicesForm'>

      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem checkboxItem ' xs={12} md={12} lg={12} >
          <FormLabel className='checkboxItem-title'>PROFILS ET SERVICES</FormLabel>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...profilesDefaultProps}
                id="profilesList"
                name="profilesList"

                isOptionEqualToValue={(option, value) => option.nameProfile === value.nameProfile}

                value={profile}

                onChange={(e, newValue) => {
                  setProfileError('');
                  setProfileServicesError('')
                  setDefaultServiceError('')
                  if (newValue) {
                    // console.log(newValue)
                    setProfile(newValue);
                    setProfileServices([])
                    setDefaultService(null)

                    setUserToAdd((prevState) => ({
                      ...prevState,
                      profile: newValue.nameProfile,
                      servicesProfile: [],
                      defaultServiceProfile: null
                    }));

                  }
                  else {
                    // L'utilisateur a effacé la valeur sélectionnée
                    setProfile(null);
                    setProfileServices([])
                    setDefaultService(null)

                    setUserToAdd((prevState) => ({
                      ...prevState,
                      profile: null,
                      servicesProfile: [],
                      defaultServiceProfile: null
                    }));
                  }
                }
                }

                onInputChange={(e, newInputValue) => {
                  if (!newInputValue) {
                    // L'utilisateur a effacé le champ au clavier
                    setProfile(null);
                    setProfileServices([])
                    setDefaultService(null)

                    setUserToAdd((prevState) => ({
                      ...prevState,
                      profile: null,
                      servicesProfile: [],
                      defaultServiceProfile: null
                    }));
                  }
                }}

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Profils" variant="standard" name="profilesList" />}
              />
            </Stack>
            {profileError && <Typography variant="caption" color="error">{profileError}</Typography>}
          </FormControl>
        </Grid>
        <Grid item className='gridItem' xs={12} md={6} lg={6} ></Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...profileServicesDefaultProps}
                multiple
                id="multipleServicesProfile"
                name="multipleServicesProfile"
                disableCloseOnSelect
                disabled={!userToAdd.profile}

                isOptionEqualToValue={(option, value) => option.aliasName === value.aliasName}

                // value={userToAdd.servicesProfile}
                value={profileServices}

                onChange={(e, newValue) => {
                  // console.log(newValue);
                  if (newValue) {
                    setProfileServices(newValue)
                    // setDefaultServiceList(newValue)
                    setDefaultService(newValue?.length === 1 ? newValue[0] : null);
                    setProfileServicesError('');
                    setDefaultServiceError('');

                    const updatedServicesProfile = newValue.map(item => ({
                      idService: item.idService,
                      aliasName: item.aliasName,
                      defaultService: newValue?.length === 1 ? 1 : 0
                    }));

                    const updatedDefaultServiceProfile = newValue?.length === 1
                      ? {
                        idService: newValue[0].idService,
                        aliasName: newValue[0].aliasName,
                        defaultService: 1
                      }
                      : null

                    // console.log({ updatedServicesProfile })
                    setUserToAdd((prevState) => ({
                      ...prevState,
                      servicesProfile: updatedServicesProfile,
                      defaultServiceProfile: updatedDefaultServiceProfile,
                    }));

                  }
                }}

                // disableCloseOnSelect

                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.aliasName}
                    </li>
                  )
                }}

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Services du profil" variant="standard" name="multipleServicesProfile" />}
              />
            </Stack>
            {profileServicesError && <Typography variant="caption" color="error">{profileServicesError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...defaultServiceProfileDefaultProps}
                id="defaultServiceProfile"
                name="defaultServiceProfile"
                disabled={userToAdd.servicesProfile.length === 0}

                isOptionEqualToValue={(option, value) => option.defaultServiceProfile === value.defaultServiceProfile}

                // value={userToAdd.defaultServiceProfile}
                value={defaultService}

                onChange={(e, newValue) => {
                  // console.log(newValue);
                  setDefaultServiceError('');
                  if (newValue) {
                    setDefaultService(newValue)

                    setUserToAdd((prevState) => ({
                      ...prevState,
                      // defaultServiceProfile: newValue
                      defaultServiceProfile: { ...newValue, defaultService: 1 }
                    }));

                  } else {
                    // L'utilisateur a effacé la valeur sélectionnée
                    setDefaultService(null)
                    setUserToAdd((prevState) => ({
                      ...prevState,
                      defaultServiceProfile: null,
                    }));
                  }
                }}

                onInputChange={(e, newInputValue) => {
                  if (!newInputValue) {
                    // L'utilisateur a effacé le champ au clavier
                    setDefaultService(null)
                    setUserToAdd((prevState) => ({
                      ...prevState,
                      defaultServiceProfile: null,
                    }));
                  }
                }}

                clearOnEscape
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Service par defaut du profil"
                      variant="standard"
                      name="defaultServiceProfile"
                    />
                  )
                }}
              />
            </Stack>
            {defaultServiceError && <Typography variant="caption" color="error">{defaultServiceError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0'>
            <FormControlLabel
              className='contact-formControl'
              control={
                <Checkbox
                  className='contact-checkbox'
                  id="contact"
                  name="contact"
                  checked={userToAdd.contact || false}
                  onChange={(e) => setUserToAdd({ ...userToAdd, [e.target.name]: e.target.checked })}
                  color="primary"
                  inputProps={{ 'aria-label': 'contact checkbox' }}
                />
              }
              label={
                <div className='contact-label'>
                  <PermContactCalendarIcon
                    sx={{
                      color: userToAdd.contact ? 'green' : 'action.active',
                      marginRight: '0.5rem'
                    }}
                  />
                  Contact
                </div>
              }
              labelPlacement="start"
            />
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6} >

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            // locale={frFR} // Utilisez la locale française canadienne pour @mui/x-date-pickers
            locale="fr-FR"
          >
            <DatePicker
              label="Date d'expiration"
              format="YYYY/MM/DD"
              openTo="day"
              views={["year", "month", "day"]}
              slotProps={{
                textField: {
                  variant: "filled",
                  size: "small",
                  // helperText: "Saisir une date AAAA/MM/JJ"
                }
              }}
              value={expiration}
              onChange={(newValue) => handleDateExpirationChange(newValue)}
            />
          </LocalizationProvider>
        </Grid>

      </Grid>

    </div>
  )
}
