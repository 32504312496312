import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./MuMiseEnLigne.scss";

import {
  Box,
  Tab
} from "@mui/material";
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import { getMiseEnLigneData } from "./httpQuerys";
import MuHeader from "../../../components/mu-header/MuHeader"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
// import moment from "moment";
import "moment/locale/fr";
import MuMiseEnLigneList from "./Mu-MiseEnLigne-List/MuMiseEnLigneList";
import MuMiseEnLigneRoles from "./Mu-MiseEnLigne-Roles/MuMiseEnLigneRoles";
import MuMiseEnLigneMatrice from "./Mu-MiseEnLigne-Matrice/MuMiseEnLigneMatrice";

// .format('DD-MM-YYYY HH:mm:ss')
// const date = moment(currentCity.maxDateMaj).locale('fr').format('dddd DD-MMM-YYYY'
// const maxDateMaj = date.isValid() ? date.format('DD-MM-YYYY') : null

export default function MuMiseEnLigne() {
  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

  const navigate = useNavigate();

  // Tableau des rôles autorisés pour la gestion des utilisateurs
  // ['ROLE_ADMIN', 'ROLE_CONSULT', 'ROLE_GESTION_UTILISATEURS', 'ROLE_MISE_EN_LIGNE', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const allowedRolesForUserManagement = ['ROLE_ADMIN', 'ROLE_GESTION_UTILISATEURS', 'ROLE_GESTION_UTILISATEURS_ET_MISE_EN_LIGNE'];
  const { roleName } = JSON.parse(localStorage.getItem('currentUserInfo'));
  const allowedManagement = allowedRolesForUserManagement.includes(roleName)

  const { page } = useParams();
  const [value, setValue] = useState(allowedManagement ? page : 'list');

  useEffect(() => {
    if (!allowedManagement) {
      navigate(`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne/list`);
      setValue('list')
    } else {
      setValue(page)
    }
  }, [allowedManagement, cityCode, navigate, page])


  const handleChange = (event, newValue) => {
    navigate(`/dashboard/municipalites/${cityCode}/mu-mise-en-ligne/${newValue}`)
    setValue(newValue);
  };



  const [data, setData] = useState([]);


  useEffect(() => {
    getMiseEnLigneData().then((data) => setData(data));
  }, []);

  const [query, setQuery] = useState("");
  const tabKeys = ["demandeur", "typedata", "statut", "MiseEnLigne"];

  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    currentCity && (
      <div className="mu-miseEnLigne">
        <MuHeader currentCity={currentCity} />

        <div className="mu-miseEnLigne-main-container">

          <div className="topPage">
            <div className="topTitle">
              <CloudUploadRoundedIcon />
              <div>Mises en Ligne {currentCity.description} </div>
              <span>
                <em>{currentCity.cityName}</em>
              </span>
            </div>
            <div className="topNav">
              <Link
                className="topNav-close"
                to={`/dashboard/municipalites/${cityCode}/mu-statistiques`}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <CloseRoundedIcon />
              </Link>
            </div>
          </div>

          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} >
                  <Tab label="Mise en ligne" value='list' />
                  <Tab label="Rôles" value='roles' />
                  <Tab label="Matrice" value='matrice' />
                  <Tab label="Zonage et grille" value='zonage' />
                  <Tab label="Orthophotos" value='orthophotos' />
                  <Tab label="Photo-croquis" value='photo-croquis' />
                  <Tab label="Infra" value='infra' />
                  <Tab label="Autres données" value='autres-données' />

                  {/* {allowedManagement ? (
                    <Tab label="Nouvel utilisateur" value='mu-add-new-user' />
                  ) : null}
                  {allowedManagement ? (
                    <Tab label="Importer un utilisateur" value='mu-import-user' />
                  ) : null} */}

                </TabList>
              </Box>

              <TabPanel value="list">
                <MuMiseEnLigneList allowedManagement={allowedManagement} />
              </TabPanel>
              <TabPanel value="roles">
                <MuMiseEnLigneRoles allowedManagement={allowedManagement} />
              </TabPanel>
              <TabPanel value="matrice">
                <MuMiseEnLigneMatrice allowedManagement={allowedManagement} />
              </TabPanel>
              {/* <TabPanel value="zonage">
                <MuMiseEnLigneZonage allowedManagement={allowedManagement} />
              </TabPanel>
              <TabPanel value="orthophotos">
                <MuMiseEnLigneOrthophotos allowedManagement={allowedManagement} />
              </TabPanel>
              <TabPanel value="photo-croquis">
                <MuMiseEnLignePhotoCroquis allowedManagement={allowedManagement} />
              </TabPanel>
              <TabPanel value="infra">
                <MuMiseEnLigneInfra allowedManagement={allowedManagement} />
              </TabPanel>
              <TabPanel value="autres-données">
                <MuMiseEnLigneAutresDonnees allowedManagement={allowedManagement} />
              </TabPanel> */}

              {/* {allowedManagement ? (
                <TabPanel value="mu-add-new-user">
                  <MuAddNewUser />
                </TabPanel>
              ) : null}
              {allowedManagement ? (
                <TabPanel value="mu-import-user">
                  <MuImportUser />
                </TabPanel>
              ) : null} */}





            </TabContext>
          </Box>

        </div>
      </div>
    )
  );
}
