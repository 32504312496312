export const masquerEmail = (email) => {

    if (email && email.trim()) {
        // Séparer l'adresse en deux parties : nom et domaine
        const [nom, domaine] = email.split('@');
        const [serveur, extention] = domaine.split('.');

        // Masquer le nom d'utilisateur (les trois premiers caractères et les autres avec '*')
        const nomMasque = nom.slice(0, 3) + '*'.repeat(5);
        // const nomMasque = nom.slice(0, 3) + '*'.repeat(Math.max(0,nom.length - 3));

        // Masquer une partie du serveur du domaine (les trois premiers caractères et les autres avec '*')
        const serveurMasque = serveur.slice(0, 3) + '*'.repeat(5);
        // const serveurMasque = serveur.slice(0, 3) + '*'.repeat(Math.max(0, serveur.length - 3));

        // Recréer l'adresse e-mail masquée
        const emailMasque = `${nomMasque}@${serveurMasque}.${extention}`;

        return emailMasque;
    } else {
        // Gérer le cas où l'email est vide ou non défini
        return '';
    }
}
