import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./MuStatistiques.scss";
import Cards from './cards/Cards'
import moment from 'moment'
import "moment/locale/fr"
import MuHeader from "../../../components/mu-header/MuHeader";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import { Box } from "@mui/system";
import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import SpanningTable from "./SpanningTable";
import { getStatsData } from './httpQuerys';
// import { useQuery } from "react-query";


export default function MuStatistiques() {
  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));
  const currentDateYear = moment().locale("fr").format("YYYY");
  const lastDateYear = currentDateYear - 1;

  const [selectedVersion, setSelectedVersion] = useState("Publique");
  const [selectedPeriode, setSelectedPeriode] = useState(currentDateYear);
  const [data, setData] = useState([]);

  const handlePeriodeChange = (event) => {
    setSelectedPeriode(event.target.value);
  };

  // ! En utilisant useCallback, on peut éviter les appels redondants à getStatsData lorsque les dépendances ne changent pas.
  // ! ceci remplace le code ci-dessous. À tester
  const fetchData = useCallback(async () => {
    const data = await getStatsData({
      SQLDatabase: currentCity.SQLDatabase,
      version: selectedVersion,
      periode: selectedPeriode,
    });
    setData(data);
  }, [currentCity.SQLDatabase, selectedVersion, selectedPeriode]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // ! code initial valide sans useCallback() hook
  // useEffect(() => {
  //   getStatsData(
  //     {
  //       SQLDatabase: currentCity.SQLDatabase,
  //       version: selectedVersion,
  //       periode: selectedPeriode
  //     }
  //   ).then(data => setData(data));

  // }, [currentCity, selectedVersion, selectedPeriode]);


  return (
    currentCity && (
      <div className="muStatistiques">

        <MuHeader currentCity={currentCity} />

        <div className="statistiques-main-container statistiques">

          <div className="topPage">
            <div className="topTitle">
              <PublicRoundedIcon />
              <div>Statistiques {currentCity.description} </div>
              <span>
                <em>{currentCity.cityName}</em>
              </span>
            </div>

            <div className="topNav">
              <Link
                className="topNav-close"
                to={`/dashboard/municipalites`}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <CloseRoundedIcon />
              </Link>

              <div className="topMiddle">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "& > *": {
                      m: 1,
                    },
                  }}
                >
                  <ButtonGroup className="btnGroupVersion" variant="text" aria-label="text button group">
                    <Button
                      className="btnVersion"
                      onClick={() => setSelectedVersion("Publique")}
                      style={
                        selectedVersion === "Publique"
                          ? { backgroundColor: "#ddd" }
                          : {}
                      }
                    >
                      Publique
                    </Button>
                    <Button
                      className="btnVersion"
                      onClick={() => setSelectedVersion("Réservée")}
                      style={
                        selectedVersion === "Réservée"
                          ? { backgroundColor: "#ddd" }
                          : {}
                      }
                    >
                      Réservée
                    </Button>
                  </ButtonGroup>
                </Box>

                <Box sx={{ minWidth: 180, maxHeight: '30px' }}>
                  <FormControl fullWidth>
                    {/* <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Période
                    </InputLabel> */}
                    <NativeSelect
                      defaultValue={selectedPeriode}
                      inputProps={{
                        name: "periode",
                        id: "periode",
                      }}
                      onChange={handlePeriodeChange}
                    >
                      <option value={currentDateYear}>Année en cours ...</option>
                      <option value={lastDateYear}>Année passée</option>
                    </NativeSelect>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>

          <Box sx={{ width: "100%" }} className="statsBox">

            <div className="cards">
              <Cards dataTable={data} periode={selectedPeriode} version={selectedVersion} />
            </div>

            <Paper
              className="paper"
              elevation={5}
            >
              <div className="spanningTable">
                <SpanningTable dataTable={data} />
              </div>

            </Paper>
          </Box>

        </div>

      </div>
    )
  );
}
