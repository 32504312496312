import axios from 'axios';

// Créer une instance d'Axios pour les requêtes vers l'API
const httpService = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // URL de l'API
});


// Intercepteur pour ajouter le jeton d'accès à chaque requête
httpService.interceptors.request.use(async (config) => {

    const { url } = config;

    if (!url.startsWith('/auth')) {

        // Vérifier si la clé portailclient existe dans le localStorage
        const portailclient = JSON.parse(localStorage.getItem('portailclient'));
        if (portailclient) {

            // Récupérer le jeton d'accès depuis le localStorage
            const { access_token, refresh_token } = portailclient;

            // Vérifier si le jeton d'accès est expiré
            const access_token_expired = isTokenExpired(access_token);
            // console.log("Vérifier si le jeton d'accès est expiré ", access_token_expired)

            if (access_token_expired) {
                // Vérifier si le jeton de rafraîchissement est expiré
                const refresh_token_expired = isTokenExpired(refresh_token);

                if (refresh_token_expired) {
                    // Si le jeton de rafraîchissement est expiré, rediriger l'utilisateur vers la page de connexion
                    localStorage.clear();
                    window.location.href = '/';
                    // window.location.href = '/login';
                } else {
                    try {
                        // Envoyer une demande à l'API pour obtenir un nouveau jeton d'accès
                        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refreshtoken`, { refresh_token: refresh_token });

                        // console.log("Envoyer une demande à l'API pour obtenir un nouveau jeton d'accès ", response)
                        // Mettre à jour les clés access_token et refresh_token de l'objet
                        portailclient.access_token = response.data.access_token;
                        portailclient.refresh_token = response.data.refresh_token;

                        // Mettre à jour le localStorage
                        localStorage.setItem('portailclient', JSON.stringify(portailclient));

                        // Ajouter le jeton d'accès à l'en-tête Authorization de la requête
                        config.headers.Authorization = `Bearer ${portailclient.access_token}`;

                    } catch (error) {
                        console.error(`Unable to get access_token using refresh_token. Error: ${error}`);
                        localStorage.clear();
                        window.location.href = '/';
                    }
                }
            } else {
                // Ajouter le jeton d'accès à l'en-tête Authorization de la requête
                config.headers.Authorization = `Bearer ${access_token}`;
            }
        } else {
            console.log('No credentials. Redirecting to login page.');
            localStorage.clear();
            window.location.href = '/';
        }
    }
    return config;
});


// Fonction pour vérifier si le jeton JWT est expiré
function isTokenExpired(token) {
    if (!token) {
        return true;
    }
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const now = Date.now() / 1000;
    return decodedToken.exp < now;
}

const dataService = {
    get: httpService.get,
    post: httpService.post,
    put: httpService.put,
    delete: httpService.delete,
    patch: httpService.patch
}
export default dataService