import React, { useEffect, useState } from 'react';
import './UserInfoForm.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/fr-ca';
import {
  getUsersToAddService,
} from '../../httpQuerys';
import {
  TextField,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function UserInfoForm(props) {

  const {
    userToAdd,
    setUserToAdd,
    userError,
    setUserError
  } = props;

  const currentCity = JSON.parse(localStorage.getItem('currentMunicipality'));
  const { cityCode } = currentCity

  const [users, setUsers] = useState([]);

  // ---------------------------------------------------------------
  // Configuration de la locale pour Day.js
  dayjs.locale("fr-ca");
  // ---------------------------------------------------------------

  //  valeurs a récupérer de la BD
  useEffect(() => {
    getUsersToAddService(cityCode, userToAdd.idGroup).then((data) => setUsers(data));

  }, [cityCode, userToAdd.idGroup]);


  // données des Options de la liste déroulante pour les titres
  const usersDefaultProps = {
    options: users,
    // getOptionLabel: (option) => option.fullName
    getOptionLabel: (option) => `${option.fullName} (${option.idUser})`
  };


  return (
    <div className='UserInfoForm'>

      <Grid container className='gridContainer' spacing={2} m='0' >

        <Grid item className='gridItem' xs={12} md={12} lg={12} >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <Stack spacing={1} >
              <Autocomplete
                {...usersDefaultProps}
                id="finduser"
                name="finduser"
                isOptionEqualToValue={(option, value) => option.fullName === value.fullName}
                onChange={(e, newValue) => {
                  // console.log(newValue);
                  setUserToAdd((prevState) => ({
                    ...prevState,
                    fullName: newValue ? newValue.fullName : null,
                    userName: newValue ? newValue.idUser : null
                  }));
                  setUserError(newValue ? '' : 'Sélectionner un utilisateur.');
                }}

                clearOnEscape
                renderInput={(params) => <TextField {...params} label="Utilisateur" variant="standard" name="finduser" />}
              />
            </Stack>
            {userError && <Typography variant="caption" color="error">{userError}</Typography>}
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}>
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }}>
            <InputLabel htmlFor="fullname">Prénom et nom</InputLabel>
            <Input
              id="fullname"
              name="fullname"
              autoComplete="fullname"
              disabled
              value={userToAdd.fullName || ''}

              startAdornment={
                <InputAdornment position="start">
                  <AccountCircleIcon
                    sx={{
                      // color: (!userToAdd.fullName && !userError) ? 'gray' : (userError) ? 'red' : 'green',
                      color: (!userToAdd.userName ) ? 'gray' :  'green',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid item className='gridItem' xs={12} md={6} lg={6}  >
          <FormControl className='gridItem-formControl' variant="standard" sx={{ mb: 2 }} m='0' >
            <InputLabel htmlFor="newUsername">Identifiant</InputLabel>
            <Input
              id="newUsername"
              name="newUsername"
              autoComplete="username"
              // autoComplete="off"
              disabled
              value={userToAdd.userName || ''}

              startAdornment={
                <InputAdornment position="start">
                  <AccountCircleIcon
                    sx={{
                      color: (!userToAdd.userName ) ? 'gray' :  'green',
                      mr: 1,
                      my: 0.5
                    }}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

      </Grid>

    </div>
  )
}
