import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { updateMuUserInfoAndServies } from '../httpQuerys';
import { useNavigate } from 'react-router';
import _ from 'lodash';


export default function DialogConfirm({ userToEdit, lastUserValues, open, setOpen, isFormValidated }) {
  // const { cityCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  console.log({ userToEdit })
  console.log({ lastUserValues })

  if (!_.isEqual(userToEdit, lastUserValues)) {
    console.log('Objects are different');
  } else {
    console.log('Objects are the same');
  }




  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog) {
      setOpenSecondDialog(false)
      navigate(`/dashboard/municipalites/${userToEdit.cityCode}/mu-utilisateurs`);
    }

  };

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  // Vérifie si la touche pressée est la touche "Échap" (code 27) pour fermer la fenêtre
  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      handleClose(); // Appelle la fonction handleClose
    }
  };

  //! -------------------------------------------------------------------------------------

  const handleConfirm = async () => {
    try {
      const userUpdated = await updateMuUserInfoAndServies(userToEdit);
      if (userUpdated) {
        console.log('Mise à jour effectuée avec succés.');
        setMessageConfirmationDialog('Mise à jour effectuée avec succés.')
        // Afficher la boîte de dialogue de confirmation
        // setOpenDialog(false);
        setOpenSecondDialog(true);
      }
      else {
        console.log('Erreur de la Mise à jour !');
        setMessageConfirmationDialog('Erreur lors de la mise à jour !')
        // Afficher la boîte de dialogue de confirmation
        // setOpenDialog(false);
        setOpenSecondDialog(true);
      }
    } catch (error) {
      // Gérer les erreurs de mise à jour
      console.error('Erreur de la Mise à jour !', error);
    }
  };

  return (
    _.isEqual(userToEdit, lastUserValues) ? (
      <div>
        <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
          <DialogTitle>Pas de mise à jour</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Aucune modification n'a été apportée à cet utilisateur.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : (
      openSecondDialog ? (
        <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleEscape}>
          <DialogTitle>Confirmation des modifications apportées</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <strong><em>{messageConfirmationDialog}</em></strong>
            </DialogContentText>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        isFormValidated ? (
          <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
            <DialogTitle>Enregistrer les modifications à apportées</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Veuillez confirmer l'enregistrement des modifications à apporter à l'utilisateur <strong><em>{userToEdit.fullName}</em></strong>.
                Cette oprération est définitive !
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Retour au Formulaire</Button>
              <Button onClick={handleConfirm}>Confirmer</Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>Erreur dans le formulaire</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Un ou plusieurs champs du formulaire sont manquants ou érronés.
                Veuillez vérifier vos données !
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>Retour au Formulaire</Button>
            </DialogActions>
          </Dialog>
        )
      )
    )
  )
}
