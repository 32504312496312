import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./MuMiseEnLigneList"
import GridTable from "../data-grid/GridTable";
import SearchBar from "../../../../components/search-bar/SearchBar";

import { getMiseEnLigneData } from "../httpQuerys"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
// import moment from "moment";
import "moment/locale/fr";

// .format('DD-MM-YYYY HH:mm:ss')
// const date = moment(currentCity.maxDateMaj).locale('fr').format('dddd DD-MMM-YYYY'
// const maxDateMaj = date.isValid() ? date.format('DD-MM-YYYY') : null

export default function MuMiseEnLigneList() {
  const { cityCode } = useParams();
  const currentCity = JSON.parse(localStorage.getItem("currentMunicipality"));

  const [data, setData] = useState([]);


  useEffect(() => {
    getMiseEnLigneData().then((data) => setData(data));
  }, []);

  const [query, setQuery] = useState("");
  const tabKeys = ["demandeur", "typedata", "statut", "MiseEnLigne"];

  const showList = (data) => {
    const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return data.filter((row) =>
      tabKeys.some((key) =>
        row[key]?.toString()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(normalizedQuery)
      )
    );
  };

  return (
    currentCity && (
      <div className="MuMiseEnLigneList">
        <SearchBar query={query} setQuery={setQuery} />
        <GridTable data={showList(data)} />
      </div>
    )
  );
}
