import React, { useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteSelectedUser } from "../httpQuerys";
import { useNavigate } from 'react-router';

export default function DialogConfirm(props) {
  // console.log(props)
  // const { open, setOpen, selectedRows, rows, setRows, setIsSelectionEmpty, setMustReload } = props
  const { open, setOpen, selectedRows } = props
  const { cityCode } = JSON.parse(localStorage.getItem('currentMunicipality'));

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(open);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [messageConfirmationDialog, setMessageConfirmationDialog] = useState('');

  const handleClose = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)

    navigate(`/dashboard/municipalites/${cityCode}/mu-utilisateurs`);
  }

  const handleCancel = () => {
    setOpen(false)
    setOpenDialog(false)
    if (openSecondDialog)
      setOpenSecondDialog(false)
  };

  // Vérifie si la touche pressée est la touche "Entrée" (code 13) pour valider la confirmation
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleConfirm(); // Appelle la fonction handleConfirm
    }
  };

  // Vérifie si la touche pressée est la touche "Échap" (code 27) pour fermer la fenêtre
  const handleEscape = (e) => {
    if (e.keyCode === 27) {
      handleClose(); // Appelle la fonction handleClose
    }
  };

  const handleConfirm = async () => {
    console.log('Deleting selected rows ');
    try {

      // console.log({selectedRows})
      // j'utilise Promise.all pour attendre la résolution de toutes les promesses
      const response = await Promise.all(
        selectedRows.map((row) => deleteSelectedUser(row))
      );


      // const response = true

      if (response) {
        // Filter rows pour avoir la nouvelle liste à afficher newRows
        // const newRows = rows.filter((row) => !selectedRows.includes(row.idUser));
        // setRows(newRows);
        // setIsSelectionEmpty(true)
        // setMustReload(true)

        console.log('Suppression effectuée avec succés.');
        setMessageConfirmationDialog('Suppression effectuée avec succés.')
        // setOpenDialog(false)
        setOpenSecondDialog(true);

      } else {

        console.log('Erreur lors de la Suppression !');
        setMessageConfirmationDialog('Erreur lors de la Suppression !')
        // Afficher la boîte de dialogue de confirmation
        // setOpenDialog(false);
        setOpenSecondDialog(true);

      }

    } catch (error) {
      console.error('Error deleting selected rows', error);
    }
  }

  return (
    openSecondDialog ? (

      <div>
        <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleEscape}>
          <DialogTitle>Confirmation de la suppression</DialogTitle>
          <DialogContent>

            <DialogContentText>
              <strong><em>{messageConfirmationDialog}</em></strong>
            </DialogContentText>

            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>

          </DialogContent>

        </Dialog>
      </div>

    ) : (
      <div>
        <Dialog open={openDialog} onClose={handleClose} onKeyDown={handleKeyDown}>
          <DialogTitle>Confirmation de suppression d'utilisateur(s)</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir retirer les services de {selectedRows.length > 1 ? `ces ${selectedRows.length} utilisateurs ` : ' cet utilisateur '} ?
              <br /> Cette action est irréversible.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel}>Annuler</Button>
            <Button onClick={handleConfirm}>Confirmer</Button>
          </DialogActions>

        </Dialog>

      </div>
    )
  )
}
